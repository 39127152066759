import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../LiveScoreCardpopups/Popup";
import VenueSearch from "../VenueListing/venueSearch";

export default function SportSelection({ width }) {
  const navigate = useNavigate();
  const userLocation = window.localStorage.getItem("userLocation");
  const [show, setShow] = useState({ popup: false, sport: "all" });

  return (
    <React.Fragment>
      <div className="col-4">
        <img
          className="img-fluid w-100 cursor-pointer popularSportimg"
          style={{ borderRadius: "7px" }}
          src={`/images/${
            width >= 900 ? "popular-sport1" : "popular-sport1_mobile"
          }.png`}
          alt=""
          onClick={(e) => {
            if (userLocation) {
              navigate(
                `/venue-search/${userLocation.toLocaleLowerCase()}/sports/box-cricket`
              );
            } else {
              setShow({ popup: true, sport: "box-cricket" });
            }
          }}
        />
      </div>
      <div className="col-4">
        <img
          className="img-fluid w-100 cursor-pointer popularSportimg"
          style={{ borderRadius: "7px" }}
          src={`/images/${
            width >= 900 ? "popular-sport2" : "popular-sport2_mobile"
          }.png`}
          alt=""
          onClick={(e) => {
            if (userLocation) {
              navigate(
                `/venue-search/${userLocation.toLocaleLowerCase()}/sports/pickleball`
              );
            } else {
              setShow({ popup: true, sport: "pickleball" });
            }
          }}
        />
      </div>
      <div className="col-4">
        <img
          className="img-fluid w-100 cursor-pointer popularSportimg"
          style={{ borderRadius: "7px" }}
          src={`/images/${
            width >= 900 ? "popular-sport3" : "popular-sport3_mobile"
          }.png`}
          alt=""
          onClick={(e) => {
            if (userLocation) {
              navigate(
                `/venue-search/${userLocation.toLocaleLowerCase()}/sports/football`
              );
            } else {
              setShow({ popup: true, sport: "football" });
            }
          }}
        />
      </div>
      <div
        className="col-md-6 m-auto text-center   mt-3 fw-600 cursor-pointer"
        onClick={(e) => {
          if (userLocation) {
            navigate(
              `/venue-search/${userLocation.toLocaleLowerCase()}/sports/all`
            );
          } else {
            setShow({ popup: true, sport: "all" });
          }
        }}
      >
        <div className="allSportDiv px-4 py-2">
          All Sports Venues{" "}
          <i className="fa fa-arrow-right mx-1" aria-hidden="true"></i>
        </div>
      </div>
      <Popup
        show={show?.popup}
        onHide={() => setShow({ popup: false, sport: "all" })}
      >
        <VenueSearch sport={show?.sport} />
      </Popup>
    </React.Fragment>
  );
}
