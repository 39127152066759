import React, { useReducer, useState } from "react";
import VenueInfo1 from "./VenueInfo1";
import { INITIAL_STATE, formReducer } from "../Reducers/VenueInfoReducer";

export default function VenueRegistration() {
  const [step, setStep] = useState(1);
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const handleNextStep = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      setStep(6);
    }
  };
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: {
        name: e.target.name,
        value: e.target.value,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="p-3 venue-registration">
        <h1 className="text-center">Venue registration</h1>
        <p className="text-center">
          Answer the following questions : answered {step - 1} out of 11
          questions
        </p>
        <div className="greyBox p-4">
          {step >= 1 && (
            <VenueInfo1
              step={step}
              handleNextStep={handleNextStep}
              dispatch={dispatch}
              state={state}
              handleChange={handleChange}
            />
          )}
          {step >= 2 && <VenueInfo1 handleNextStep={handleNextStep} />}
        </div>
      </div>
    </React.Fragment>
  );
}
