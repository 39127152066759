import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { ConvertDate, GetMyAge } from "../../constants";

export const UserDetails = ({
  UserDetails,
  BowlingStyle,
  BattingStyle,
  PlayerStatistics,
  show,
  onHide,
}) => {
  const [PlayermatchData, Setmatchplayerdata] = useState(PlayerStatistics);
  const [mainPage, setmainPage] = useState({
    info: "",
    career: "d-none",
  });

  return (
    <div>
      <Modal
        show={show}
        onHide={() => onHide({ msg: "Cross Icon Clicked!" })}
        style={{ padding: "20px" }}
      >
        <div className="row" style={{ margin: "5px" }}>
          <div
            className="col-5 p-0 "
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <img
              src={UserDetails.profileImage}
              className="img-fluid   playerProfileimage"
            />
          </div>
          <div className="col-7 p-0">
            <div className="row col-12 mt-2">
              <div className="name col-9 mt-3 dataText">
                <b style={{ textTransform: "uppercase" }}>
                  {UserDetails.firstName} {UserDetails.lastName}{" "}
                </b>
              </div>
              <div className="col-3">
                <img
                  src="/images/Closeicon.svg"
                  className=""
                  style={{ width: "20px", marginLeft: "20px" }}
                  onClick={onHide}
                />
              </div>
            </div>
            <div className="mt-2">
              <span className="textLabel ">
                {" "}
                (
                {UserDetails.playerRoleName === null ||
                UserDetails.playerRoleName === ""
                  ? "N/A"
                  : UserDetails.playerRoleName}
                )
              </span>
            </div>
          </div>
        </div>
        <Modal.Body className="profileformControl profileContainer mt-2">
          <div style={{ marginTop: "30px", wordBreak: "break-word" }}>
            {/* <div className="row mt-3">
              <div
                className="col-6 centerAlign cursorPointer h6"
                onClick={() =>
                  setmainPage({ ...mainPage, info: "", career: "d-none" })
                }
              >
                <span className={mainPage.info == "" ? "bottomColor" : ""}>
                  Info.
                </span>
              </div>
              <div
                className="col-6 centerAlign cursorPointer h6"
                onClick={() =>
                  setmainPage({ ...mainPage, info: "d-none", career: "" })
                }
              >
                <span className={mainPage.career == "" ? "bottomColor" : ""}>
                  Career Summary
                </span>
              </div>
            </div> */}
            <div
              className="my-4 text-center"
              style={{ height: "350px", overflowX: "hidden" }}
            >
              {/* <div className={mainPage.info}> */}
              <label>
                <h3>
                  <b>INFORMATION</b>
                </h3>
              </label>
              <div className="row text-center mt-3">
                <div className="col-5">
                  <label className="form-label textLabel">
                    Batting Style :
                  </label>
                </div>
                {/* <div className="col-1 textLabel"></div> */}
                <div className="col-7 TextAlignClass ps-0">
                  <label className="dataText">
                    {BattingStyle === null || BattingStyle === ""
                      ? "N/A"
                      : BattingStyle.replace(/([A-Z]+)/g, " $1").replace(
                          /([A-Z][a-z])/g,
                          " $1"
                        )}
                  </label>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-5">
                  <label className="form-label textLabel">
                    Bowling Style :
                  </label>
                </div>
                {/* <div className="col-1 textLabel"></div> */}
                <div className="col-7 TextAlignClass ps-0">
                  <label className="dataText">
                    {BowlingStyle === null || BowlingStyle === ""
                      ? "N/A"
                      : BowlingStyle.replace(/([A-Z]+)/g, " $1").replace(
                          /([A-Z][a-z])/g,
                          " $1"
                        )}
                  </label>
                </div>
              </div>

              {/* <div className="row ">
                <div className="p-2 mt-3">
                  <label>
                    <h4>
                      <b>PERSONAL INFORMATION</b>
                    </h4>
                  </label>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col-4">
                  <label className="form-label textLabel ">Phone No</label>
                </div>
                <div className="col-1 textLabel">:</div>
                <div className="col-7 text-start TextAlignClass">
                  <label className="dataText">{UserDetails.phone_Number}</label>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col-4">
                  <label className="form-label textLabel">DOB </label>
                </div>
                <div className="col-1 textLabel">:</div>
                <div className="col-7 text-start TextAlignClass">
                  <label className="dataText">
                    {UserDetails.dob == "" || UserDetails.dob == null
                      ? "N/A"
                      : `${ConvertDate(UserDetails.dob)}(
                        ${GetMyAge(UserDetails.dob)} Years)`}
                  </label>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col-4">
                  <label className="form-label textLabel">Email </label>
                </div>
                <div className="col-1 textLabel">:</div>
                <div className="col-7 text-start TextAlignClass">
                  <label className="dataText">
                    {UserDetails.email == "" || UserDetails.email == null
                      ? "N/A"
                      : UserDetails.email}
                  </label>
                </div>
              </div> */}
              {/* <div className="row text-center">
                <div className="col-4">
                  <label className="form-label textLabel">Location </label>
                </div>
                <div className="col-1 textLabel">:</div>
                <div className="col-7   TextAlignClass">
                  <label className="dataText">
                    {UserDetails.area == "" || UserDetails.area == null
                      ? ""
                      : `${UserDetails.area}`}
                    {UserDetails.city == "" || UserDetails.city == null
                      ? ""
                      : `${UserDetails.city}`}
                    {UserDetails.area == "" ||
                    UserDetails.area == null ||
                    UserDetails.city == "" ||
                    UserDetails.city == null
                      ? "N/A"
                      : `${UserDetails.area} ${UserDetails.city}`}
                  </label>
                </div>
              </div> */}
              {/* </div> */}
              {/* { <div className={mainPage.career}>
                <div
                  className="my-3 py-2 ps-2 csfont1"
                  style={{ backgroundColor: "#C4C4C4" }}
                >
                  Batting Career Summary
                </div>
                <div className="row csfont2">
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Avgerage : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.avgerage}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Ballfaced : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.ballfaced}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Fifteen : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.fifteen}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Fours : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.fours}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start pe-0">
                        HighestScore :{" "}
                      </div>
                      <div className="col-4 text-start">
                        {
                          PlayermatchData[0].battingCareer
                            .highestScore
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Hundered : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.hundered}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Innings : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.innings}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Matches : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.matches}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Runs : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.runs}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Sixes : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.sixes}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">StrikeRate : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].battingCareer.strikeRate}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start px-0">
                        TwoHundered :{" "}
                      </div>
                      <div className="col-4 text-start">
                        {
                          PlayermatchData[0].battingCareer
                            .twoHundered
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="my-3 py-2 ps-2 csfont1"
                  style={{ backgroundColor: "#C4C4C4" }}
                >
                  Bowling Career Summary
                </div>
                <div className="row csfont2">
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Avgerage : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.avgerage}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Balls : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.balls}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Bbm : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.bbm}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Economay : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.economay}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">FiveWicket : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.fiveWicket}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Innings : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.innings}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Matches : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.matches}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Runs : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.runs}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">StrikeRate : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.strikeRate}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">TenWicket : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.tenWicket}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Wickets : </div>
                      <div className="col-4 text-start">
                        {PlayermatchData[0].bowlingCareer.wickets}
                      </div>
                    </div>
                  </div>
                </div>
              </div> } */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
