import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomGetAPI } from "../Utils/APIConnector";
import { convertTo12HourFormat, formatDate } from "../Utils/common";
import Loader from "../Utils/Loader";

export default function Withdrawalhistory({ venueId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionData, setTransactionData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    CustomGetAPI("/venue/Withdrawhistory/" + venueId).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setTransactionData(result);
        setIsLoading(false);
      }
    });
  }, [venueId]);
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="30%" />
      ) : (
        <div className="Container">
          <div className="row px-2">
            {/* <div className="col-1">
              <img
                className="cursorClass"
                src="/images/backArrow.svg"
                onClick={() => navigate(-1)}
              />
            </div> */}
            <div className="col-12 text-center">
              <b style={{ color: "#A51E22" }}> Withdrawal history</b>
            </div>
            <div className="col-12">
              <div className="row ">
                <div className="col-12"></div>
                <div className="col-12 pt-3">
                  <div className="row withdrawHeader py-2">
                    <div className="col-6">Date & Time</div>
                    <div className="col-6 text-end">Amount</div>
                  </div>
                  <div className="row border py-2">
                    <div className="col-12">
                      {transactionData && transactionData.length > 0 ? (
                        transactionData.map((transaction) => (
                          <div className="row mb-3" key={transaction.id}>
                            <div className="col-6">
                              <b>
                                {formatDate(
                                  transaction.createdAt.split("T")[0]
                                )}
                              </b>{" "}
                              <br />
                              {convertTo12HourFormat(
                                transaction.createdAt.split("T")[1]
                              )}
                            </div>
                            <div className="col-6 text-end">
                              ₹ <b>{transaction.amount}</b> <br />
                              {transaction.status === 0 ? (
                                <div style={{ color: "#EFA028" }}>Pending</div>
                              ) : (
                                <div style={{ color: "#339900" }}>Received</div>
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No Transaction History Found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
