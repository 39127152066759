import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
// import "../../../css"

const MultiStepBar = (props) => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === props.totalSteps) {
    stepPercentage = 100;
  } else {
    stepPercentage =
      (100 / parseInt(props.totalSteps - 1)) * (props.currentStep - 1);
  }

  // if (props.currentStep === 1) {
  //   stepPercentage = 0;
  // } else if (props.currentStep === 2) {
  //   stepPercentage = 50;
  // } else if (props.currentStep === 3) {
  //   stepPercentage = 100;
  // } else {
  //   stepPercentage = 0;
  // }
  const stepDiv = [];
  for (var i = 1; i <= props.totalSteps; i++) {
    stepDiv.push(i);
  }

  return (
    <ProgressBar percent={stepPercentage}>
      {stepDiv.map((str, index) => {
        var stDiv;
        <>
          {
            (stDiv = (
              <Step>
                {({ accomplished, index }) => (
                  <div
                    className={`indexedStep ${
                      accomplished ? "accomplished" : null
                    }`}
                  >
                    {index + 1}
                  </div>
                )}
              </Step>
            ))
          }
        </>;
        return stDiv;
      })}
      {/* <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <div
            className={`indexedStep ${accomplished ? "accomplished" : null}`}
          >
            {index + 1}
          </div>
        )}
      </Step> */}
    </ProgressBar>
  );
};

export default MultiStepBar;
