import React, { useLayoutEffect, useState } from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import MainFooter from "../MainComponents/MainFooter";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [MainLayout, setMainLayout] = useState(true);
  useLayoutEffect(() => {
    if (
      pathname == "/" ||
      pathname == "/privacy-policy" ||
      pathname == "/terms-and-conditions" ||
      pathname == "/venue-booking-history" ||
      pathname == "/home" ||
      pathname.match(/^\/venue-search\/[^/]+\/[^/]+\/[^/]+$/) ||
      pathname.match(/^\/venue-search\/[^/]+\/[^/]+$/)
    ) {
      setMainLayout(true);
    } else {
      setMainLayout(false);
    }
  }, [pathname]);
  const loggedin = window.localStorage.getItem("loggedin");

  return (
    <React.Fragment>
      {pathname != "/welcome" &&
        pathname != "/login" &&
        pathname != "/forgot-password" &&
        pathname != "/registration" &&
        pathname !== "/app1" &&
        pathname !== "/app2" && (
          <>
            <div id="wrapper">
              <div id="wrapperHeader">
                <Navbar MainLayout={MainLayout} />
              </div>
              <div
                id="wrapperMain"
                className={`row mx-0 mainContainer ${
                  pathname == "/home" || pathname == "/" ? "pt-0" : "pt-0"
                }`}
                style={{ overflowX: "hidden" }}
              >
                <div
                  fixed={true}
                  className={`${
                    MainLayout
                      ? "col-12 px-0"
                      : "col-md-6 col-lg-6 col-12 m-auto my-0 "
                  } ${pathname !== "/campaign" ? "pt-2" : "pt-0 px-0 mw-65"}`}
                >
                  <main className="container-fluid px-0">{children}</main>
                </div>
              </div>
              {/* {loggedin ? <Footer /> : null} */}
              <div id="wrapperFooter ">
                {pathname !== "/campaign" && <MainFooter />}
              </div>
            </div>
          </>
        )}
    </React.Fragment>
  );
};
export default Layout;
