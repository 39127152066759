import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = "";

export const LiveScoreData = createSlice({
  name: "liveScoreData",
  initialState: { value: initialStateValue },
  reducers: {
    changeColor: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeColor, dd } = LiveScoreData.actions;

export default LiveScoreData.reducer;
