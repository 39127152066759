import * as React from "react";
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import $ from "jquery";
import { GetRunRate, GetStrikeRate, isValid } from "./constants";
import Popup from "./LiveScoreCardpopups/Popup";
import OutScorePopup from "./LiveScoreCardpopups/OutScorePopup";
import AddBatsmen from "./LiveScoreCardpopups/AddBatsmen";
import AddBowler from "./LiveScoreCardpopups/AddBowler";
import ScoreCard from "./ScoreCard";
import { GetAPI, PostAPI } from "./Utils/APIConnector";

export default function LiveScoreCard({
  values,
  teamNames,
  prevStep,
  setDynamicValues,
}) {
  const userType = window.localStorage.getItem("UserType");
  const userid = window.localStorage.getItem("userid");
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  const ResumeMatch = window.localStorage.getItem("ResumeMatch");
  var strikeBatsmen = window.localStorage.getItem("strikerBatsmen");
  var nonStrikeBatsmen = window.localStorage.getItem("nonStrikerBatsmen");
  var strikeBowler = window.localStorage.getItem("strikerBowler");

  const [popupshow, setPopupShow] = useState({
    popup1: false,
    popup3: values.IsResumed && values.IsInningCompleted ? true : false,
  });
  const [popup1, setpopup1] = useState(false);
  const [mainPage, setmainPage] = useState({
    livePage: "",
    scorePage: "d-none",
  });
  const [totalScore, SetTotalScore] = useState({
    runs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.totalRun
        : 0,
    wickets:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.totalWickets
        : 0,
  });
  const [score, setscore] = useState([]);
  const [ballCounts, setballCounts] = useState([]);
  const [currentPlayerID, setcurrentPlayerID] = useState([]);
  const [scoreCount, setscoreCount] = useState(0);
  const [overCount, setoverCount] = useState(
    values.IsResumed && values.IsInningCompleted
      ? values.resumeData.completedOver
      : 0
  );
  const [extra, setextra] = useState([]);
  const [extraValue, setextraValue] = useState("");
  const [thisOver, setThisOver] = useState(0);
  const [outType, SetOutType] = useState([]);
  const [outBy, SetOutBy] = useState([]);
  const [runoutPLayerId, setrunoutPLayerId] = useState([]);
  const [outplayersList, setoutplayersList] = useState([]);
  const [player1Score, setplayer1Score] = useState({
    name:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[0].batsmenName
        : "",
    runs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[0].runs
        : 0,
    balls:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[0].balls
        : 0,
    fours:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[0].fours
        : 0,
    sixs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[0].sixes
        : 0,
  });
  const [player2Score, setplayer2Score] = useState({
    name:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[1].batsmenName
        : "",
    runs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[1].runs
        : 0,
    balls:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[1].balls
        : 0,
    fours:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[1].fours
        : 0,
    sixs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBatsmenDetails[1].sixes
        : 0,
  });
  const [bowlerScore, setbowlerScore] = useState({
    name:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.bolwerName
        : "",
    over:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.overs
        : "",
    maiden:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.maiden
        : 0,
    runs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.runs
        : 0,
    wickets:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.wickets
        : 0,
    eRate:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentBowlerDetails.eRate
        : 0,
  });
  const [partnershipScore, setpartnershipScore] = useState({
    runs:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentPartnershipRuns
        : 0,
    balls:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.currentPartershipBalls
        : 0,
  });
  const [currentBall, SetCurrentBall] = useState({
    MatchID: matchDetailsID,
    BallNumber: [],
    BatsmenID: [],
    BowlerID: 0,
    Run: 0,
    ExtraType: "",
    OutType: [],
    OutBy: [],
    TeamNumber:
      values.IsResumed && values.IsInningCompleted
        ? values.resumeData.battingTypeID
        : values.battingTeamValue,
    OutPlayerID: [],
  });
  const [battingTeam, SetBattingTeam] = useState([]);
  const [bowlingTeam, SetBowlingTeam] = useState([]);
  const [currentTeamID, SetCurrentTeamID] = useState();
  const [finalOverButton, SetFinalOverButton] = useState(false);

  var teamTypeID = 0;
  useEffect(async () => {
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }

    if (values.SingleOrMultipleTeam === "Single") {
      await GetAPI(
        "/Matches/MatchTeamDetails?matchID=" + parseInt(matchDetailsID)
      ).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var result = JSON.parse(result);
          if (result.status === "success") {
            for (var i = 0; i < result.selectedPlayers.length; i++) {
              if (
                parseInt(strikeBatsmen) ===
                parseInt(result.selectedPlayers[i].id)
              ) {
                setplayer1Score({
                  ...player1Score,
                  name: result.selectedPlayers[i].fullName,
                });

                SetCurrentTeamID(result.selectedPlayers[i].teamTypeID);
              }
              if (
                parseInt(nonStrikeBatsmen) ===
                parseInt(result.selectedPlayers[i].id)
              ) {
                setplayer2Score({
                  ...player2Score,
                  name: result.selectedPlayers[i].fullName,
                });
              }
              if (
                parseInt(strikeBowler) ===
                parseInt(result.selectedPlayers[i].id)
              ) {
                setbowlerScore({
                  ...bowlerScore,
                  name: result.selectedPlayers[i].fullName,
                });
              }
            }
          } else {
            alert(result.message);
          }
        }
      });
    } else {
      await GetAPI(
        "/Matches/MultiTeamList?matchID=" +
          parseInt(matchDetailsID) +
          `&inningType=${values.inningsType == "FirstInning" ? 1 : 2}`
      ).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var result = JSON.parse(result);
          if (result.status === "success") {
            SetBattingTeam(result.battingTeam);
            SetBowlingTeam(result.bowlingTeam);
            for (var i = 0; i < result.battingTeam.length; i++) {
              if (
                parseInt(strikeBatsmen) === parseInt(result.battingTeam[i].id)
              ) {
                setplayer1Score({
                  ...player1Score,
                  name: result.battingTeam[i].fullName
                    .split(" ")[0]
                    .concat(
                      " ",
                      result.battingTeam[i].fullName.split(" ")[1]
                        ? result.battingTeam[i].fullName.split(" ")[1].charAt(0)
                        : ""
                    ),
                });
                SetCurrentTeamID(result.battingTeam[i].teamTypeID);
              }
              if (
                parseInt(nonStrikeBatsmen) ===
                parseInt(result.battingTeam[i].id)
              ) {
                setplayer2Score({
                  ...player2Score,
                  name: result.battingTeam[i].fullName
                    .split(" ")[0]
                    .concat(
                      " ",
                      result.battingTeam[i].fullName.split(" ")[1]
                        ? result.battingTeam[i].fullName.split(" ")[1].charAt(0)
                        : ""
                    ),
                });
              }
            }
            for (var i = 0; i < result.bowlingTeam.length; i++) {
              if (
                parseInt(strikeBowler) === parseInt(result.bowlingTeam[i].id)
              ) {
                setbowlerScore({
                  ...bowlerScore,
                  name: result.bowlingTeam[i].fullName
                    .split(" ")[0]
                    .concat(
                      " ",
                      result.bowlingTeam[i].fullName.split(" ")[1]
                        ? result.bowlingTeam[i].fullName.split(" ")[1].charAt(0)
                        : ""
                    ),
                  over: "0.0",
                  runs: 0,
                });
              }
            }

            if (values.IsResumed && values.IsInningCompleted) {
              if (values.resumeData.currentBatsmenDetails[0] != true) {
                $(".star1").addClass("d-none");
                $(".star2").removeClass("d-none");
                window.localStorage.setItem(
                  "nonStrikerBatsmen",
                  values.resumeData.currentBatsmenDetails[0].playerID
                );
              } else {
                window.localStorage.setItem(
                  "strikerBatsmen",
                  values.resumeData.currentBatsmenDetails[1].playerID
                );
              }
            }
          } else {
            alert(result.message);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    SetCurrentBall({
      ...currentBall,
      BallNumber: ballCounts,
      BatsmenID: currentPlayerID,
      BowlerID: parseInt(strikeBowler),
      Run: score,
      ExtraType: extra,
      OutType: outType,
      OutBy: outBy,
      OutPlayerID: runoutPLayerId,
    });
  }, [score, outType, outBy, runoutPLayerId]);
  useEffect(() => {
    if (scoreCount == 6) {
      $(".NextOverButton").toggleClass("d-none");
      $(".RunInputButton").toggleClass("d-none");
    }
  }, [scoreCount]);
  useEffect(async () => {
    if (scoreCount == 6 && !popup1 && finalOverButton) {
      setPopupShow({ ...popupshow, popup3: true });

      var raw = JSON.stringify({
        MatchId: currentBall.MatchID,
        BallNumber: currentBall.BallNumber,
        BatsmenID: currentBall.BatsmenID,
        BowlerID: currentBall.BowlerID,
        Run: currentBall.Run,
        ExtraType: currentBall.ExtraType,
        OutType: currentBall.OutType,
        OutBy: currentBall.OutBy,
        TeamNumber: currentBall.TeamNumber,
        OutPlayerID: currentBall.OutPlayerID,
      });

      await PostAPI("/MatchScoreCard/SaveOver/", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            setballCounts([]);
            setcurrentPlayerID([]);
            setThisOver(0);
            setscoreCount(0);
            setscore([]);
            setextra([]);
            SetOutBy([]);
            SetOutType([]);
            setrunoutPLayerId([]);
            setoverCount(overCount + 1);
            $(".NextOverButton").toggleClass("d-none");
            $(".RunInputButton").toggleClass("d-none");
            // SetFinalOverButton(!finalOverButton);
          } else {
            alert(result.Message);
          }
        }
      });
    }
  }, [finalOverButton, popup1]);

  var ballCount = 0;
  var currentplayerId = 0;
  const addScore = (e) => {
    var eIntValue = parseInt(e);
    if (
      extraValue != "WD" &&
      extraValue != "NB" &&
      !extraValue.includes("NB")
    ) {
      setscoreCount(scoreCount + 1);
    }

    if (e == "OUT") {
      // setPopupShow({ ...popupshow, popup1: true });
      setpopup1(true);
      eIntValue = 0;
      setpartnershipScore({
        ...partnershipScore,
        runs: 0,
        balls: 0,
      });
    } else {
      SetOutType([...outType, ""]);
      SetOutBy([...outBy, ""]);
      setrunoutPLayerId([...runoutPLayerId, ""]);
    }

    $("#one").removeAttr("disabled");
    $("#two").removeAttr("disabled");
    $("#three").removeAttr("disabled");
    $("#four").removeAttr("disabled");

    $("#one").css("border-bottom", "");
    $("#two").css("border-bottom", "");
    $("#three").css("border-bottom", "");
    $("#four").css("border-bottom", "");

    if (scoreCount <= 5) {
      ballCount =
        overCount +
        "." +
        (extraValue == "" || extraValue == "BYE" || extraValue == "LB"
          ? scoreCount + 1
          : scoreCount);
      setballCounts([...ballCounts, ballCount]);
      if (!$(".star1").hasClass("d-none")) {
        currentplayerId = strikeBatsmen;
        setcurrentPlayerID([...currentPlayerID, currentplayerId]);
      } else {
        currentplayerId = nonStrikeBatsmen;
        setcurrentPlayerID([...currentPlayerID, currentplayerId]);
      }
      setextra([...extra, e == "OUT" ? "OUT" : extraValue]);
      setscore([...score, e]);
    } else {
      // setThisOver(0);
      // setscoreCount(0);
      // setoverCount(overCount + 1);
      // setscore([]);
      // setextra([]);
    }
    setextraValue("");
    if (!isNaN(e)) {
      if (!$(".star1").hasClass("d-none")) {
        setplayer1Score({
          ...player1Score,
          runs:
            extraValue == "NB" || extraValue == ""
              ? player1Score.runs + eIntValue
              : player1Score.runs,
          balls:
            extraValue != "WD" &&
            extraValue != "NB" &&
            !extraValue.includes("NB")
              ? player1Score.balls + 1
              : player1Score.balls,
          fours:
            eIntValue == 4 && extraValue != "WD"
              ? ++player1Score.fours
              : player1Score.fours,
          sixs:
            eIntValue == 6 && extraValue != "WD"
              ? ++player1Score.sixs
              : player1Score.sixs,
        });
      }
      if (!$(".star2").hasClass("d-none")) {
        setplayer2Score({
          ...player2Score,
          runs:
            extraValue == "NB" || extraValue == ""
              ? player2Score.runs + eIntValue
              : player2Score.runs,
          balls:
            extraValue != "WD" &&
            extraValue != "NB" &&
            !extraValue.includes("NB")
              ? player2Score.balls + 1
              : player2Score.balls,
          fours:
            eIntValue == 4 && extraValue != "WD"
              ? ++player2Score.fours
              : player2Score.fours,
          sixs:
            eIntValue == 6 && extraValue != "WD"
              ? ++player2Score.sixs
              : player2Score.sixs,
        });
      }
      setpartnershipScore({
        ...partnershipScore,
        runs:
          extraValue != "WD" && extraValue != "NB" && !extraValue.includes("NB")
            ? partnershipScore.runs + eIntValue
            : partnershipScore.runs + 1 + eIntValue,
        balls:
          extraValue != "WD" && extraValue != "NB" && !extraValue.includes("NB")
            ? partnershipScore.balls + 1
            : partnershipScore.balls,
      });
    }
    if (e == 1 || e == 3 || (scoreCount == 5 && e != "OUT")) {
      if ($(".star2").hasClass("d-none")) {
        $(".star2").removeClass("d-none");
        $(".star1").addClass("d-none");
      } else {
        $(".star2").addClass("d-none");
        $(".star1").removeClass("d-none");
      }
    }

    SetTotalScore({
      runs: !isNaN(e)
        ? extraValue != "WD" && extraValue != "NB" && !extraValue.includes("NB")
          ? totalScore.runs + eIntValue
          : totalScore.runs + 1 + eIntValue
        : totalScore.runs,
      wickets: e == "OUT" ? totalScore.wickets + 1 : totalScore.wickets,
    });

    setThisOver(
      extraValue != "WD" && extraValue != "NB"
        ? thisOver + eIntValue
        : thisOver + 1 + eIntValue
    );

    setbowlerScore({
      ...bowlerScore,
      over:
        extraValue == "" || extraValue == "BYE" || extraValue == "LB"
          ? parseInt(bowlerScore.over) + "." + (scoreCount + 1)
          : parseInt(bowlerScore.over) + "." + scoreCount,
      runs:
        extraValue == "WD" || extraValue == "NB" || extraValue.includes("NB")
          ? parseInt(bowlerScore.runs) + eIntValue + 1
          : parseInt(bowlerScore.runs) + eIntValue,
    });
  };

  const ExtraScore = (e) => {
    if (extraValue == "") {
      setextraValue(e);
    }

    if (extraValue == "NB" || extraValue == "LB" || extraValue == "BYE") {
      if (extraValue == "NB" && (e == "LB" || e == "BYE")) {
        setextraValue("NB" + "&" + e);
      }
      if ((extraValue == "LB" || extraValue == "BYE") && e == "NB") {
        setextraValue("NB" + "&" + extraValue);
      }
    }

    if (e == "WD") {
      $("#one").css("border-bottom", "3px solid rgb(165, 30, 34)");
      $("#two").attr("disabled", "true");
      $("#three").attr("disabled", "true");
      $("#four").attr("disabled", "true");
    } else if (e == "NB") {
      $("#two").css("border-bottom", "3px solid rgb(165, 30, 34)");
      $("#one ").attr("disabled", "true");
    } else if (e == "LB") {
      $("#three").css("border-bottom", "3px solid rgb(165, 30, 34)");
      $("#one ").attr("disabled", "true");
      $("#four").attr("disabled", "true");
    } else if (e == "BYE") {
      $("#four").css("border-bottom", "3px solid rgb(165, 30, 34)");
      $("#one").attr("disabled", "true");
      $("#three").attr("disabled", "true");
    }
  };

  // const undo = () => {
  //   setextraValue("");
  //   $("#one").removeAttr("disabled");
  //   $("#two").removeAttr("disabled");
  //   $("#three").removeAttr("disabled");
  //   $("#four").removeAttr("disabled");
  // };

  const UndoLastBall = () => {
    if (extra.at(-1) == "OUT" || score.length == 0) {
      return;
    } else {
      SetTotalScore({
        ...totalScore,
        runs:
          extra.at(-1) == "WD" ||
          extra.at(-1) == "NB" ||
          extra.at(-1).includes("NB")
            ? totalScore.runs - score.at(-1) - 1
            : totalScore.runs - score.at(-1),
        wickets:
          outBy.at(-1) != null && parseInt(outBy.at(-1)) > 0
            ? totalScore.wickets - 1
            : totalScore.wickets,
      });
      setscoreCount(
        extra.at(-1) != null &&
          extra.at(-1) != "" &&
          (extra.at(-1) == "WD" ||
            extra.at(-1) == "NB" ||
            extra.at(-1).includes("NB"))
          ? scoreCount
          : scoreCount - 1
      );
      setpartnershipScore({
        ...partnershipScore,
        runs:
          extra.at(-1) != null &&
          extra.at(-1) != "" &&
          (extra.at(-1) == "WD" ||
            extra.at(-1) == "NB" ||
            extra.at(-1).includes("NB"))
            ? partnershipScore.runs - 1 - parseInt(score.at(-1))
            : partnershipScore.runs - parseInt(score.at(-1)),
        balls:
          extra.at(-1) != null &&
          extra.at(-1) != "" &&
          (extra.at(-1) == "WD" ||
            extra.at(-1) == "NB" ||
            extra.at(-1).includes("NB"))
            ? partnershipScore.balls
            : partnershipScore.balls - 1,
      });
      if (parseInt(score.at(-1)) == 1 || parseInt(score.at(-1)) == 3) {
        if ($(".star2").hasClass("d-none")) {
          setplayer2Score({
            ...player2Score,
            runs:
              extra.at(-1) == "NB" || extra.at(-1) == ""
                ? player2Score.runs - parseInt(score.at(-1))
                : player2Score.runs,
            balls:
              extra.at(-1) == "WD" ||
              extra.at(-1) == "NB" ||
              extra.at(-1).includes("NB")
                ? player2Score.balls
                : player2Score.balls - 1,
            fours:
              parseInt(score.at(-1)) == 4 && extra.at(-1) != "WD"
                ? --player2Score.fours
                : player2Score.fours,
            sixs:
              parseInt(score.at(-1)) == 6 && extraValue != "WD"
                ? --player2Score.sixs
                : player2Score.sixs,
          });
          $(".star2").removeClass("d-none");
          $(".star1").addClass("d-none");
        } else {
          setplayer1Score({
            ...player1Score,
            runs:
              extra.at(-1) == "NB" || extra.at(-1) == ""
                ? player1Score.runs - parseInt(score.at(-1))
                : player1Score.runs,
            balls:
              extra.at(-1) == "WD" ||
              extra.at(-1) == "NB" ||
              extra.at(-1).includes("NB")
                ? player1Score.balls
                : player1Score.balls - 1,
            fours:
              parseInt(score.at(-1)) == 4 && extra.at(-1) != "WD"
                ? --player1Score.fours
                : player1Score.fours,
            sixs:
              parseInt(score.at(-1)) == 6 && extraValue != "WD"
                ? --player1Score.sixs
                : player1Score.sixs,
          });
          $(".star2").addClass("d-none");
          $(".star1").removeClass("d-none");
        }
      } else {
        if (!$(".star1").hasClass("d-none")) {
          setplayer1Score({
            ...player1Score,
            runs:
              extra.at(-1) == "NB" || extra.at(-1) == ""
                ? player1Score.runs - parseInt(score.at(-1))
                : player1Score.runs,
            balls:
              extra.at(-1) == "WD" ||
              extra.at(-1) == "NB" ||
              extra.at(-1).includes("NB")
                ? player1Score.balls
                : player1Score.balls - 1,
            fours:
              parseInt(score.at(-1)) == 4 && extra.at(-1) != "WD"
                ? --player1Score.fours
                : player1Score.fours,
            sixs:
              parseInt(score.at(-1)) == 6 && extraValue != "WD"
                ? --player1Score.sixs
                : player1Score.sixs,
          });
        }
        if (!$(".star2").hasClass("d-none")) {
          setplayer2Score({
            ...player2Score,
            runs:
              extra.at(-1) == "NB" || extra.at(-1) == ""
                ? player2Score.runs - parseInt(score.at(-1))
                : player2Score.runs,
            balls:
              extra.at(-1) == "WD" ||
              extra.at(-1) == "NB" ||
              extra.at(-1).includes("NB")
                ? player2Score.balls
                : player2Score.balls - 1,
            fours:
              parseInt(score.at(-1)) == 4 && extra.at(-1) != "WD"
                ? --player2Score.fours
                : player2Score.fours,
            sixs:
              parseInt(score.at(-1)) == 6 && extraValue != "WD"
                ? --player2Score.sixs
                : player2Score.sixs,
          });
        }
      }
      var Overs = bowlerScore.over.split(".");

      setbowlerScore({
        ...bowlerScore,
        over:
          extra.at(-1) == "" || extra.at(-1) == "BYE" || extra.at(-1) == "LB"
            ? parseInt(Overs[0]) + "." + (parseInt(Overs[1]) - 1)
            : bowlerScore.over,
        runs:
          extra.at(-1) == "WD" ||
          extra.at(-1) == "NB" ||
          extra.at(-1).includes("NB")
            ? parseInt(bowlerScore.runs) - parseInt(score.at(-1)) - 1
            : parseInt(bowlerScore.runs) - parseInt(score.at(-1)),
      });
      SetCurrentBall({
        ...currentBall,
        BallNumber: ballCounts.pop(),
        BatsmenID: currentPlayerID.pop(),
        BowlerID: parseInt(strikeBowler),
        Run: score.pop(),
        ExtraType: extra.pop(),
        OutType: outType.pop(),
        OutBy: outBy.pop(),
        OutPlayerID: runoutPLayerId.pop(),
      });
    }
  };
  return (
    <>
      <div className="row mb-2 mx-3" style={{ backgroundColor: "#F2F2F2" }}>
        <div
          className="col-6 centerAlign cursorPointer h5"
          onClick={() =>
            setmainPage({ ...mainPage, livePage: "", scorePage: "d-none" })
          }
        >
          <span className={mainPage.livePage == "" ? "bottomColor" : ""}>
            Live
          </span>
        </div>
        <div
          className="col-6 centerAlign cursorPointer h5"
          onClick={() =>
            setmainPage({ ...mainPage, livePage: "d-none", scorePage: "" })
          }
        >
          <span className={mainPage.scorePage == "" ? "bottomColor" : ""}>
            {" "}
            Score
          </span>
        </div>
      </div>
      <div>
        <div className={` ${mainPage.livePage}`}>
          <div className="playersScoreCard">
            {values.SingleOrMultipleTeam === "Multiple" ? (
              <div className="row p-3">
                <div
                  className="centerAlign Lfont1"
                  style={{ fontSize: "14px" }}
                >
                  <span>
                    <b>
                      {teamNames[0].teamA} vs {teamNames[0].teamB} (
                      {values.overs} Overs)
                    </b>
                  </span>
                </div>
              </div>
            ) : (
              <div className="row p-3">
                <div
                  className="centerAlign Lfont1"
                  style={{ fontSize: "18px" }}
                >
                  <span>
                    <b>Scoreboard</b>
                    <hr />
                  </span>
                </div>
              </div>
            )}
            <div className="row ">
              {values.SingleOrMultipleTeam === "Multiple" ? (
                <div className="col-12 p-0 Lfont2 text-center">
                  {currentTeamID === 2
                    ? teamNames[0].teamA
                    : teamNames[0].teamB}{" "}
                  : {totalScore.runs}/{totalScore.wickets}
                  <span className="ms-2 Lfont3 ">{`(${overCount}.${scoreCount})`}</span>
                  <span className="ms-2 Lfont3 ">{`(CRR: ${GetRunRate(
                    totalScore.runs,
                    overCount,
                    scoreCount
                  )})`}</span>
                </div>
              ) : null}
            </div>
            {/* <div className="row">
            <div className="col-12 p-0 Lfont4 text-center">
              Team A won the toss and opt to bat
            </div>
          </div> */}
            {values.SingleOrMultipleTeam === "Multiple" ? (
              <div className="row py-3 px-4">
                <div className="col-7 Lfont3 ps-0">Target</div>
                <div className="col-5 Lfont3 px-0">
                  Partnership : {partnershipScore.runs}({partnershipScore.balls}
                  )
                </div>
              </div>
            ) : null}

            <div className="row p-4 pt-0">
              <div className="col-6">
                <div className="row Lfont5">
                  <div className="col-12 ps-0">
                    {player1Score.name} :{player1Score.runs}(
                    {player1Score.balls})<span className="star1">*</span>
                  </div>
                </div>
                <div className="row Lfont6 mt-2">
                  ( 4s - {player1Score.fours} , 6s - {player1Score.sixs} )
                </div>
                <div className="row Lfont6 mt-2">
                  SR - {GetStrikeRate(player1Score.runs, player1Score.balls)}
                </div>
              </div>
              <div className="col-6 ">
                <div className="row Lfont5 ">
                  <div className="col-12 ps-0">
                    {player2Score.name} :{player2Score.runs}(
                    {player2Score.balls})<span className="star2 d-none">*</span>
                  </div>
                </div>
                <div className="row Lfont6 mt-2">
                  ( 4s - {player2Score.fours} , 6s - {player2Score.sixs} )
                </div>
                <div className="row Lfont6 mt-2">
                  SR - {GetStrikeRate(player2Score.runs, player2Score.balls)}
                </div>
                {/* <div className="row ">
                <div className="col-12 p-0  Lfont7">
                  Axar Patel
                  <img
                    src="./images/ballIcon.png"
                    alt=""
                    className="ms-1 img-fluid"
                  />
                </div>
              </div> */}
                {/* <div className="row Lfont7  mt-2  ">0.5 - 0 - 10 - 0</div> */}
                {/* <div className="row">
                <div className="row Lfont7 mt-4">KulDeep Yadev</div>
                <div className="row Lfont7 mt-2">2 - 0 - 20 - 0</div>
              </div> */}
              </div>
            </div>
            <div className="row rowPadding">
              <div className="col-12 " style={{ background: "#555555" }}>
                <div className="row mb-4">
                  <div className="col-12">
                    {bowlerScore.name}{" "}
                    <img
                      src="./images/ballIcon.png"
                      alt=""
                      className="img-fluid"
                    />{" "}
                    ({bowlerScore.over} - {bowlerScore.maiden} -{" "}
                    {bowlerScore.runs} - {bowlerScore.wickets})
                  </div>
                  {/* <div className="col-7">This Over :- {thisOver}</div> */}
                </div>

                <div className="row my-2">
                  {score.map(function (data, i) {
                    var returdata;
                    returdata = (
                      <div className="col-2" key={i}>
                        <div
                          className={`runColor ${
                            data == 0 || data == 1 || data == 2 || data == 3
                              ? "runsColor"
                              : data == 4
                              ? "fourboundarycolor"
                              : data == 6
                              ? "sixboundarycolor"
                              : data == "OUT"
                              ? "out"
                              : ""
                          }`}
                        >
                          {data}
                        </div>
                        <div className="row">
                          <div className="col-2" key={i}>
                            <span>
                              <div className="runColor">{extra[i]}</div>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                    return returdata;
                  })}
                </div>
              </div>
            </div>
          </div>

          <Card className=" mt-3">
            <Card.Body className="RunInputButton">
              <div className="row mt-2 text-center">
                <div className="col-3">
                  <button
                    className="btn "
                    value="0"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    0
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className="btn "
                    value="1"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    1
                  </button>
                </div>
                <div className="col-3">
                  <button
                    className="btn "
                    value="2"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    2
                  </button>
                </div>
                {/* </div>
          <div className="row mt-2 text-center"> */}
                <div className="col-3">
                  <button className="btn  " value="3" onClick={UndoLastBall}>
                    <img src="./images/backArrow.svg" />
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn "
                    value="3"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    3
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn fourboundarycolor"
                    value="4"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    4
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn sixboundarycolor"
                    value="6"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    6
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn out"
                    value="OUT"
                    onClick={(e) => addScore(e.target.value)}
                  >
                    OUT
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn extraScore"
                    value="WD"
                    id="one"
                    onClick={(e) => ExtraScore(e.target.value)}
                  >
                    WD
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn extraScore"
                    value="NB"
                    id="two"
                    onClick={(e) => ExtraScore(e.target.value)}
                  >
                    NB
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn extraScore"
                    value="LB"
                    id="three"
                    onClick={(e) => ExtraScore(e.target.value)}
                  >
                    LB
                  </button>
                </div>
                <div className="col-3 mt-2">
                  <button
                    className="btn extraScore"
                    value="BYE"
                    id="four"
                    onClick={(e) => ExtraScore(e.target.value)}
                  >
                    BYE
                  </button>
                </div>

                {/* <div className="col-3 mt-2">
              <button
                className="btn "
                value="retire"
                // onClick={(e) => addScore(e.target.value)}
              >
                RETIRE
              </button>
            </div> */}

                {/* <div className="col-4 mt-3">
              <button
                className="btn extraScore"
                value="AddBowler"
                id="four"
                onClick={(e) => addBatsmenBowler(e.target.value)}
              >
                Add Bowler
              </button> */}

                {/* </div> */}
                <div className="col-2"></div>
              </div>
            </Card.Body>
          </Card>
          <div className="d-none NextOverButton">
            <button
              className="btn profileformControl"
              onClick={() => SetFinalOverButton(true)}
            >
              NEXT OVER
            </button>
          </div>
          <Popup
            // show={popupshow.popup1}
            show={popup1}
            // onHide={closePopupHandler}
            class="outClass"
            teamname={
              teamTypeID === 2 ? teamNames[0].teamA : teamNames[0].teamB
            }
          >
            <OutScorePopup
              popupshow={popupshow}
              onHide={setpopup1}
              totalPlayers={bowlingTeam}
              player1Score={player1Score}
              player2Score={player2Score}
              battingTeam={battingTeam}
              SetOutType={SetOutType}
              SetOutBy={SetOutBy}
              outType={outType}
              outBy={outBy}
              setplayer1Score={setplayer1Score}
              setplayer2Score={setplayer2Score}
              runoutPLayerId={runoutPLayerId}
              setrunoutPLayerId={setrunoutPLayerId}
              scoreCount={scoreCount}
              outplayersList={outplayersList}
              setoutplayersList={setoutplayersList}
              currentBall={currentBall}
              prevStep={prevStep}
              setDynamicValues={setDynamicValues}
              inningsType={values.inningsType}
            />
          </Popup>
          {/* <Popup
        show={popupshow.popup2}
        class="outClass"
        teamname={teamTypeID === 2 ? teamNames[0].teamA : teamNames[0].teamB}
      >
        <AddBatsmen totalPlayers={bowlingTeam} onHide={closePopupHandler} />
      </Popup> */}
          <Popup
            show={popupshow.popup3}
            class="outClass"
            teamname={
              teamTypeID === 2 ? teamNames[0].teamB : teamNames[0].teamA
            }
          >
            <AddBowler
              totalPlayers={bowlingTeam}
              onHide={() => {
                setPopupShow({ ...popupshow, popup3: false });
                setpopup1(false);
              }}
              setbowlerScore={setbowlerScore}
              bowlerScore={bowlerScore}
              overs={values.overs}
              overCount={overCount}
              currentBall={currentBall}
              prevStep={prevStep}
              setDynamicValues={setDynamicValues}
              inningsType={values.inningsType}
              SetFinalOverButton={SetFinalOverButton}
            />
          </Popup>
        </div>
      </div>
      <div className={mainPage.scorePage}>
        <ScoreCard />
      </div>
    </>
  );
}
