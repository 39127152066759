import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "./Utils/APIConnector";
import {
  convertDecimalToTime,
  convertTo12HourFormat,
  minToHour,
} from "./Utils/common";
import Swal from "sweetalert2";
import Loader from "./Utils/Loader";
import { Carousel } from "react-bootstrap";

export default function ShowVenueDetails() {
  const navigate = useNavigate();
  const [venueDetails, setVenueDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const baseurl = process.env.REACT_APP_Base_URL;
  const userid = window.localStorage.getItem("userid");
  const payout = (e) => {
    var raw = JSON.stringify({
      userid: userid,
      amount: venueDetails?.totalEarning,
      venueid: location.state.id,
    });
    CustomPostAPI("/payment/Payout", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        if (result === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Your withdrawal request is under review",
            text: "The review process is usually completed within 2 business days.",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            confirmButtonColor: "#000000",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    CustomGetAPI("/Venue/Approveorrejectvenue/" + location.state.id).then(
      (result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          setIsLoading(false);
          setVenueDetails(result);
        }
      }
    );
  }, [location.state.id]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <div className="Container">
          <div className="row px-2">
            <div className="col-12">
              <img
                className="cursorClass"
                src="/images/backArrow.svg"
                onClick={() => navigate("/home-venue-owner")}
              />
            </div>
            <div className="col-12">
              <h4
                className="text-center fw-bold p-2"
                style={{ color: "#a51e22" }}
              >
                Venue Details
              </h4>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-12 text-center mb-3 withdrawIcons">
                  <label>Available balance : </label>
                  <span> ₹ {venueDetails?.totalEarning}</span>
                </div>
                <div className="col-6 d-flex align-items-center">
                  <button
                    className="btn-withdraw-history"
                    onClick={(e) => {
                      navigate("/withdrawal-history", {
                        state: { id: location.state.id },
                      });
                    }}
                  >
                    <img
                      src="/images/withdrawal_history.svg"
                      alt=""
                      className="img-fluid pe-1"
                    />
                    Withdrawal history
                  </button>
                </div>
                <div
                  className="col-6 d-flex align-items-center"
                  onClick={(e) => {
                    if (
                      venueDetails.showButton &&
                      venueDetails.totalEarning > 0
                    ) {
                      Swal.fire({
                        icon: "warning",
                        title: "Confirm Withdrawal",
                        text: `Total Withdrawal Amount : ₹ ${venueDetails?.totalEarning}`,
                        showConfirmButton: true,
                        confirmButtonText: "Confirm",
                        confirmButtonColor: "#000000",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setIsLoading(true);
                          payout(e);
                        }
                      });
                    } else if (venueDetails.totalEarning === 0) {
                      Swal.fire({
                        icon: "error",
                        title: "Unable to Withdraw",
                        text: `Available Balance is Zero `,
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                      });
                    } else {
                      Swal.fire({
                        icon: "error",
                        title: "Unable to Withdraw",
                        text: `Request in Progress`,
                        showConfirmButton: true,
                        confirmButtonText: "Ok",
                      });
                    }
                  }}
                >
                  <button className="btn-withdraw">
                    <img
                      src="/images/withdrawal_btn.svg"
                      alt=""
                      className="img-fluid pe-1"
                    />
                    Withdraw money
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 p-0">
            <div className="dotted-line"></div>
          </div>
          <div className="text-center mt-3">
            <img src="/images/infovector.svg" className="img-fluid" alt="" />
            <span className="mx-1 fw-bold">Venue Information</span>
          </div>
          <div className="row adminlist">
            <div className="col-12 mt-3">
              <label>Venue Name:</label>&nbsp;{venueDetails?.name}
            </div>
            <div className="col-12 mt-3">
              <label>Photos of Venue:</label>
            </div>
            <div className="my-3">
              <Carousel controls={true} indicators={false}>
                {venueDetails?.venueImages?.map((data, i) => (
                  <Carousel.Item key={i}>
                    <img
                      src={`${baseurl}${data?.image}`}
                      className="venuePhotos1 img-fluid w-100"
                      alt="image"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            {/* <div className="col-12 mt-3">
              <div className="row">
                {venueDetails?.venueImages?.map((image, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-9 m-auto text-center my-2"
                  >
                    <img
                      className="img-fluid venuePhotos w-auto"
                      src={`${baseurl}${image?.image}`}
                    />
                  </div>
                ))}
              </div>
            </div> */}
            <div className="col-12 mt-3">
              <label>Description/About the Venue:</label>
            </div>
            <div className="col-12 mt-2">{venueDetails?.description}</div>
            <div className="col-12 mt-3">
              <label>Venue Operating Hours:</label>{" "}
              {convertTo12HourFormat(venueDetails?.startTime) === "12:00 am" &&
              convertTo12HourFormat(venueDetails?.endTime) === "11:59 pm"
                ? "24 Hours"
                : `${convertTo12HourFormat(
                    venueDetails?.startTime
                  )} - ${convertTo12HourFormat(venueDetails?.endTime)}`}
            </div>
            <div className="col-12 mt-3">
              <label>Minimun Booking Time:</label>{" "}
              {convertDecimalToTime(minToHour(venueDetails?.minSlotTime))}
            </div>
            <div className="col-12 mt-3">
              <label>Cancellation Policy:</label>{" "}
              {venueDetails?.cancellationTime}
            </div>
            <div className="col-12 mt-3">
              <label>Reschedule Hours:</label> {venueDetails?.rescheduleHours}
            </div>
            <div className="col-12 mt-3">
              <label>Reschedule Count:</label> {venueDetails?.rescheduleCount}
            </div>
            <div className="col-12 mt-1 p-0">
              <div className="dotted-line"></div>
            </div>
            <div className="text-center mt-3">
              <img src="/images/contactinfo.svg" className="img-fluid" alt="" />
              <span className="mx-1 fw-bold">Contact Information</span>
            </div>
            <div className="col-12 mt-3">
              <label>Email:</label>&nbsp;{venueDetails?.email}
            </div>
            <div className="col-12 mt-3">
              <label>Phone Number:</label>&nbsp;{venueDetails?.phone}
            </div>
            <div className="col-12 mt-1 p-0">
              <div className="dotted-line"></div>
            </div>
            <div className="text-center mt-3">
              <img
                src="/images/locationinfo.svg"
                className="img-fluid"
                alt=""
              />
              <span className="mx-1 fw-bold">Location</span>
            </div>
            <div className="col-12 mt-3">
              <label>Street:</label>&nbsp;
              {venueDetails?.address?.addressline1}
            </div>
            <div className="col-12 mt-3">
              <label>Area/Locality:</label>&nbsp;
              {venueDetails?.address?.addressline2}
            </div>
            {venueDetails?.address?.addressline3 && (
              <div className="col-12 mt-3">
                <label>Landmark:</label>&nbsp;
                {venueDetails?.address?.addressline3}
              </div>
            )}
            <div className="row mt-3">
              <div className="col-6">
                <label>City:</label>&nbsp;{venueDetails?.address?.cityName}
              </div>
              <div className="col-6">
                <label>State:</label>&nbsp;{venueDetails?.address?.stateName}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <label>Country:</label>&nbsp;India
              </div>
              <div className="col-6">
                <label>Zip code:</label>&nbsp;{venueDetails?.address?.zipcode}
              </div>
            </div>
            <div className="col-12 p-0">
              <div className="dotted-line"></div>
            </div>
            <div className="text-center mt-3">
              <span className="mx-1 fw-bold">Facilities</span>
            </div>
            <div className="col-12 mt-2">
              <div className="row">
                <div className="accordion" id="accordionExample">
                  {venueDetails?.sportsFacility.map((surface, index) => (
                    <>
                      <div className="accordion-item mt-3">
                        <h2 className="accordion-header" id={`header-${index}`}>
                          <div
                            className="row mx-0 p-2"
                            style={{ backgroundColor: "#f5f5f5" }}
                          >
                            <div
                              className="col-10 display-center ps-0 colorClass fw-bold"
                              style={{
                                backgroundColor: "#F2F2F2 !important",
                                color: "black !important",
                                fontSize: "14px",
                                fontWeight: 500,
                              }}
                            >
                              {venueDetails?.category == "Sports"
                                ? "Court/Surface"
                                : "Event"}{" "}
                              {index + 1} : {surface?.surfaceName}
                            </div>

                            <div className="col-2 pe-0">
                              <button
                                className={`accordion-button accordion-button-child ${
                                  index != 0 && "collapsed"
                                }`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${index}`}
                                aria-expanded={index == 0 ? "true" : "false"}
                                aria-controls={`collapse${index}`}
                                style={{ float: "right" }}
                              ></button>
                            </div>
                          </div>
                        </h2>
                        <div
                          id={`collapse${index}`}
                          className={`accordion-collapse collapse ${
                            index == 0 && "show"
                          }`}
                          collapse
                          show
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            className="accordion-body"
                            style={{ backgroundColor: "#F9F9F9" }}
                          >
                            {/* <div className="col-12 my-1">
                              <label>Court Count:</label>&nbsp;
                              {surface?.courtCount}

                            </div>
                            <div className="dotted-line"></div> */}

                            <div className="col-12 my-1">
                              <label>
                                Selected{" "}
                                {venueDetails?.category == "Sports"
                                  ? "Sports"
                                  : "Event"}
                                :
                              </label>
                              &nbsp;
                              {surface?.sports?.map((sport, index) => (
                                <React.Fragment key={index}>
                                  {index > 0 && ", "}
                                  {sport?.label}
                                </React.Fragment>
                              ))}
                            </div>
                            <div className="dotted-line"></div>
                            <div className="text-center my-3 colorClass">
                              Booking day availability & rates
                            </div>
                            <div className="row mt-3">
                              {surface?.weekDay?.map((day, dayIndex) => {
                                const isGoKarting =
                                  surface?.sports[0]?.label === "Go-Karting";

                                if (isGoKarting) {
                                  return (
                                    day?.weekDays !== "" &&
                                    day?.data?.length > 0 && (
                                      <div key={dayIndex} className="col-md-6">
                                        <div
                                          className="p-3 mb-3"
                                          style={{
                                            backgroundColor: "#e8e8e8",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              className="fw-600"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {day.weekDays}
                                            </div>
                                          </div>
                                          {day?.data?.map((item, idx) => (
                                            <div key={idx} className="mt-2">
                                              <div>
                                                {item.duration1 &&
                                                  "5 Min: Amount: ₹" +
                                                    item.duration1}{" "}
                                                <br />
                                                {item.duration2 &&
                                                  "10 Min: Amount: ₹" +
                                                    item.duration2}{" "}
                                                <br />
                                                {item.duration3 &&
                                                  "15 Min: Amount: ₹" +
                                                    item.duration3}{" "}
                                                <br />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )
                                  );
                                } else {
                                  return (
                                    day?.weekDays !== "" &&
                                    day?.data?.length > 0 &&
                                    day?.data[0]?.startTime !== "" &&
                                    day?.data[0]?.amount !== "" &&
                                    day?.data[0]?.amount !== false &&
                                    day?.data[0]?.amount !== "0" && (
                                      <div key={dayIndex} className="col-md-6">
                                        <div
                                          className="p-3 mb-3"
                                          style={{
                                            backgroundColor: "#e8e8e8",
                                            borderRadius: "10px",
                                          }}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              className="fw-600"
                                              style={{ fontSize: "16px" }}
                                            >
                                              {day.weekDays}
                                            </div>
                                          </div>
                                          {day?.data?.map((item, idx) => (
                                            <div key={idx} className="mt-2">
                                              <div>
                                                Time:{" "}
                                                {convertTo12HourFormat(
                                                  item.startTime
                                                )}{" "}
                                                to{" "}
                                                {convertTo12HourFormat(
                                                  item.endTime
                                                )}{" "}
                                                <br />
                                                Amount: ₹{item.amount}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )
                                  );
                                }
                              })}
                            </div>
                            {surface?.rehearsalprice > 0 ||
                            surface?.extrahourprice > 0 ? (
                              <div className="dotted-line mb-3"></div>
                            ) : (
                              <></>
                            )}
                            {/* <div className="mt-2">
                              <label>Minimum Booking Time:</label>{" "}
                              {surface?.minslottime} mins
                            </div> */}
                            {surface?.rehearsalprice > 0 && (
                              <>
                                <div className="mt-2">
                                  <label>Rehersal Price:</label>{" "}
                                  {surface?.rehearsalprice}₹
                                </div>
                              </>
                            )}
                            {surface?.extrahourprice > 0 && (
                              <>
                                <div className="mt-2">
                                  <label>Extra Per Hour Charge:</label>{" "}
                                  {surface?.extrahourprice}₹
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <div className="dotted-line mt-3"></div>
                <div className="col-12 my-3">
                  <label>Selected Amenities:</label>&nbsp;
                  {venueDetails?.venueAmenities?.map((item, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && ", "}
                      {item?.name}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
