import axios from "axios";
import React, { useEffect, useState } from "react";
import { CustomPostAPI, PostAPI } from "../Utils/APIConnector";
import { Navigate } from "react-router-dom";
import Loader from "../Utils/Loader";
import { compressImage } from "../Utils/common";

export const Amenities = ({
  setcurrentStep,
  // isLoading,
  Amenitieslist,
  setAmenitieslist,
  formValuesSportsFacilities,
  setIsFirstTime,
  checkedAmenities,
  setCheckedAmenities,
  setIsSecondTime,
  setEditFirst,
  isEditFirst,
  venueOffers,
  venueOffersImage,
  setVenueOffers,
  setVenueOffersImage,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);
  const handleCheckboxChange = (e, i) => {
    isEditFirst === "0" ? setEditFirst("0") : setEditFirst("2");
    let list = [...Amenitieslist];
    const foundItem = list.find((item) => item.amenityId === i);
    if (foundItem) {
      foundItem.checked = e.target.checked;
      setAmenitieslist(list);
      setCheckedAmenities((prevChecked) => {
        const updatedChecked = e.target.checked
          ? [...prevChecked, i]
          : prevChecked.filter((id) => id !== i);
        return updatedChecked;
      });
    }
  };

  const handleSubmit = async () => {
    const venueAmenities = checkedAmenities.map((amenity) => ({
      Id: amenity,
    }));

    const venueOffer = [{ Image: venueOffersImage, Description: venueOffers }];
    const sportsID = formValuesSportsFacilities[0].Sports.map(
      (item) => item.value
    );
    var raw = JSON.stringify({
      stage: 3,
      Userid: window.localStorage.getItem("userid"),
      VenueId: formValuesSportsFacilities[0].VenueId,
      VenueAmenities: venueAmenities,
      venueOffers: venueOffer,
    });
    {
      isEditFirst === "0"
        ? await CustomPostAPI("/Venue/Createvenue", raw).then((result) => {
            if (result == undefined || result == "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              if (result.status == "Success") {
                formValuesSportsFacilities[0].VenueId = result.venueid;
                window.location = "/home-venue-owner";
              } else {
                alert(result.message);
              }
            }
          })
        : await CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
            if (result == undefined || result == "") {
              // alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              if (result.status == "Success") {
                formValuesSportsFacilities[0].VenueId = result.venueid;
                window.location = "/home-venue-owner";
              } else {
                alert(result.message);
              }
            }
          });
    }
  };

  const prevStep = () => {
    setcurrentStep(2);
    // setIsSecondTime("2");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredAmenities = Amenitieslist?.filter((data) =>
    data.amenityName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleBannerChange = async (event) => {
    setErrorMessage("");
    const file = event.target.files[0];
    const extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      setErrorMessage(
        `Invalid file type for ${file.name}. Only JPG, JPEG, and PNG files are allowed.`
      );
      return;
    }
    let compressedFile = await compressImage(file);
    if (compressedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setVenueOffersImage(reader.result);
      };
      reader.readAsDataURL(compressedFile);
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="30%" />
      ) : (
        <div className="row px2">
          <div className="col-12 venueInformationHeader">Amenities</div>
          <div className="col-12 venueInformationBody mt-2">
            <div className="row">
              <div className="col-12">
                <div className="mx-3 posRel">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Amenities"
                    style={{ padding: "6px 10px 6px 40px" }}
                    onChange={handleSearch}
                  />
                  <img
                    src="/images/searchicon.svg"
                    style={{ marginTop: "-63px", marginLeft: "12px" }}
                  />
                </div>
                <div className="row mx-3">
                  {filteredAmenities?.map((data, i) => (
                    <div className="col-6 my-2" key={i}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`flexCheckDefault-${i}`}
                        onClick={(e) => {
                          handleCheckboxChange(e, data?.amenityId);
                        }}
                        checked={checkedAmenities.includes(data?.amenityId)}
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor={`flexCheckDefault-${i}`}
                      >
                        {data.amenityName}
                      </label>
                    </div>
                  ))}
                </div>
                <hr />
                <div className="row mx-3">
                  <b className="px-0">
                    Do you have any ongoing offer at your venue?
                  </b>
                  <br />
                  <small className="mt-2 px-0">
                    (You can put simple text below, for example, "Book 1 hour
                    and get 1 hour free on weekdays!" OR, you can simply upload
                    any digital banner of your ongoing offer! We will promote
                    your offer on your behalf to all of our users and followers
                    of Instagram at zero cost. Also, you will get the benefit of
                    being listed at the top with Ongoing Offers in your
                    area/city compared to other venues!)
                  </small>
                  <input
                    type="text"
                    placeholder="Type offer(optional)"
                    className="form-control mt-3"
                    name="venueOffers"
                    value={venueOffers}
                    onChange={(e) => setVenueOffers(e.target.value)}
                  />
                  <p className="text-center my-1">or</p>
                  <div className="d-flex justify-content-center position-relative">
                    {!venueOffersImage && (
                      <>
                        <div className="row">
                          <div className="col-12">
                            <label
                              htmlFor="bannerUpload"
                              className="venueBannerbtn"
                            >
                              Click here to upload banner
                            </label>
                          </div>
                          <div className="col-12">
                            {ErrorMessage != "" && (
                              <small className="text-danger">
                                {ErrorMessage}
                              </small>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    <input
                      type="file"
                      id="bannerUpload"
                      className="d-none"
                      accept=".jpg, .jpeg, .png"
                      onChange={(e) => handleBannerChange(e)}
                    />

                    {venueOffersImage && (
                      <div className="">
                        <img
                          src={venueOffersImage}
                          alt="Banner Preview"
                          className="img-fluid"
                        />
                        <img
                          src="/images/Closeicon.svg"
                          className="venueBannerCross"
                          onClick={() => setVenueOffersImage()}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 d-flex justify-content-center">
            <div className="col-6">
              <button onClick={prevStep} className="btn btn-gray">
                Previous
              </button>
            </div>
            <div className="col-6">
              <button onClick={handleSubmit} className="btn btn-danger">
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Amenities;
