import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { changeColor } from "./reducer";

export default function ChangeColor() {
  const dispatch = useDispatch();
  const themeColor = useSelector((state) => state.theme.value);
  return (
    <div>
      <button
        onClick={() => {
          dispatch(changeColor(window.location.href));
        }}
      >
        CHANGE COLOR
      </button>
      <h1>{themeColor}</h1>
    </div>
  );
}
