import React from "react";

export default function DetailsCard({
  heading,
  description,
  gradient,
  imageSrc,
}) {
  return (
    <React.Fragment>
      <div
        className="info-card text-center px-3 py-2 h-100"
        style={{ "--gradient": gradient }}
      >
        <div className="info-card-content">
          <div className="info-card-heading">{heading}</div>
          <div className="info-card-description">{description}</div>
        </div>

        <img src={`/images/${imageSrc}.svg`} alt="logo" />
      </div>
    </React.Fragment>
  );
}
