import React from "react";

export default function VenueInfo1({
  step,
  handleNextStep,
  dispatch,
  state,
  handleChange,
}) {
  return (
    <>
      <div className="row">
        <div className="col-12">What is your venue name?</div>
        <div className="col-12 mt-2">
          <input
            type="text"
            name="VenueName"
            placeholder="Name*"
            className="form-control"
            value={state?.VenueName}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">Where is your venue located?</div>
        <div className="col-6 mt-2">
          <input
            type="text"
            name="Address1"
            placeholder="Street*"
            value={state?.Address1}
            className="form-control"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-6 mt-2">
          <input
            type="text"
            name="Landmark"
            placeholder="Landmark"
            className="form-control"
          />
        </div>
        <div className="col-4 mt-2">
          <input
            type="text"
            name="Address2"
            placeholder="Area*"
            className="form-control"
            value={state?.Address2}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-4 mt-2">
          <input
            type="text"
            name="State"
            placeholder="State*"
            className="form-control"
          />
        </div>
        <div className="col-4 mt-2">
          <input
            type="text"
            name="City"
            placeholder="City*"
            className="form-control"
          />
        </div>
        <div className="col-4 mt-2">
          <input
            type="text"
            name="City"
            placeholder="Zip code*"
            className="form-control"
          />
        </div>
      </div>
      {step === 1 && (
        <div className="row mt-5 d-flex justify-content-center align-items-center">
          <div className="col-6 col-md-4">
            <button onClick={handleNextStep} className="btn btn-danger">
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
}
