import React, { Component } from "react";
import { MatchPlayerInfo } from "./CreateMatchComponent/MatchPlayerInfo";
import { MatchInfo } from "./CreateMatchComponent/MatchInfo";
import MultiStepBar from "./Utils/ProcessBar/MultiStepBar";
import { MatchDetailPreview } from "./CreateMatchComponent/MatchDetailsPreview";
import { MatchPayment } from "./CreateMatchComponent/MatchPayment";
import { MatchVerified } from "./CreateMatchComponent/MatchVerified";
import { Authentication } from "./Utils/PopupModal/Authentication";
import { Welcomepage } from "./welcomepage";
import HelmetMetaTag from "./Utils/HelmetMetaTag/HelmetMetaTag";

export default class createownmatch extends Component {
  state = {
    step: 1,
    currentPlayer: "",
    needPlayer: "",
    TotalBowler: "",
    TotalBatsmen: "",
    AllRounders: "",
    WicketKeeprs: "",
    IsChecked: false,
    GroundName: "",
    MatchDate: "",
    MatchTime: "",
    TypeOfMatch: "",
    MatchType: "Box",
    TotalOvers: "",
    MatchName: "",
    MatchFees: "100",
    MatchID: 0,
    PaymentID: "",
    OrderID: "",
    Status: "",
    IsGuest: false,
    NameOfGround: "",
    SelectedCityName: "",
    otherGroundAddress: "",
    showExitPrompt: true,
  };
  initBeforeUnLoad = (showExitPrompt) => {
    var isOnIOS =
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i);
    isOnIOS
      ? (window.onpagehide = (event) => {
          if (this.state.showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
              e.returnValue = "";
            }
            return "";
          }
        })
      : (window.onbeforeunload = (event) => {
          if (this.state.showExitPrompt) {
            const e = event || window.event;
            e.preventDefault();
            if (e) {
              e.returnValue = "";
            }
            return "";
          }
        });
  };
  componentDidMount = () => {
    const userType = window.localStorage.getItem("UserType");
    const userid = window.localStorage.getItem("userid");
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    if (
      isNaN(userid) ||
      userid === null ||
      userid === 0 ||
      userType === "Guest" ||
      userType === "" ||
      userType === null
    ) {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.location = "/welcome";
      // const { step } = this.state;
      // this.setState({
      //   step: step - 1,
      //   IsGuest: true,
      // });
    } else {
      this.initBeforeUnLoad(this.state.showExitPrompt);
    }
  };

  prevStep = () => {
    // window.scrollTo(0, 0);
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  redirectToPreviousPage = () => {
    window.history.go(-1);
  };
  // proceed to the next step
  nextStep = () => {
    // window.scrollTo(0, 0);
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleMatchTypeChange = (event) => {
    this.setState({
      MatchType: event.target.value, // Update TypeOfMatch state with the selected value
    });
  };
  // Handle fields change
  handleChange = (input) => (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    if (e.target.type === "date") {
      // const dateVal = e.target.value.split("-").reverse().join("-")
      this.setState({ [input]: value });
    } else {
      this.setState({ [input]: value });
    }
    // else {

    //   this.setState({ [input]: value }, () => {

    //     if (input === "currentPlayer" || input === "needPlayer") {
    //       const totalPlayers = this.state.currentPlayer + this.state.needPlayer;

    //       if (totalPlayers > 22) {

    //         <span className="validationText">Total players cannot exceed 22</span>

    //       } else {

    //         this.setState({ errorMessage: "" });
    //       }
    //     }
    //   });
    // }
  };

  handleTotalPlayer = (total) => {
    this.setState({
      needPlayer: total,
    });
  };

  validatedInputs = (input, value) => {
    this.setState({
      [input]: value,
    });
  };
  closeGuestPopup = () => {
    this.setState({
      IsGuest: false,
    });
    window.history.back();
  };
  render() {
    const { step } = this.state;
    const { IsGuest } = this.state;
    const {
      currentPlayer,
      needPlayer,
      TotalBowler,
      TotalBatsmen,
      AllRounders,
      WicketKeeprs,
      IsChecked,
      GroundName,
      MatchDate,
      MatchTime,
      TypeOfMatch,
      MatchType,
      TotalOvers,
      MatchName,
      MatchFees,
      MatchID,
      PaymentID,
      OrderID,
      Status,
      NameOfGround,
      SelectedCityName,
      otherGroundAddress,
      showExitPrompt,
    } = this.state;

    const values = {
      currentPlayer,
      needPlayer,
      TotalBowler,
      TotalBatsmen,
      AllRounders,
      WicketKeeprs,
      IsChecked,
      GroundName,
      MatchDate,
      MatchTime,
      TypeOfMatch,
      TotalOvers,
      MatchName,
      MatchFees,
      MatchID,
      PaymentID,
      OrderID,
      Status,
      MatchType,
      NameOfGround,
      SelectedCityName,
      otherGroundAddress,
      showExitPrompt,
    };
    switch (step) {
      // case 0:
      //   return (
      //     <>
      //       <Authentication
      //         show={IsGuest}
      //         onClick={this.closeGuestPopup}
      //         onHide={this.closeGuestPopup}
      //       ></Authentication>
      //     </>
      //   );
      case 1:
        return (
          <>
            <HelmetMetaTag
              titleName="Play Cricket - Find Missing Players and Join Matches | CricBuddy"
              canonical={window.location.href}
              desc="Looking for missing players to complete your cricket match lineup? Find them and invite them to join your game on CricBuddy. Play cricket with a full team and enjoy the game."
              ogtitle="Play Cricket - Find Missing Players and Join Matches | CricBuddy"
              ogdesc="Looking for missing players to complete your cricket match lineup? Find them and invite them to join your game on CricBuddy. Play cricket with a full team and enjoy the game."
              ogtype="website"
              ogurl={window.location.href}
            />
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={(e) => (window.location = "/home")}
                />
              </div>
            </div>
            <div className="p-2 text-center">
              <label>
                <h4>Create new match and find players</h4>
              </label>
            </div>
            <MultiStepBar currentStep={1} totalSteps={4} />
            <MatchPlayerInfo
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              handleMatchTypeChange={this.handleMatchTypeChange}
              values={values}
              handleTotalPlayer={this.handleTotalPlayer}
            />
          </>
        );
      case 2:
        return (
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={this.redirectToPreviousPage}
                />
              </div>
            </div>
            <div className="p-2 text-center">
              <label>
                <h4>Create new match and find players</h4>
              </label>
            </div>
            <MultiStepBar currentStep={2} totalSteps={4} />
            <MatchInfo
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
              validatedInputs={this.validatedInputs}
            />
          </>
        );
      case 3:
        return (
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={this.redirectToPreviousPage}
                />
              </div>
            </div>
            <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
            <div className="p-2 text-center">
              <label>
                <h4>Create new match and find players</h4>
              </label>
            </div>
            <MultiStepBar currentStep={3} totalSteps={4} />
            <MatchDetailPreview
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
              validatedInputs={this.validatedInputs}
            />
          </>
        );
      case 4:
        return (
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={this.redirectToPreviousPage}
                />
              </div>
            </div>
            <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
            <div className="p-2 text-center">
              <label>
                <h4>Create new match and find players</h4>
              </label>
            </div>
            <MultiStepBar currentStep={4} totalSteps={4} />
            <MatchPayment
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
              validatedInputs={this.validatedInputs}
            />
          </>
        );
      case 5:
        return (
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={this.redirectToPreviousPage}
                />
              </div>
            </div>
            <MatchVerified
              nextStep={this.nextStep}
              handleChange={this.handleChange}
              values={values}
              handleTotalPlayer={this.handleTotalPlayer}
            />
          </>
        );
      default:
    }
  }
}
