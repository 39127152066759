import React from "react";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {
  ConvertDateTime,
  DBConvertDatetime,
  editIconVisible,
} from "../constants";

import { UserDetails } from "../Utils/PopupModal/UserDetails";
import { GetPlayerRole } from "../constants";
import { useSelector } from "react-redux";
import Popup from "../LiveScoreCardpopups/Popup";
import MatchDetailsEditPage from "../MatchDetailsEditPage";
import { CustomPostAPI, GetAPI, PutAPI } from "../Utils/APIConnector";
import $ from "jquery";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom";

export default function MatchScenario() {
  const MatchID = parseInt(window.localStorage.getItem("MatchDetailID"));
  const navigate = useNavigate();
  const [allMatchesData, setAllMatchesData] = useState([]);
  const [EditAllMatchesData, setEditAllMatchesData] = useState([]);
  const [GetAllPlayers, setGetAllPlayers] = useState([]);
  const [MatchEditPopUp, SetMatchEditPopUp] = useState(false);
  const [userPopUp, SetUserPopUp] = useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [bowlingStyle, SetBowlingStyle] = useState("");
  const [battingStyle, SetBattingStyle] = useState("");
  const [PlayerStats, SetPlayerStats] = useState([]);
  const [value, setValue] = useState("");
  const [filterDatas, setfilterDatas] = useState([]);
  const [filterPlayers, setfilterPlayers] = useState([]);
  const RedirectURL = window.localStorage.getItem("RedirectURL");
  const userid = window.localStorage.getItem("userid");
  const [UserdetailsID, setUserdetailsID] = useState();
  const [existingplayers, setexistingplayers] = useState();
  const [availableplayers, setavailableplayers] = useState();
  const [CompleteCancelMatchAlert, setCompleteCancelMatchAlert] = useState({
    completeAlert: false,
    joinALert: false,
    cancelAlert: false,
    complete: false,
  });

  useEffect(async () => {
    const userType = window.localStorage.getItem("UserType");
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }

    await GetAPI("/Matches/DetailsOfCreatedMatch/" + MatchID).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.matchDetails;
        setexistingplayers(result.ExistingPlayercount);
        setavailableplayers(result.RequiredPlayercount);
        if (result?.status != "Error" && matchList?.length != 0) {
          setAllMatchesData(matchList);
          setEditAllMatchesData(matchList[0].matchesPackage);
          setfilterDatas(matchList[0].joinMatchPackages);
        }
      }
    });
    getAllPLayersFun();
    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };
    // fetch(
    //   "https://playerstation.azurewebsites.net/api/Matches/DetailsOfCreatedMatch/" +
    //     MatchID,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // window.localStorage.removeItem("MatchDetailID");
    //     var matchList = result.matchDetails;
    //     setAllMatchesData(matchList);
    //     setEditAllMatchesData(matchList[0].matchesPackage);
    //     setfilterDatas(matchList[0].joinMatchPackages);
    //   })
    //   .catch((error) => console.log("error", error));
  }, []);

  const getAllPLayersFun = async () => {
    await GetAPI("/Matches/Getavailableplayers/" + MatchID).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        if (result?.status != "Error" && result?.players?.length != 0) {
          // console.log(result);
          setGetAllPlayers(result?.getAllPlayers);
          setfilterPlayers(result?.getAllPlayers);
        }
      }
    });
  };

  const backTopage = () => {
    window.localStorage.removeItem("MatchDetailID");
    window.history.back();
  };

  const showUserDetails = async (detailsUserID) => {
    await GetAPI(
      "/MatchScoreCard/GetPlayersStatistics/?userid=" + parseInt(detailsUserID)
    ).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        // console.log(result);
        if (result.status === "success") {
          SetUserDetails(result.userDetails);
          SetBattingStyle(result.battingstyle);
          SetBowlingStyle(result.bowlingstyleName);
          var player = new Array();
          player.push(result.playerStatistics);
          // console.log(player);
          SetPlayerStats(player);
          SetUserPopUp(true);
        }
      }
    });
  };
  const closePopupHandler = () => {
    SetUserPopUp(false);
  };

  const handleChange = (e) => {
    setValue(e);
    e = parseInt(e);
    var role = allMatchesData[0].joinMatchPackages;
    var roleList = [];
    for (let i = 0; i < role.length; i++) {
      if (e === 0) {
        roleList.push(role[i]);
      } else if (e === 1) {
        if (role[i].playerRoleID == 1) {
          roleList.push(role[i]);
        }
      } else if (e === 2) {
        if (role[i].playerRoleID == 2) {
          roleList.push(role[i]);
        }
      } else if (e === 3) {
        if (role[i].playerRoleID == 3) {
          roleList.push(role[i]);
        }
      } else if (e === 4) {
        if (role[i].playerRoleID == 4) {
          roleList.push(role[i]);
        }
      }
      setfilterDatas(roleList);
    }
  };

  function handlePlayers(status) {
    let filtered = [];

    if (status === "All") {
      filtered = GetAllPlayers;
    } else if (status === "Joined") {
      filtered = GetAllPlayers.filter(
        (player) => player.playerAvailability === 1
      );
    } else if (status === "Not Joined") {
      filtered = GetAllPlayers.filter(
        (player) =>
          player.playerAvailability === 2 || player.playerAvailability === 0
      );
    }
    setfilterPlayers(filtered);
  }

  const SavePlayerStatus = async () => {
    // var array = []
    // $(".PlayerStatuscheckbox").each(function () {
    //     var checked = $(this).find('input').is(':checked');
    //     var val = $(this).find('input').val();
    //     var joinUserId = $(this).find('input').attr('data-joinUserId');
    //     // if(checked){
    //     var obj = new Object();
    //     obj.ID = val
    //     obj.CricketMatchId = MatchID
    //     obj.JoinUserID = joinUserId
    //     obj.PlayerAvailability = checked ? 1 : 0
    //     array.push(obj)
    //     // }
    // })

    await PutAPI(
      "/Matches/Editavailableplayers",
      JSON.stringify(GetAllPlayers)
    ).then((result) => {
      var res = result;
      // console.log(res);
      getAllPLayersFun();
    });
  };
  const handlePlayerStatus = (e) => {
    var val = e.value;
    var playerId = e.id.split("-")[0];
    const updatedItems = GetAllPlayers.map((item) => {
      if (item.id === parseInt(playerId)) {
        return { ...item, playerAvailability: parseInt(val) };
      }
      return item;
    });
    setGetAllPlayers(updatedItems);
    //   setfilterPlayers(updatedItems);
  };
  const CompleteMatchFun = (completematch) => {
    var hasZeroValue = GetAllPlayers.some(
      (obj) => obj.playerAvailability === 0
    );
    hasZeroValue &&
      setCompleteCancelMatchAlert({
        ...CompleteCancelMatchAlert,
        joinALert: true,
      });
    if (completematch && !hasZeroValue) {
      CustomPostAPI("/Payment/Refund/" + MatchID + "/true").then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status == "Success") {
            setCompleteCancelMatchAlert({
              ...CompleteCancelMatchAlert,
              complete: true,
            });
            setTimeout(() => {
              navigate("/created-matches");
            }, 2000);
          }
        }
      });
    } else if (!completematch) {
      setCompleteCancelMatchAlert({
        ...CompleteCancelMatchAlert,
        completeAlert: true,
      });
    }
  };
  const CancelMatchFun = (completematch) => {
    // setCompleteCancelMatchAlert({
    //   ...CompleteCancelMatchAlert,
    //   cancelAlert: true,
    // });
    if (completematch) {
      CustomPostAPI("/Payment/Refund/" + MatchID + "/true").then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status == "Success") {
          } else {
          }
        }
      });
    }
  };
  return (
    <>
      <div className="row px-2">
        <div className="col-12">
          <img
            src="/images/backArrow.svg"
            onClick={() => {
              window.location = RedirectURL;
            }}
          />
        </div>
      </div>
      <div className="Container mb-3">
        {/* <div className="pt-2">
                    <a onClick={backTopage} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <img src="/images/backArrow.svg" />
                    </a>
                    <h5 className="pt-1 text-center"><b>Match Details</b> </h5>
                </div> */}
        <div className="p-2 text-center">
          <label>
            <h4>
              <b>Match</b>
            </h4>
          </label>
        </div>
        {allMatchesData?.length === 0 ? (
          <Card className="mt-3 matchListItem p-0 text-center">
            <Card.Body className="p-0">
              <div className="">
                <div className="profileformControl small">
                  <b>No records found.</b>
                </div>
              </div>
            </Card.Body>
          </Card>
        ) : (
          allMatchesData?.map((str, index) => {
            var returData;
            returData = (
              <Card
                key={index}
                className="mt-3 matchListItem p-0"
                data-item={str.matchesPackage.id}
                data-index={index}
              >
                {/* <Card.Header>
                                        <div className='text-center'>
                                            <a><b>{str.matchesPackage.name} - </b>{str.matchesPackage.sportTypeName}</a>
                                        </div>
                                    </Card.Header> */}
                <Card.Body className="profileformControl">
                  {!editIconVisible(str.matchesPackage.dateAndTime) &&
                  str.matchesPackage.userId == userid ? (
                    <>
                      <div className="row justify-content-end">
                        <div className="col-1 me-2">
                          {/* <img
                            src="./images/editSvg.svg"
                            alt=""
                            onClick={() => SetMatchEditPopUp(true)}
                          /> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Match Name :{" "}
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        {str.matchesPackage.name}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Match Type :{" "}
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        {str.matchesPackage.sportTypeName}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Ground Name :{" "}
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        {str.matchesPackage?.groundName != null
                          ? `${str.matchesPackage.groundName} Cricket Ground`
                          : "Cricket Ground"}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Date & Time :{" "}
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        {DBConvertDatetime(str.matchesPackage.dateAndTime)}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">Overs : </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        {str.matchesPackage.overs}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">Fees : </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">
                        ₹ {str.matchesPackage.entryFees}
                      </label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Existing Players :
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">{existingplayers}</label>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-6">
                      <label className="form-label textLabel">
                        Required Players :{" "}
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="dataText">{availableplayers}</label>
                    </div>
                  </div>

                  <div className="playerStatus mt-5">
                    <h4 className="text-center">
                      <b>Players Status</b>
                    </h4>

                    <div className="row text-center mt-3">
                      <div className="col-10 m-auto">
                        <div className="row">
                          <div className="col-4">
                            <button
                              className="statusButtons w-100"
                              onClick={() => handlePlayers("All")}
                              style={{ backgroundColor: "#3c3c40" }}
                            >
                              All
                            </button>
                          </div>

                          <div className="col-4">
                            <button
                              className="statusButtons w-100"
                              onClick={() => handlePlayers("Joined")}
                              style={{ backgroundColor: "#390" }}
                            >
                              Joined
                            </button>
                          </div>

                          <div className="col-4">
                            <button
                              className="statusButtons w-100"
                              onClick={() => handlePlayers("Not Joined")}
                              style={{ backgroundColor: "#CD0D0D" }}
                            >
                              Not Joined
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="playersStatusInfo">
                      <div className="col-12">
                        {filterPlayers.length != 0 ? (
                          filterPlayers.map((data, index) => {
                            return (
                              <div className="row mt-5" key={data.id}>
                                <div className="col-3 display-center">
                                  <img
                                    src={
                                      data.playerProfileImage
                                        ? data.playerProfileImage
                                        : "./images/Ellipse 14.svg"
                                    }
                                    style={{ height: "60px" }}
                                    alt=""
                                  />
                                </div>
                                <div className="col-9">
                                  <div className="row">
                                    <div className="col-12">
                                      <span
                                        style={{
                                          color: "#3C3C40",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {data?.fullName}:
                                      </span>
                                      <span
                                        className="ps-2"
                                        style={{
                                          color:
                                            data?.playerAvailability == 1
                                              ? "#339900"
                                              : data?.playerAvailability == 2
                                              ? "#cd0d0d"
                                              : "#3C3C40",
                                          fontSize: "13px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {data?.playerAvailability == 0
                                          ? "Status pending"
                                          : data?.playerAvailability == 1
                                          ? "Joined the match"
                                          : "Not Joined the match"}
                                      </span>
                                    </div>
                                  </div>
                                  {/* <Form.Check value={data?.id} className="custom-checkbox PlayerStatuscheckbox" aria-label="option 1" data-joinUserId={data?.joinUserID} defaultChecked={data?.playerAvailability === 1 ? true : false}/> */}
                                  {/* <input className="custom-checkbox PlayerStatuscheckbox" type="checkbox" value={data?.id}  /> */}
                                  <div
                                    className="row py-2 display-center"
                                    onChange={(e) => {
                                      handlePlayerStatus(e.target);
                                    }}
                                  >
                                    <div className="col-4 pe-0">
                                      <div className="row">
                                        <div className="col-12 display-center">
                                          <input
                                            className={`form-check-input playerStatus${data.id}`}
                                            value="1"
                                            type="radio"
                                            name={`playerStatus${data.id}`}
                                            id={`${data.id}-1`}
                                            defaultChecked={
                                              data?.playerAvailability === 1
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handlePlayerStatus(e.target);
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 display-center mt-2">
                                          <span
                                            className="form-check-label"
                                            style={{
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                            }}
                                            htmlFor={`${data.id}-1`}
                                          >
                                            Joined
                                          </span>{" "}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4 pe-0">
                                      <div className="row">
                                        <div className="col-12 display-center">
                                          <input
                                            className={`form-check-input playerStatus${data.id}`}
                                            value="2"
                                            type="radio"
                                            name={`playerStatus${data.id}`}
                                            id={`${data.id}-2`}
                                            defaultChecked={
                                              data?.playerAvailability === 2
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handlePlayerStatus(e.target);
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 display-center  mt-2">
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                            }}
                                            className="form-check-label"
                                            htmlFor={`${data.id}-2`}
                                          >
                                            Not Joined
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4 pe-0">
                                      <div className="row">
                                        <div className="col-12 display-center">
                                          <input
                                            className={`form-check-input playerStatus${data.id}`}
                                            value="0"
                                            type="radio"
                                            name={`playerStatus${data.id}`}
                                            id={`${data.id}-2`}
                                            defaultChecked={
                                              data?.playerAvailability === 0
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handlePlayerStatus(e.target);
                                            }}
                                          />
                                        </div>
                                        <div className="col-12 display-center mt-2">
                                          <span
                                            style={{
                                              fontSize: "10px",
                                              fontWeight: "bold",
                                            }}
                                            className="form-check-label"
                                            htmlFor={`${data.id}-0`}
                                          >
                                            Pending
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="display-center mt-3 fw-bold">
                            No Players found
                          </div>
                        )}

                        {filterPlayers.length > 0 && (
                          <div className="mt-2">
                            <p className="saveText" onClick={SavePlayerStatus}>
                              Save
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <Card className="profileformControl mt-3">
                    <div className="createdInfo text-center">
                      <b>
                        Created By :{" "}
                        <span style={{ color: "#A51E22" }}>
                          {str.matchesPackage.userName}
                        </span>
                      </b>
                      <br />
                      <b>
                        {" "}
                        Created At :{" "}
                        {DBConvertDatetime(str.matchesPackage.createdAt)}
                      </b>
                    </div>

                    <div className="text-center mt-5 mb-5">
                      <div className="row">
                        <div className="col-12">
                          <button
                            className="matchStatusButtons w-100"
                            style={{
                              backgroundColor: "#390",
                              opacity: str.matchesPackage.completematch
                                ? "1"
                                : "0.5",
                            }}
                            onClick={(e) =>
                              str.matchesPackage.completematch
                                ? CompleteMatchFun(
                                    str.matchesPackage.completematch
                                  )
                                : setCompleteCancelMatchAlert({
                                    ...CompleteCancelMatchAlert,
                                    completeAlert: true,
                                  })
                            }
                          >
                            Complete Match
                          </button>
                          {CompleteCancelMatchAlert?.completeAlert && (
                            <SweetAlert
                              warning
                              confirmBtnBsStyle="warning"
                              onConfirm={() => {
                                setCompleteCancelMatchAlert({
                                  ...CompleteCancelMatchAlert,
                                  completeAlert: false,
                                });
                              }}
                            >
                              Match can be completed after 30 mins of start time
                            </SweetAlert>
                          )}
                          {CompleteCancelMatchAlert?.joinALert && (
                            <SweetAlert
                              warning
                              confirmBtnBsStyle="warning"
                              onConfirm={() => {
                                setCompleteCancelMatchAlert({
                                  ...CompleteCancelMatchAlert,
                                  joinALert: false,
                                });
                              }}
                            >
                              Player status: pending. Please select players who
                              have joined or not joined from the options.
                            </SweetAlert>
                          )}
                          {CompleteCancelMatchAlert?.complete && (
                            <SweetAlert
                              success
                              confirmBtnBsStyle="success"
                              onConfirm={() => {
                                setCompleteCancelMatchAlert({
                                  ...CompleteCancelMatchAlert,
                                  complete: false,
                                });
                                navigate("/created-matches");
                              }}
                            >
                              Your match has been successfully completed!
                            </SweetAlert>
                          )}
                        </div>
                        <div className="col-12 mt-3">
                          <button
                            className="matchStatusButtons w-100"
                            style={{
                              backgroundColor: "#CD0D0D",
                              opacity: str.matchesPackage.cancelmatch
                                ? "1"
                                : "0.5",
                            }}
                            onClick={(e) =>
                              // CancelMatchFun(str.matchesPackage.cancelmatch)
                              setCompleteCancelMatchAlert({
                                ...CompleteCancelMatchAlert,
                                cancelAlert: true,
                              })
                            }
                          >
                            Cancel Match
                          </button>
                          {CompleteCancelMatchAlert?.cancelAlert && (
                            <SweetAlert
                              warning
                              showCancel={str.matchesPackage.cancelmatch}
                              confirmBtnText={
                                str.matchesPackage.cancelmatch
                                  ? "Yes, cancel it!"
                                  : "Ok"
                              }
                              confirmBtnBsStyle="danger"
                              title="Alert!"
                              onConfirm={() => {
                                setCompleteCancelMatchAlert({
                                  ...CompleteCancelMatchAlert,
                                  cancelAlert: false,
                                });
                                if (str.matchesPackage.cancelmatch) {
                                  CancelMatchFun(
                                    str.matchesPackage.cancelmatch
                                  );
                                }
                              }}
                              onCancel={() => {
                                setCompleteCancelMatchAlert({
                                  ...CompleteCancelMatchAlert,
                                  cancelAlert: false,
                                });
                              }}
                            >
                              {str.matchesPackage.cancelmatch
                                ? "You will not be able to recover this Match!"
                                : "Cannot cancel match within 2 hours of start time."}
                            </SweetAlert>
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Card.Body>

                {/* <Card.Footer
                  style={{ fontSize: "13px", float: "right !important" }}
                  key={index}
                >
                  <b>Created By :- </b> {str.matchesPackage.userName} |
                  <b> Created At :- </b>
                  {DBConvertDatetime(str.matchesPackage.createdAt)}
                </Card.Footer> */}
              </Card>
            );
            return returData;
          })
        )}
      </div>
      <Popup
        show={MatchEditPopUp}
        onHide={() => SetMatchEditPopUp(false)}
        className="outClass"
        teamname={"Match Details"}
      >
        <MatchDetailsEditPage
          onHide={() => SetMatchEditPopUp(false)}
          allMatchesData={EditAllMatchesData}
        />
      </Popup>
    </>
  );
}
