import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CheckValidation } from "../Utils/common";
import {
  CustomGetAPI,
  CustomPostAPI,
  CustomPutAPI,
} from "../Utils/APIConnector";
import { useLocation } from "react-router-dom";
import Loader from "../Utils/Loader";
import Swal from "sweetalert2";
export default function AddCoupon() {
  const UserType = window.localStorage.getItem("UserType");
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [couponData, setCouponData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    percentDiscount: "",
    amountDiscount: "",
  });
  useEffect(() => {
    if (UserType != "Admin") {
      window.location.href = "/home";
    }
  }, []);

  useEffect(() => {
    if (location.state?.couponId) {
      setIsLoading(true);
      CustomGetAPI(`/venue/GetOffers/${location.state.couponId}`).then(
        (result) => {
          if (result) {
            result = JSON.parse(result);
            setCouponData((prevState) => ({
              ...prevState,
              name: result?.data?.code,
              startDate: new Date(result?.data?.createdDate),
              endDate: new Date(result?.data?.expiry),
              percentDiscount: result?.data?.offpercentage,
              amountDiscount: result?.data?.amountLimit,
            }));
            setIsLoading(false);
          }
        }
      );
    }
  }, [location.state]);

  const handleSubmit = () => {
    var isFormValid = CheckValidation({ formID: "couponPopup" });

    if (!couponData?.startDate) {
      isFormValid = false;
      const startError = document.getElementById("startDateError");
      startError.classList.remove("d-none");
    } else {
      const startError = document.getElementById("startDateError");
      startError.classList.add("d-none");
    }

    if (!couponData?.endDate) {
      isFormValid = false;
      const endError = document.getElementById("endDateError");
      endError.classList.remove("d-none");
    } else {
      const endError = document.getElementById("endDateError");
      endError.classList.add("d-none");
    }

    if (isFormValid && !location.state?.couponId) {
      setIsLoading(true);
      const raw = JSON.stringify({
        code: couponData?.name,
        CreatedDate: couponData?.startDate,
        Expiry: couponData?.endDate,
        Offpercentage: couponData?.percentDiscount,
        AmountLimit: couponData?.amountDiscount,
      });
      CustomPostAPI("/venue/Createoffer", raw).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status === "success") {
            setIsLoading(false);
            Swal.fire({
              icon: "success",
              title: "Congratulations!",
              text: "Coupon Created Successfully",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/coupon-home";
              }
            });
          }
        }
      });
    } else {
      const raw = JSON.stringify({
        Id: location.state?.couponId,
        code: couponData?.name,
        CreatedDate: couponData?.startDate,
        Expiry: couponData?.endDate,
        Offpercentage: couponData?.percentDiscount,
        AmountLimit: couponData?.amountDiscount,
      });
      CustomPutAPI(`/venue/EditOffer`, raw).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result?.status === "success") {
            setIsLoading(false);
            Swal.fire({
              icon: "success",
              title: "Congratulations!",
              text: "Coupon Updated Successfully",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/coupon-home";
              }
            });
          }
        }
      });
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="couponPopup">
          <div className="col-1 ps-0">
            <img
              src="/images/backArrow.svg"
              onClick={() => {
                window.location.href = "/coupon-home";
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="text-center">
            <h5 className="colorClass text-center">
              {location.state?.couponId ? "Edit Coupon" : "New Coupon"}
            </h5>
          </div>
          <div className="mt-5 parent">
            <b>Coupon Code :</b>
            <input
              className="form-control mt-2"
              type="text"
              required
              value={couponData?.name}
              name="name"
              id="coupon"
              placeholder="Coupon Code*"
              onChange={(e) =>
                setCouponData((prevData) => ({
                  ...prevData,
                  [e.target.name]: e.target.value,
                }))
              }
            />
            <span className="form-text invalid-feedback">
              * This field is Required!
            </span>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <b>Start Date :</b>

              <DatePicker
                className="form-control mt-2"
                placeholderText="Select Date*"
                name="startDate"
                selected={couponData?.startDate}
                onChange={(date) =>
                  setCouponData((prevData) => ({
                    ...prevData,
                    startDate: date,
                  }))
                }
              />
              <span
                id="startDateError"
                className="form-text text-danger d-none"
              >
                * This field is Required!
              </span>
            </div>
            <div className="col-6">
              <b>Expiry Date :</b>
              <DatePicker
                className="form-control mt-2"
                placeholderText="Select Date*"
                name="endDate"
                selected={couponData?.endDate}
                onChange={(date) =>
                  setCouponData((prevData) => ({ ...prevData, endDate: date }))
                }
              />
              <span id="endDateError" className="form-text text-danger d-none">
                * This field is Required!
              </span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 parent">
              <b>Discount Percentage :</b>
              <input
                type="number"
                name="percentDiscount"
                id="percentageDiscount"
                required
                className="form-control mt-2"
                value={couponData?.percentDiscount}
                placeholder="Max Percentage Discount*"
                onChange={(e) =>
                  setCouponData((prevData) => ({
                    ...prevData,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
            </div>
            <div className="col-6 parent">
              <b>Max Amount :</b>
              <input
                className="form-control mt-2"
                type="number"
                name="amountDiscount"
                id="maxDiscount"
                required
                value={couponData?.amountDiscount}
                placeholder="Max Discount Amount*"
                onChange={(e) =>
                  setCouponData((prevData) => ({
                    ...prevData,
                    [e.target.name]: e.target.value,
                  }))
                }
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
            </div>
          </div>
          <div className="text-center mt-5">
            <button className="btn btn-danger w-50" onClick={handleSubmit}>
              <b>Submit</b>
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
