import { useState, useEffect } from "react";
import { getNextSaturdayDate } from "./constants";
import Loader from "./Utils/Loader";

export function InviteAndEarn() {
    const openReferral= () =>{
        window.open("/registration?referby=kevin1210");
    }
    return (
        <>
            <div className=" px-0 ">
                <div className="row p-4">
                    <div className="d-flex availableMatchDiv " onClick={openReferral}>
                        <div className="col-9 centerAlign">
                            <span className="availableMatchTxtDiv">Refer & Earn</span>
                        </div>
                        <div className="col-3">
                            <img
                                src="/images/mymatchesicon.svg"
                                className=""
                                style={{ height: "100%" }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
