import imageCompression from "browser-image-compression";

export const isValidValue = (value) => {
  if (value === undefined || value === null || value === 0 || value === "") {
    return false;
  }
  return true;
};
export const findParent = (element) => {
  let parentElement = element.parentNode;
  for (let i = 0; i < 12; i++) {
    if (parentElement.classList.contains("parent")) {
      return parentElement;
    } else {
      parentElement = parentElement.parentNode;
    }
  }
  return null;
};
export const CheckValidation = ({ formID }) => {
  let isValid = true;
  const formElement = document.getElementById(formID);
  const inputs = formElement.querySelectorAll("select, textarea, input");

  inputs.forEach((input) => {
    if (
      input.name === "duration1" ||
      input.name === "duration2" ||
      input.name === "duration3"
    ) {
      return;
    }
    if (!input.required) {
      return;
    }
    const inputParent = findParent(input);
    if (inputParent && !inputParent.classList.contains("d-none")) {
      if (input.tagName == "SELECT" && input.value === "0") {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "block";
        isValid = false;
      } else if (!isValidValue(input.value)) {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "block";
        isValid = false;
      } else {
        const invalidFeedback =
          input.parentNode.querySelector(".invalid-feedback");
        invalidFeedback.style.display = "";
      }
    }
  });

  return isValid;
};

export const venueSportsData = (name) => {
  var image = "";
  switch (name) {
    case "Cricket":
      image = "sport-cricket.svg";
      break;
    case "Box-Cricket":
      image = "sport-box-cricket.svg";
      break;
    case "Football":
      image = "sport-football.svg";
      break;
    case "Pickleball":
      image = "sport-pickleball.svg";
      break;
    case "Go-Karting":
      image = "sport-gokarting.svg";
      break;
    case "Auditorium":
      image = "theater.svg";
      break;
    case "Pool":
      image = "PoolIcon.svg";
      break;
    case "Snooker":
      image = "PoolIcon.svg";
      break;
    case "Badminton":
      image = "BadmintonIcon.svg";
      break;
    default:
      break;
  }
  return image;
};
export const venueAmenitiesData = (name) => {
  var image = "";
  switch (name) {
    case "Lighting":
      image = "lighting.svg";
      break;
    case "Seating":
      image = "seating.svg";
      break;
    case "Locker Rooms":
      image = "locker-room.svg";
      break;
    case "Restrooms":
      image = "restrooms.svg";
      break;
    case "Wi-Fi":
      image = "wifi.svg";
      break;
    case "First Aid":
      image = "firstaid.svg";
      break;
    case "Security":
      image = "security.svg";
      break;
    case "Equipments":
      image = "equipments.svg";
      break;
    case "Audience Sitting":
      image = "Audience-sitting.svg";
      break;
    case "Drinking Water":
      image = "drinking-water.svg";
      break;
    case "Shower":
      image = "shower.svg";
      break;
    case "Change Room":
      image = "change-room.svg";
      break;
    case "Cafe":
      image = "cafe.svg";
      break;
    case "Parking":
      image = "parking.svg";
      break;
    default:
      break;
  }
  return image;
};
export function convertTo12HourFormat(time24) {
  if (time24 != null) {
    const [hours, minutes, seconds] = time24.split(":");
    let hour = parseInt(hours, 10);
    let suffix = hour >= 12 ? "pm" : "am";

    if (hour > 12) {
      hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }

    return `${hour?.toString()?.padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${suffix}`;
  }
  return "";
}

// export function convertTo12HourFormat(time24) {
//   if (time24 != null) {
//     const [hours, minutes, seconds] = time24.split(":");
//     let hour = parseInt(hours, 10);
//     let suffix = hour >= 12 ? "PM" : "AM";

//     if (hour > 12) {
//       hour -= 12;
//     } else if (hour === 0) {
//       hour = 12;
//     }

//     return `${hour.toString().padStart(2, "0")}:${minutes
//       .toString()
//       .padStart(2, "0")} ${suffix}`;
//   }
//   return "";
// }

export const modifyTime = (hours, minutes, increment) => {
  let totalMinutes = hours * 60 + minutes + increment;
  let newHours = 0;
  let newMinutes = 0;
  if (totalMinutes < 30) {
    totalMinutes = 30;
  } else if (totalMinutes > 5 * 60) {
    totalMinutes = 5 * 60;
  } else {
    newHours = Math.floor(totalMinutes / 60);
    newMinutes = totalMinutes % 60;

    if (newHours < 5 || (newHours === 5 && newMinutes <= 0)) {
      return { newHours, newMinutes };
    }
  }
  return { newHours, newMinutes };
};

export function isWeekend(inputDate) {
  var date = new Date(inputDate);
  var dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6;
}

export function getPriceForTime(time, schedule) {
  const givenTime = new Date(`2000-01-01 ${time}`);

  for (const slot of schedule) {
    const startTime = new Date(`2000-01-01 ${slot.startTime}`);
    const endTime = new Date(`2000-01-01 ${slot.endTime}`);

    if (givenTime >= startTime && givenTime <= endTime) {
      return slot.price;
    }
  }

  return null;
}

export function calculatePrice(min, addMin, schedule, venueSlots, addSubtract) {
  var obj = {
    price: 0,
  };
  var time = new Date("1970-01-01T" + min + "Z");
  // if (addSubtract == "add") {
  time.setMinutes(time.getMinutes() + addMin);
  // } else {
  //   time.setMinutes(time.getMinutes() - addMin);
  // }
  var resultTimeString = time.toLocaleTimeString("en-US", {
    hour12: false,
    timeZone: "UTC",
  });
  if (addSubtract == "add") {
    for (const slot of venueSlots) {
      if (slot.startTime == resultTimeString && slot?.booked) {
        return true;
      }
    }
  }
  return getPriceForTime(resultTimeString, schedule);
}

export function timeAddChange(hours, minutes, MinSlotTime) {
  let isAddDisabled = false;
  switch (MinSlotTime) {
    case 30:
      isAddDisabled = hours === 5 && minutes === 0;
      break;
    case 60:
      isAddDisabled = hours === 5 && minutes === 0;
      break;
    case 90:
      isAddDisabled = hours === 4 && minutes === 30;
      break;
    default:
      break;
  }
  return isAddDisabled;
}
export function timeSubtractChange(hours, minutes, MinSlotTime) {
  let isSubDisabled = false;
  switch (MinSlotTime) {
    case 30:
      isSubDisabled = hours === 0 && minutes === 30;
      break;
    case 60:
      isSubDisabled = hours === 1 && minutes === 0;
      break;
    case 90:
      isSubDisabled = hours === 1 && minutes === 30;
      break;
    default:
      break;
  }
  return isSubDisabled;
}

// export function validateImageSize (imageUrl, minWidth, maxWidth, minHeight, maxHeight){
//   const img = new Image();
//   img.src = imageUrl;

//   if (
//     img.width >= minWidth &&
//     img.width <= maxWidth &&
//     img.height >= minHeight &&
//     img.height <= maxHeight
//   ) {
//     return true;
//   } else {
//     return false;
//   }
// };

export function VenueDate(dateString) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const originalDate = new Date(dateString);
  const dayOfWeekIndex = originalDate.getDay();
  const formattedDate = `${originalDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(originalDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${originalDate.getFullYear()}`;
  const dayOfWeek = daysOfWeek[dayOfWeekIndex];
  return { formattedDate, dayOfWeek };
}

export function checkTime(inputTime) {
  var currentTime = new Date();
  var currentHours = currentTime.getHours();
  var currentMinutes = currentTime.getMinutes();

  currentHours = (currentHours + 1) % 24;

  var currentTimeString =
    currentHours.toString().padStart(2, "0") +
    ":" +
    currentMinutes.toString().padStart(2, "0");

  return compareTimes(inputTime, currentTimeString);
}
function compareTimes(time1, time2) {
  var [hours1, minutes1] = time1.split(":").map(Number);
  var [hours2, minutes2] = time2.split(":").map(Number);

  if (hours1 > hours2 || (hours1 === hours2 && minutes1 > minutes2)) {
    return true;
  } else {
    return false;
  }
}

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (email == "" || email == null) {
    return true;
  }
  if (!emailRegex.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
export const formatDateToInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
export const capitalizeWord = (string, split, join) => {
  return string
    .split(split)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(join);
};
export const compressImage = async (file) => {
  let compressedFile = file;
  const maxSizeMB = 5;
  const options = {
    maxSizeMB,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  if (file.size / 1024 / 1024 > maxSizeMB) {
    compressedFile = await imageCompression(file, options);
  }
  return compressedFile;
};
export const minToHour = (min) => {
  const minSlotTime = parseInt(min, 10) || 0;
  const hours = minSlotTime / 60;
  return parseFloat(hours);
};
export const convertDecimalToTime = (decimalHours) => {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);

  let timeString = "";

  if (hours > 0) {
    timeString += `${hours} hour${hours > 1 ? "s" : ""}`;
  }

  if (minutes > 0) {
    if (timeString) timeString += " ";
    timeString += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  return timeString || "0 minutes";
};
export function areAllWeekdaysCovered(data) {
  const allWeekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const weekdaysInData = data?.weekDay?.flatMap((weekdayObj) =>
    weekdayObj?.weekDays?.split(",")?.map((day) => day?.trim())
  );

  for (let weekday of allWeekdays) {
    if (!weekdaysInData?.includes(weekday)) {
      return true;
    }
  }

  return false;
}

export const formatDateTime = (date, format) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedDate = date.toLocaleString("en-US", options);

  if (format === "YYYY-MM-DD HH:mm:ss") {
    return (
      formattedDate
        .split("T")[0]
        .replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$1-$2") +
      " " +
      formattedDate.split(",")[1].trim()
    );
  } else if (format === "MM/DD/YYYY hh:mm A") {
    const [datePart, timePart] = formattedDate.split(", ");
    const [hour, minute] = timePart.split(":");
    const hours = hour % 12 || 12;
    const ampm = hour >= 12 ? "PM" : "AM";
    return `${datePart} ${hours}:${minute} ${ampm}`;
  } else if (format === "DD/MM/YY hh:mm A") {
    const [datePart, timePart] = formattedDate.split(", ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute] = timePart.split(":");
    const hours = hour % 12 || 12;
    const ampm = hour >= 12 ? "PM" : "AM";
    return `${day}/${month}/${year.slice(-2)},${hours}:${minute} ${ampm}`;
  }

  // Add more formats as needed
  return formattedDate;
};
