import React, { Fragment, useState } from "react";
import { useScroll } from "react-spring";
import { useNavigate } from "react-router-dom";

export default function FAQ() {
  const data = [
    {
      id: 0,
      idh: "heading0",
      idbs: "collapse0",
      title: "How can I book sports venues using the cricbuddy app?",
      text: "Booking sports venues through our app is easy! Simply search for your favorite sports venues nearby, whether it's for Box Cricket, Football, Snooker, or any other sport you enjoy, and make your reservation.",
      collapse: "",
    },
    {
      id: 1,
      idh: "heading1",
      idbs: "collapse1",
      title: "What's the process for booking a venue?",
      text: "To Book a venue, navigate to the 'Book Sports Venue' Tab > Search for the venue from the list of venues > Select the venue you want to book > Add booking details > Select surface type > Select sport, Date > Time Slots > Confirm Booking > verify number > Book Now  Proceed to make payment.",
      collapse: "collapsed",
    },
    {
      id: 2,
      idh: "heading2",
      idbs: "collapse2",
      title: "How can I join cricket teams and play with them?",
      text: " If you're passionate about cricket, you can easily join teams that are looking for players. Showcase your skills and get ready to play alongside them.",
      collapse: "collapsed",
    },
  ];
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(true);
  const handleClick = (id, e) => {
    var val = e.currentTarget.classList;
    if (val[3] == "collapsed") {
      setActive(0);
      setActive2(false);
    } else {
      setActive(id);
      setActive2(true);
    }
  };
  return (
    <Fragment>
      <div className="pt-md-5">
        <div className="row pt-5 align-items-center">
          <div className="col-md-5 col-12 text-center">
            <h2 className="newpage-faq-h2">Frequently Asked Questions</h2>
            <img
              src="/images/faqimg.svg"
              alt="FAQ"
              className="d-md-block d-none mt-3 mx-auto p-3"
              style={{ height: "272px", width: "263px" }}
            />
          </div>

          <div className="col-md-7">
            <div className="row d-flex justify-content-center px-md-5 px-5">
              <div
                className="accordion accordion-flush mb-3 px-md-2 mt-0 ps-0 pe-0"
                id="accordionFlushExample"
              >
                {data.map((val, idx) => {
                  var isactive = idx == active;
                  return (
                    <div className="accordion-item mb-3 position-relative newpage-faq-accordion-item">
                      <h3 className="accordion-header" id={val.idh}>
                        <button
                          className={`accordion-button newpage-faq-accordion-button pb-3 ${val.collapse}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${val.idbs}`}
                          aria-expanded="true"
                          aria-controls={val.idbs}
                          onClick={(e) => handleClick(idx, e)}
                        >
                          <p
                            className="mb-0 pe-3 fw-600 newpage-faq-p"
                            style={
                              isactive && active2
                                ? { color: "#A51E22" }
                                : { color: "#3E4349" }
                            }
                          >
                            {val.title}
                          </p>
                        </button>
                        {isactive && active2 ? (
                          <img
                            src="/images/accordian-landing-page.png"
                            className="newpage-info-accordian-img"
                          />
                        ) : null}
                      </h3>
                      <div
                        id={val.idbs}
                        className={`accordion-collapse collapse ${
                          val.collapse != "collapsed" ? "show" : ""
                        }`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">{val.text}</div>
                      </div>
                    </div>
                  );
                })}
                <a
                  className="text-decoration-underline"
                  style={{ color: "#a51e22" }}
                  onClick={() => navigate("/faq")}
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
