import { useEffect, useState } from "react";
import $ from "jquery";

export const MatchPlayerInfo = ({
  nextStep,
  handleChange,
  values,
  handleTotalPlayer,
  handleMatchTypeChange,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  const Continue = (e) => {
    var isValidated = true;
    if (values.MatchName == "" || parseInt(values.MatchName) == 0) {
      isValidated = checkValidation("MatchName", values.MatchName);
    }
    if (values.currentPlayer == "" || parseInt(values.currentPlayer) == 0) {
      isValidated = checkValidation("AvailPlayer", values.currentPlayer);
    }
    if (values.needPlayer == "" || parseInt(values.needPlayer) == 0) {
      isValidated = checkValidation("NeededPlayer", values.needPlayer);
    }
    // if (values.IsChecked) {
    //     if (values.TotalBowler == '' || parseInt(values.TotalBowler) == 0) {
    //         isValidated = checkValidation("ReqBolwers", values.TotalBowler)
    //     }
    //     if (values.TotalBatsmen == '' || parseInt(values.TotalBatsmen) == 0) {
    //         isValidated = checkValidation("ReqBatsmens", values.TotalBatsmen)
    //     }
    //     if (values.AllRounders == '' || parseInt(values.AllRounders) == 0) {
    //         isValidated = checkValidation("ReqAllrounders", values.AllRounders)
    //     }
    //     if (values.WicketKeeprs == '' || parseInt(values.WicketKeeprs) == 0) {
    //         isValidated = checkValidation("ReqWicketKeepers", values.WicketKeeprs)
    //     }
    // }

    // const totalPlayers = parseInt(values.currentPlayer, 10) + parseInt(values.needPlayer, 10);
    // if (totalPlayers > 22) {
    //   this.setState({ errorMessage: "Total players cannot exceed 22" });
    //    <span className="validationText">{this.state.errorMessage}</span>
    // } else {
    //   this.setState({ errorMessage: "" });
    // }

    const totalPlayers =
      parseInt(values.currentPlayer) + parseInt(values.needPlayer);
    if (totalPlayers > 22) {
      isValidated = false;
      var players = $(".playersValidation");
      players.removeClass("d-none");

      // alert("Total players cannot exceed 22.");
      // <span className="validationText">Total players cannot exceed 22</span>
    }
    if (values.IsChecked) {
      const Total = sumOfNeededPlayers();
      if (Total > 22) {
        isValidated = false;
      }
    }
    if (isValidated) {
      e.preventDefault();
      nextStep();
    }
  };

  const checkValidation = (divID, Value) => {
    if (Value == "" || parseInt(Value) == 0) {
      $("#" + divID)
        .find(".requiredValidation")
        .text("Required");
      return false;
    } else {
      $("#" + divID)
        .find(".requiredValidation")
        .text("");
    }
  };

  // check total skills sum or needPlayer count
  const sumOfNeededPlayers = () => {
    const re = /^[0-9\b]+$/;
    var bolwer = values.TotalBowler === "" ? 0 : parseInt(values.TotalBowler);
    var batsmen =
      values.TotalBatsmen === "" ? 0 : parseInt(values.TotalBatsmen);
    var allrounder =
      values.AllRounders === "" ? 0 : parseInt(values.AllRounders);
    var wicketkeeper =
      values.WicketKeeprs === "" ? 0 : parseInt(values.WicketKeeprs);
    var vTotal = bolwer + batsmen + allrounder + wicketkeeper;
    handleTotalPlayer(vTotal);
    return vTotal;
  };
  return (
    <>
      <div className="px-2">
        <div className="row mt-2">
          <div className="p-2 text-center">
            <label style={{ color: "#A51E22" }}>
              <h4>
                <b>Players Details</b>
              </h4>
            </label>
            <hr />
          </div>
          <div className="col-12">
            <form>
              <div id="MatchName" className="mb-3 ">
                <label className="form-label textLabel">Match Name:</label>
                <div className="col-lg-6 col-md-6">
                  <input
                    type="text"
                    className="form-control profileformControl "
                    value={values.MatchName}
                    name="MatchName"
                    onChange={handleChange("MatchName")}
                    placeholder="Type your match name"
                    autoComplete="off"
                  />
                  <span className="requiredValidation validationText">{}</span>
                </div>
              </div>
              <div id="MatchType" className="mb-3 ">
                <label className="form-label textLabel">
                  Where is it played?
                </label>
                <div className="row ">
                  <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center">
                    <input
                      type="radio"
                      name="matchtype"
                      value="Box"
                      className="form-check-input me-1 "
                      checked={values.MatchType === "Box"}
                      onChange={(e) => handleMatchTypeChange(e)}
                    />
                    <label>Box Cricket</label>
                    <span className="requiredValidation validationText">
                      {}
                    </span>
                  </div>
                  <div className="col-lg-4 col-md-4 col-6 d-flex align-items-center">
                    <input
                      type="radio"
                      name="matchtype"
                      value="Cricket"
                      className="form-check-input me-1 "
                      checked={values.MatchType === "Cricket"}
                      onChange={(e) => handleMatchTypeChange(e)}
                    />
                    <label>Cricket Ground</label>
                    <span className="requiredValidation validationText">
                      {}
                    </span>
                  </div>
                </div>
              </div>

              <div id="AvailPlayer" className="mb-3 text-left">
                <label className="form-label textLabel">
                  How many players do you already have?
                </label>
                <div className="col-3">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control profileformControl "
                    value={values.currentPlayer}
                    onChange={handleChange("currentPlayer")}
                    placeholder="0"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 2))
                    }
                  />
                  <span className="requiredValidation validationText">{}</span>
                </div>
              </div>
              <div id="NeededPlayer" className="mb-3 text-left">
                <label className="form-label textLabel">
                  How many more players do you need to play the match?
                </label>
                <div className="col-3">
                  <input
                    type="number"
                    inputMode="numeric"
                    className="form-control profileformControl"
                    value={values.needPlayer}
                    onChange={handleChange("needPlayer")}
                    placeholder="0"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 2))
                    }
                  />
                  <span className="requiredValidation validationText">{}</span>

                  {/* <span className="validationText">{this.state.errorMessage}</span> */}
                </div>
              </div>
              <small className="text-danger playersValidation d-none">
                Total players cannot exceed 22
              </small>
              <div className="mb-3 form-check">
                <label className="form-check-label textLabel">
                  Do you need players with specific skills?
                </label>
                <input
                  type="checkbox"
                  className="form-check-input mt-2"
                  id="skillsCheck"
                  name="IsChecked"
                  checked={values.IsChecked}
                  onChange={handleChange("IsChecked")}
                />
              </div>
            </form>
          </div>
        </div>
        {values.IsChecked ? (
          <div className="row mx-2" id="hdnSkillsDiv">
            <div className="col-12">
              <form>
                <div id="ReqBolwers" className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="row">
                      <div className="col-10 mt-2">
                        <label className="form-label textLabel">
                          How many Bowlers?
                        </label>
                      </div>
                      <div className="col-2 px-0">
                        <input
                          type="number"
                          inputMode="numeric"
                          className="form-control profileformControl"
                          value={values.TotalBowler}
                          onChange={handleChange("TotalBowler")}
                          onKeyUp={sumOfNeededPlayers}
                          placeholder="0"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 2))
                          }
                        />
                        <span className="requiredValidation validationText">
                          {}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ReqBatsmens" className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="row">
                      <div className="col-10 mt-2">
                        <label className="form-label textLabel">
                          How many Batsmen?
                        </label>
                      </div>
                      <div className="col-2 px-0">
                        <input
                          type="number"
                          inputMode="numeric"
                          className="form-control profileformControl"
                          value={values.TotalBatsmen}
                          onChange={handleChange("TotalBatsmen")}
                          onKeyUp={sumOfNeededPlayers}
                          placeholder="0"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 2))
                          }
                        />
                        <span className="requiredValidation validationText">
                          {}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ReqAllrounders" className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="row">
                      <div className="col-10 mt-2">
                        <label className="form-label textLabel">
                          How many All rounders?
                        </label>
                      </div>
                      <div className="col-2 px-0">
                        <input
                          type="number"
                          inputMode="numeric"
                          className="form-control profileformControl"
                          value={values.AllRounders}
                          onChange={handleChange("AllRounders")}
                          onKeyUp={sumOfNeededPlayers}
                          placeholder="0"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 2))
                          }
                        />
                        <span className="requiredValidation validationText">
                          {}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="ReqWicketKeepers" className="row mt-3">
                  <div className="col-12 px-0">
                    <div className="row">
                      <div className="col-10 mt-2">
                        <label className="form-label textLabel">
                          How many Wicket keepers?
                        </label>
                      </div>
                      <div className="col-2 px-0">
                        <input
                          type="number"
                          inputMode="numeric"
                          className="form-control profileformControl"
                          value={values.WicketKeeprs}
                          onChange={handleChange("WicketKeeprs")}
                          onKeyUp={sumOfNeededPlayers}
                          placeholder="0"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 2))
                          }
                        />
                        <span className="requiredValidation validationText">
                          {}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : null}
        <div className="row mt-3 mb-3">
          <hr />
          <div className="col-12">
            <div className="row mt-3 d-flex justify-content-center">
              <div className="col-3">
                <button onClick={Continue} className="btn btn-danger">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
