import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import { VenueDate, convertTo12HourFormat } from "../Utils/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Utils/Loader";
import VenueCalender from "../Calender/VenueCalender";
import Swal from "sweetalert2";

export default function VenuesBookedOwnerDashboard() {
  const location = useLocation();
  const [showDaypart, setShowDayPart] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const UserType = window.localStorage.getItem("UserType");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [filterData, setFilterData] = useState();
  // const dayPartRef = useRef(null);

  const navigate = useNavigate();
  const [showBookingDetails, setShowBookingDetails] = useState({
    bookingDetails: [],
    venueDetails: {},
  });

  const closeDaypart = (e) => {
    e.stopPropagation();
    setShowDayPart(false);
  };

  const alternateColor = (index) => {
    return index % 2 == 0 ? "#FBFAFA" : "white";
  };

  function resetBookings() {
    // setSelectedDate( new Date().toISOString().split("T")[0])
    setIsLoading(true);
    setSelectedTimeSlots([]);
    const raw = JSON.stringify({
      VenueId: location.state.id,
      Date: selectedDate,
      TimeId: [],
    });
    CustomPostAPI("/Venue/GetBookedvenueOwnerside", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setShowBookingDetails({
          bookingDetails: result.bookingDetails,
          venueDetails: result.venueDetails,
        });
        setIsLoading(false);
      }
    });
    setShowDayPart(false);
  }

  function filterBookings() {
    setIsLoading(true);
    // console.log(selectedTimeSlots)
    // const timingIds = selectedTimeSlots.map(timing => timing.value)
    const raw = JSON.stringify({
      VenueId: location.state.id,
      Date: selectedDate,
      TimeId: selectedTimeSlots,
    });
    CustomPostAPI("/Venue/GetBookedvenueOwnerside", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setShowBookingDetails({
          bookingDetails: result.bookingDetails,
          venueDetails: result.venueDetails,
        });
        setIsLoading(false);
      }
    });

    setShowDayPart(false);
  }

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zeros if month or day is a single digit
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedTimeSlots((prevSlots) => [...prevSlots, value]);
    } else {
      setSelectedTimeSlots((prevSlots) =>
        prevSlots.filter((slot) => slot !== value)
      );
    }
  };
  const currentDate = new Date().toISOString().split("T")[0];
  const handleChangeDate = (e) => {
    setIsLoading(true);
    const targetDate = e.target.value;
    setSelectedDate(targetDate);
    const raw = JSON.stringify({
      VenueId: location.state.id,
      Date: targetDate,
    });
    CustomPostAPI("/Venue/GetBookedvenueOwnerside", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // console.log(result)
        setShowBookingDetails({
          bookingDetails: result?.bookingDetails,
          venueDetails: result?.venueDetails,
        });
        setIsLoading(false);
      }
    });
  };

  const cancel = (id, Isrecurring) => {
    if (Isrecurring) {
      Swal.fire({
        title: "Cancel Future Event also",
        text: "This is a recurring booking. Do you want to cancel all future events?",
        showConfirmButton: true,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          CustomPostAPI(`/payment/OfflineCancelbooking/${id}/true`).then(
            (result) => {
              if (result) {
                setIsLoading(false);
                Swal.fire({
                  title: "Success",
                  text: "Booking cancelled successfully",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  window.location.reload();
                });
              } else {
                setIsLoading(false);
                alert("Something went worng");
              }
            }
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          CustomPostAPI(`/payment/OfflineCancelbooking/${id}/false`).then(
            (result) => {
              if (result) {
                setIsLoading(false);
                Swal.fire({
                  title: "Success",
                  text: "Booking cancelled successfully",
                  showConfirmButton: true,
                  confirmButtonText: "Ok",
                }).then((result) => {
                  window.location.reload();
                });
              } else {
                setIsLoading(false);
                alert("Something went worng");
              }
            }
          );
        }
      });
    } else {
      CustomPostAPI(`/payment/OfflineCancelbooking/${id}/false`).then(
        (result) => {
          if (result) {
            setIsLoading(false);
            Swal.fire({
              title: "Success",
              text: "Booking cancelled successfully",
              showConfirmButton: true,
              confirmButtonText: "Ok",
            }).then((result) => {
              window.location.reload();
            });
          } else {
            setIsLoading(false);
            alert("Something went worng");
          }
        }
      );
    }
    // var raw = JSON.stringify({
    //   id: id,
    // });
    setIsLoading(true);
  };

  useEffect(() => {
    const raw = JSON.stringify({
      VenueId: location.state.id,
      Date: selectedDate,
    });
    CustomPostAPI("/Venue/GetBookedvenueOwnerside", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        setShowBookingDetails({
          bookingDetails: result.bookingDetails,
          venueDetails: result.venueDetails,
        });
        setIsLoading(false);
      }
    });
  }, [location.state.id]);

  const [totalIncome, settotalIncome] = useState(0);
  useEffect(() => {
    settotalIncome(0);
    var totalAmount = 0;
    {
      showBookingDetails?.bookingDetails?.map((item) => {
        if (item.bookingstatus === 1) {
          totalAmount = totalAmount + item?.amount;
        }
      });
    }
    settotalIncome(totalAmount);
  }, [showBookingDetails?.bookingDetails]);

  return (
    <>
      {/* <div className="Container my-5" style={{ height: "75dvh" }}> */}
      <div className="Container mb-5">
        <div className="form-control">
          <div className="row ">
            <div className="col-12 text-center position-relative">
              <img
                className="venuePlaceArrow img-fluid position-absolute"
                src="/images/backArrow.svg"
                onClick={() => navigate("/home-venue-owner")}
              />
              <div
                className="venuePlaceName colorClass"
                style={{ fontWeight: "600" }}
              >
                {showBookingDetails?.venueDetails?.name
                  ? showBookingDetails?.venueDetails?.name
                  : "User"}
              </div>
              <span className="venueText" style={{ fontSize: "13px" }}>
                Operating Hours:{" "}
              </span>
              <span className="colorClass" style={{ fontSize: "13px" }}>
                {convertTo12HourFormat(
                  showBookingDetails?.venueDetails?.startTime
                ) === "12:00 am" &&
                convertTo12HourFormat(
                  showBookingDetails?.venueDetails?.endTime
                ) === "12:00 am"
                  ? "24 Hours"
                  : `${convertTo12HourFormat(
                      showBookingDetails?.venueDetails?.startTime
                    )} - ${convertTo12HourFormat(
                      showBookingDetails?.venueDetails?.endTime
                    )}`}
              </span>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ border: "1px solid #E0E0E0", borderRadius: "4px" }}
        >
          <div
            className="borders p-md-3 px-3 py-2"
            style={{
              background: "#F4F4F4",
            }}
          >
            <div className="row align-items-center">
              <div className="col-7 mt-md-2 p-2">
                <span className="venueText">
                  Booking count:{" "}
                  <span className="venueText">
                    {showBookingDetails?.bookingDetails?.length} bookings
                  </span>
                </span>
                <div className="row">
                  <span className="venueText">
                    {showBookingDetails?.bookingDetails?.length} Bookings
                    income:{" "}
                    <span className="bookingText colorClass fw-bold">
                      ₹{totalIncome}
                    </span>
                  </span>
                </div>
              </div>
              <div className="col-5 p-0">
                <button
                  id="DatePicker"
                  className="datepicker-native ownerbuttons w-100"
                  style={{ background: "#a51e22" }}
                >
                  <img src="/images/today.svg" className="me-2" alt="" />
                  {selectedDate === currentDate ? "Today" : selectedDate}
                  <input
                    type="date"
                    className="datepicker-native-input"
                    value={selectedDate}
                    onChange={(e) => handleChangeDate(e)}
                  />
                </button>
              </div>
            </div>
          </div>

          <hr className="m-0" />
          {isLoading ? (
            <Loader mt="0%" />
          ) : (
            <>
              <div className="p-3">
                {showBookingDetails?.bookingDetails?.length !== 0 ? (
                  <>
                    <div className="row">
                      {showBookingDetails?.bookingDetails?.map(
                        (booking, index) => (
                          <div
                            key={index}
                            className="col-lg-6 mb-3 bookingCardWrapper"
                          >
                            <div className="bookingCard">
                              <div className="row my-3">
                                <div
                                  className="col-6 pe-0"
                                  style={{ overflowWrap: "break-word" }}
                                >
                                  <b className="">{booking?.username}</b>
                                  <br />
                                  <b>
                                    {convertTo12HourFormat(
                                      booking?.startTimelist
                                    )}
                                  </b>
                                  <br />
                                  {booking?.surface?.split("(")[0]}:{" "}
                                  {booking?.sport} <br />
                                  <a href={`tel:${booking?.userPhone}`}>
                                    {booking?.userPhone}
                                  </a>
                                </div>
                                <div
                                  className="col-6"
                                  style={{ overflowWrap: "break-word" }}
                                >
                                  {booking?.amount > 0 && (
                                    <>
                                      Paid: ₹{" "}
                                      <span style={{ color: "#4caf50" }}>
                                        {booking?.amount}
                                      </span>{" "}
                                      <br />
                                    </>
                                  )}
                                  {booking?.toPayAmount > 0 && (
                                    <>
                                      Pending: ₹{" "}
                                      <span style={{ color: "#a51e22" }}>
                                        {booking?.toPayAmount}
                                      </span>{" "}
                                      <br />
                                    </>
                                  )}
                                  {booking?.toPayAmount + booking?.amount >
                                    0 && (
                                    <>
                                      Total Fee: ₹{" "}
                                      <span
                                        style={{
                                          color: "#a51e22",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {booking?.toPayAmount + booking?.amount}
                                      </span>{" "}
                                      <br />
                                    </>
                                  )}

                                  {booking?.durationgocart ? (
                                    <>{booking?.durationgocart} Min</>
                                  ) : (
                                    <>
                                      {booking?.slotDuration <= 1
                                        ? `${booking?.slotDuration} hr`
                                        : `${booking?.slotDuration} hrs`}
                                      <br />
                                    </>
                                  )}
                                  {booking?.noOfKart &&
                                  booking?.durationgocart ? (
                                    <>
                                      {" "}
                                      <span className="bookingans">
                                        <>{booking?.noOfKart} </>
                                      </span>
                                      <br />
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {UserType != "Staff" && (
                                    <span style={{ color: "#000000B2" }}>
                                      {booking?.userEmail}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {booking?.isOfflineBooking ? (
                                <div className="text-center">
                                  <button
                                    className="w-auto  btn btn-danger"
                                    onClick={(e) =>
                                      cancel(booking?.id, booking?.isrecurring)
                                    }
                                  >
                                    Cancel Booking
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <p>No bookings available.</p>
                )}
              </div>
            </>
          )}

          {/* <VenueCalender
            VenueId={location.state.id}
            selectedDate={selectedDate}
          /> */}
        </div>
      </div>
    </>
  );
}
