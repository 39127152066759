import React, { Fragment, useState } from "react";
import Popup from "../LiveScoreCardpopups/Popup";
import VenueSearch from "../VenueListing/venueSearch";

export default function LocationSearch({ isRedirect }) {
  const [show, setShow] = useState(false);
  const userLocation = localStorage.getItem("userLocation")?.split("-")[
    localStorage.getItem("userLocation")?.split("-")?.length - 1
  ];
  return (
    <Fragment>
      <div
        onClick={() => {
          setShow(true);
        }}
        className="cursor-pointer d-flex align-items-center"
      >
        {" "}
        <img
          src="/images/Location-Booking.svg"
          alt="Location"
          className="me-1"
        />
        {userLocation
          ? userLocation?.charAt(0).toUpperCase() +
            userLocation?.slice(1).toLowerCase()
          : "Choose Location"}
      </div>

      <Popup show={show} onHide={() => setShow(false)}>
        <VenueSearch setShow={setShow} isRedirect={isRedirect} />
      </Popup>
    </Fragment>
  );
}
