import * as React from "react";
import { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import $ from "jquery";
import { GetPlayerRole, isValid, radioChecked } from "../constants";
import { GetAPI, PostAPI } from "../Utils/APIConnector";

export default function LiveMatchPalyers_2({
  prevStep,
  nextStep,
  TeamNames,
  setValues,
  setDynamicValues,
  values,
  playerWithTeams,
  teamNames,
}) {
  const userType = window.localStorage.getItem("UserType");
  const userid = window.localStorage.getItem("userid");
  const [valuess, setValue] = useState({ e: "", v: "" });
  const [isMultipleTeam, SetIsMultipleTeam] = useState(false);
  const [teamType, SetTeamType] = useState();
  const [joinMatchPackages, setjoinMatchPackagesData] = useState([]);
  const [filterDatas, setfilterDatas] = useState({
    filterDatas1: [],
    filterDatas2: [],
  });
  const [teamOne, SetTeamOne] = useState([
    {
      PlayerID: "",
      TeamTypeID: "",
    },
  ]);

  const [showTeamData, setshowTeamData] = useState({
    oneTeam: values.twoTeams ? true : false,
    twoTeams: values.twoTeams ? false : true,
  });
  const radioBtn = (e, value) => {
    if (value == "oneTeam") {
      setshowTeamData({ ...showTeamData, oneTeam: false, twoTeams: true });
    }
    if (value == "twoTeams") {
      setshowTeamData({ ...showTeamData, oneTeam: true, twoTeams: false });
      setDynamicValues("twoTeams", true);
    }
  };
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  useEffect(async () => {
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    await GetAPI(
      "/Matches/DetailsOfCreatedMatch/" + parseInt(matchDetailsID)
    ).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.matchDetails;
        setjoinMatchPackagesData(matchList[0].joinMatchPackages);

        setfilterDatas({
          ...filterDatas,
          filterDatas1: matchList[0].joinMatchPackages,
          filterDatas2: matchList[0].joinMatchPackages,
        });
        setDynamicValues("overs", matchList[0].matchesPackage.overs);
      }
    });
  }, []);
  const [TeamAName, setTeamAName] = useState(teamNames[0].teamA);
  const [TeamBName, setTeamBName] = useState(teamNames[0].teamB);

  const list = [];
  var playerObj = new Object();
  playerObj.playerList = new Array();
  const Continue = () => {
    var teamAvalidation = false;
    var teamBvalidation = false;
    var teamAcount = 0;
    var teamBcount = 0;
    if (!$("#multipleTeams").hasClass("d-none")) {
      $("#firstTeam")
        .find(".firstTeamList")
        .each(function () {
          var roleTypeID = $(this).find("input:radio:checked").val();
          if (
            roleTypeID !== undefined &&
            roleTypeID !== "" &&
            roleTypeID !== null &&
            roleTypeID !== 0
          ) {
            teamAcount++;
            var value = new Object();
            value.PlayerID = parseInt(roleTypeID);
            value.TeamTypeID = 2;
            playerObj.playerList.push(value);
            teamAvalidation = true;
            SetTeamOne(playerObj.playerList);
          }
        });
      $("#secondTeam")
        .find(".secondTeamList")
        .each(function () {
          var roleTypeID = $(this).find("input:radio:checked").val();
          if (
            roleTypeID !== undefined &&
            roleTypeID !== "" &&
            roleTypeID !== null &&
            roleTypeID !== 0
          ) {
            teamBcount++;
            var value = new Object();
            value.PlayerID = parseInt(roleTypeID);
            value.TeamTypeID = 3;
            playerObj.playerList.push(value);
            teamBvalidation = true;
            SetTeamOne(playerObj.playerList);
          }
        });

      setValues("playerWithTeams", playerObj.playerList);
      setValues("playerWithTeams", playerObj.playerList);

      if (teamAvalidation && teamBvalidation) {
        if (teamAcount < 5 || teamBcount < 5) {
          $(".validation2").text(
            "Both team should have atleast 5 players to play."
          );
        } else {
          $(".validation2").text("");
          nextStep(1);
        }
      } else {
        $(".validation2").text("Please select players");
      }
    }

    TeamNames([{ teamA: TeamAName, teamB: TeamBName }]);
  };
  const playerSelection = (e) => {
    var temp = e.currentTarget;
    var firstTeamList = 1;
    var secondTeamList = 1;
    if ($(temp).parent().hasClass("firstTeamList")) {
      firstTeamList = $(".firstTeamList").find("input:radio:checked").length;
    }
    if ($(temp).parent().hasClass("secondTeamList")) {
      secondTeamList = $(".secondTeamList").find("input:radio:checked").length;
    }
    var devied = Math.round(parseInt(joinMatchPackages.length) / 2);
    var deviedminus = parseInt(joinMatchPackages.length) - devied;
    if (firstTeamList == devied) {
      alert("you cant select more then " + devied);
    }
    if (secondTeamList == deviedminus) {
      alert("you cant select more then " + deviedminus);
    }
  };
  const inputValueA = () => {
    $("#teamAValue").focus();
  };
  const inputValueB = () => {
    $("#teamBValue").focus();
  };
  const handleChange = (e, v, value) => {
    e = parseInt(e);
    v = parseInt(v);
    setValue({ ...valuess, e: e, v: v });
    if (value == "select1") {
      const filter = joinMatchPackages;
      var filterData = [];
      if (e === 0) {
        setfilterDatas({ ...filterDatas, filterDatas1: joinMatchPackages });
      } else {
        for (let i = 0; i < filter.length; i++) {
          if (e === filter[i].playerRoleID) {
            filterData.push(filter[i]);
          }
        }
        setfilterDatas({ ...filterDatas, filterDatas1: filterData });
      }
    }
    if (value == "select2") {
      const filter = joinMatchPackages;
      var filterData = [];
      if (v === 0) {
        setfilterDatas({ ...filterDatas, filterDatas2: joinMatchPackages });
      } else {
        for (let i = 0; i < filter.length; i++) {
          if (v === filter[i].playerRoleID) {
            filterData.push(filter[i]);
          }
        }
        setfilterDatas({ ...filterDatas, filterDatas2: filterData });
      }
    }
  };

  const startMatch = async () => {
    var isValidate = false;
    var strikeBatsmen = $(
      "#playerSelection .strikeBatsmen option:selected"
    ).val();
    var nonStrikeBatsmen = $(
      "#playerSelection .nonStrikeBatsmen  option:selected"
    ).val();
    var strikeBowler = $(
      "#playerSelection .strikeBowler option:selected"
    ).val();

    if (
      !isValid(strikeBatsmen) ||
      !isValid(nonStrikeBatsmen) ||
      !isValid(strikeBowler)
    ) {
      $("#playerSelection .validation")
        .removeClass("d-none")
        .text("All fields are required");
    } else {
      if (
        parseInt(strikeBatsmen) === parseInt(nonStrikeBatsmen) &&
        parseInt(nonStrikeBatsmen) === parseInt(strikeBowler)
      ) {
        $("#playerSelection .validation")
          .removeClass("d-none")
          .text("All players not should be same");
      } else if (parseInt(strikeBatsmen) === parseInt(nonStrikeBatsmen)) {
        $("#playerSelection .validation")
          .removeClass("d-none")
          .text("Please select both different batsmen");
      } else if (
        parseInt(strikeBatsmen) === parseInt(strikeBowler) ||
        parseInt(nonStrikeBatsmen) === parseInt(strikeBowler)
      ) {
        $("#playerSelection .validation")
          .removeClass("d-none")
          .text("Please select both different batsmen & bowler");
      } else {
        $("#playerSelection .validation").addClass("d-none");
        isValidate = true;
      }
    }
    if (isValidate) {
      // nextStep(1);
      window.localStorage.setItem("strikerBatsmen", strikeBatsmen);
      window.localStorage.setItem("nonStrikerBatsmen", nonStrikeBatsmen);
      window.localStorage.setItem("strikerBowler", strikeBowler);
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      var raw = JSON.stringify({
        SingleOrDouble: 1,
        CricketMatchId: parseInt(matchDetailsID),
      });
      await PostAPI("/Matches/SaveTeamSelection/", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            setDynamicValues("SingleOrMultipleTeam", "Single");
            nextStep(3);
          } else {
            alert(result.Message);
          }
        }
      });
    }
  };
  return (
    <>
      <div className="px-3">
        <div className="row ContainerPage_3 py-3 mt-4">
          <div className="col-12 page_3Font1">
            What type of match are you going to play?
          </div>
          <div className="col-12 my-3">
            <div className="row">
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_2Radio1"
                  onClick={(e) => radioBtn(e, "oneTeam")}
                  defaultChecked
                />
                <span className="page_3Font2 ms-1">One Team Match</span>
              </div>
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_2Radio1"
                  onClick={(e) => radioBtn(e, "twoTeams")}
                  defaultChecked={values.twoTeams == true ? "checked" : null}
                />
                <span className="page_3Font2 ms-1">Two Teams Match</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={` ${showTeamData.oneTeam == true ? "d-none" : ""}`}
        id="playerSelection"
      >
        <div className="p-3">
          <div className="row ContainerPage_3 py-3">
            <div className="col-12 page_3Font1">Striker Batsman</div>
            <div className="col-8 my-2 ContainerPage_4Select">
              <select className="strikeBatsmen">
                <option value="0">Striker Player Name select</option>
                {joinMatchPackages.map((data, i) => {
                  var returnData = (
                    <option value={data.id}>{data.fullName}</option>
                  );
                  return returnData;
                })}
              </select>
            </div>
          </div>
          <div className="row ContainerPage_3 mt-4 py-3">
            <div className="col-12 page_3Font1">Non Striker Batsman</div>
            <div className="col-9 my-2 ContainerPage_4Select">
              <select className="nonStrikeBatsmen">
                <option value="0">Non Striker Player Name select</option>
                {joinMatchPackages.map((data, i) => {
                  var returnData = (
                    <option value={data.id}>{data.fullName}</option>
                  );
                  return returnData;
                })}
              </select>
            </div>
          </div>
          <div className="row ContainerPage_3 mt-4 py-3">
            <div className="col-12 page_3Font1">Striker Bowler</div>
            <div className="col-8 my-2 ContainerPage_4Select">
              <select className="strikeBowler">
                <option value="0">Striker Bowler Name select</option>
                {joinMatchPackages.map((data, i) => {
                  var returnData = (
                    <option value={data.id}>{data.fullName}</option>
                  );
                  return returnData;
                })}
              </select>
            </div>
            <span
              className="validationOneTeam d-none centerAlign"
              style={{ color: "red" }}
            >
              Required
            </span>
          </div>
          <span
            className="validation centerAlign mt-3 d-none"
            style={{ color: "red", float: "center" }}
          >
            Both batsment not should be same!!
          </span>
        </div>
        <div className="mt-3 mb-3">
          <hr />
          <div className="row">
            <div className="col-12 p-0 centerAlign">
              <button className="loginbtn" onClick={startMatch}>
                Start Match
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          `${showTeamData.twoTeams == true ? "d-none" : ""}` + " row px-0 m-0"
        }
        id="multipleTeams"
      >
        {joinMatchPackages.length < 10 ? (
          <>
            <div className="my-3">
              <h4 className="text-center">
                For this feature you should have at least 10 or more players
              </h4>
            </div>
          </>
        ) : (
          <>
            <div className="col-6 px-0 mt-2" id="firstTeam">
              <div className="row my-2 mx-1">
                <div className="col-12 profileformControl text-center">
                  <input
                    className="col-10 textClear w-20"
                    type="text"
                    value={TeamAName}
                    onChange={(e) => setTeamAName(e.target.value)}
                    id="teamAValue"
                    autoComplete="off"
                  />
                  <img
                    className="ms-1"
                    src="./images/editIcon.png"
                    alt=""
                    onClick={inputValueA}
                  />
                </div>
              </div>

              <hr />
              <div className="row">
                <div className="col-12 ">
                  {filterDatas.filterDatas1.length == 0 ? (
                    <>
                      <div className="centerAlign h5 my-2">
                        No data Available
                      </div>
                    </>
                  ) : (
                    filterDatas.filterDatas1.map((str, index) => {
                      var tesm = (
                        <>
                          <div
                            key={index}
                            index={index}
                            data-item={str.id}
                            className="centerAlign mb-2"
                          >
                            <div
                              className="firstTeamList"
                              style={{ marginRight: "10px" }}
                              data-value={index}
                            >
                              <input
                                type="radio"
                                className="form-check-input firstTeamList"
                                name={`IsChecked${index}`}
                                id={`teamOne${index}`}
                                value={str.id}
                                defaultChecked={
                                  radioChecked(playerWithTeams, str.id, 2)
                                    ? "checked"
                                    : null
                                }
                              />
                            </div>
                            <div
                              className="col-10 p-1"
                              style={{ backgroundColor: "white" }}
                            >
                              <div className="row">
                                <div className="col-8 ps-4">
                                  <label
                                    className="form-check-label textLabel "
                                    htmlFor={`teamOne${index}`}
                                  >
                                    {str.fullName}
                                  </label>
                                </div>
                                <div className="col-4 text-end pe-4">
                                  <span className="">
                                    <img
                                      src={`/images/PlayerType/${GetPlayerRole(
                                        str.playerRoleID
                                      )
                                        .replace("(", "")
                                        .replace(")", "")
                                        .replace(/\s/g, "")}.svg`}
                                      className="img-fluid"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                      return tesm;
                    })
                  )}
                  <span
                    className="validation1 d-none"
                    style={{ color: "red", float: "right" }}
                  >
                    Required
                  </span>
                </div>
              </div>
            </div>
            <div className="col-6 px-0 mt-2" id="secondTeam">
              <div className="row my-2 mx-1 p-0">
                <div className="col-12 profileformControl">
                  <input
                    className="textClear col-10 w-20"
                    type="text"
                    value={TeamBName}
                    onChange={(e) => setTeamBName(e.target.value)}
                    id="teamBValue"
                    autoComplete="off"
                  />
                  <img
                    className="ms-1"
                    src="./images/editIcon.png"
                    alt=""
                    onClick={inputValueB}
                  />
                </div>
              </div>
              <hr />
              <div className="col-12">
                {filterDatas.filterDatas2.length == 0 ? (
                  <>
                    <div className="centerAlign h5 my-2">No data Available</div>
                  </>
                ) : (
                  filterDatas.filterDatas2.map((str, index) => {
                    var tesm = (
                      <>
                        <div
                          key={index}
                          index={index}
                          data-item={str.id}
                          className="centerAlign mb-2"
                        >
                          <div
                            className="secondTeamList"
                            style={{ marginRight: "10px" }}
                            data-value={index}
                          >
                            <input
                              type="radio"
                              className="form-check-input secondTeamList"
                              name={`IsChecked${index}`}
                              id={`teamTwo${index}`}
                              value={str.id}
                              defaultChecked={
                                radioChecked(playerWithTeams, str.id, 3)
                                  ? "checked"
                                  : null
                              }
                            />
                          </div>
                          <div
                            className="col-10 p-1"
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="row">
                              <div className="col-8 ps-4">
                                <label
                                  className="form-check-label textLabel "
                                  htmlFor={`teamTwo${index}`}
                                >
                                  {str.fullName}
                                </label>
                              </div>
                              <div className="col-4 text-end pe-4">
                                <span className="">
                                  <img
                                    src={`/images/PlayerType/${GetPlayerRole(
                                      str.playerRoleID
                                    )
                                      .replace("(", "")
                                      .replace(")", "")
                                      .replace(/\s/g, "")}.svg`}
                                    className="img-fluid"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                    return tesm;
                  })
                )}
                <span
                  className="validation2"
                  style={{ color: "red", float: "right" }}
                ></span>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <hr />
              <div className="row">
                <div
                  className="col-12 "
                  style={{
                    justifyContent: "flex-end",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <img src="/images/Fill_NextArrow.svg" onClick={Continue} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
