import React, { Component } from "react";
import LiveMatchPlayers_1 from "./LiveScorePagesComponent/LiveMatchPlayers_1";
import LiveMatchPlayers_2 from "./LiveScorePagesComponent/LiveMatchPlayers_2";
import LiveMatchPlayers_3 from "./LiveScorePagesComponent/LiveMatchPlayers_3";
import LiveMatchPlayers_4 from "./LiveScorePagesComponent/LiveMatchPlayers_4";
import LiveScoreCard from "./LiveScoreCard";
import { Authentication } from "./Utils/PopupModal/Authentication";
import { Welcomepage } from "./welcomepage";
import { GetAPI } from "./Utils/APIConnector";
import { isValid } from "./constants";

export default class LiveScorepage extends Component {
  state = {
    step: 1,
    totalPlayers: [],
    teamNames: [{ teamA: "Team A", teamB: "Team B" }],
    playerWithTeams: [],
    TossWon: "",
    TossCall: "",
    TeamChoose: "",
    SingleOrMultipleTeam: "",
    twoTeams: false,
    battingTeamValue: 0,
    overs: 0,
    inningsType: "FirstInning",
    showExitPrompt: true,
    resumeData: {},
    IsInningCompleted: false,
    IsResumed: false,
  };
  initBeforeUnLoad = (showExitPrompt) => {
    var isOnIOS =
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i);
    var eventName = isOnIOS ? "pagehide" : "beforeunload";
    window.addEventListener(eventName, (event) => {
      if (this.state.showExitPrompt) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    });
  };
  componentDidMount = async () => {
    const userType = window.localStorage.getItem("UserType");
    const userid = window.localStorage.getItem("userid");
    const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
    const ResumeMatch = window.localStorage.getItem("ResumeMatch");

    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    if (
      isNaN(userid) ||
      userid === null ||
      userid === 0 ||
      userType === "Guest" ||
      userType === "" ||
      userType === null
    ) {
      window.localStorage.setItem("UserType", "User");
      window.location = "/welcome";
      const { step } = this.state;
      this.setState({
        step: step - 1,
        IsGuest: true,
      });
    } else {
      this.initBeforeUnLoad(this.state.showExitPrompt);
    }

    if (isValid(ResumeMatch)) {
      window.localStorage.removeItem("ResumeMatch");
      await GetAPI(
        "/Matchscorecard/ResumeMatch?matchID=" + parseInt(matchDetailsID)
      ).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var data = JSON.parse(result);
          var matchDetail = data.matchDetails;
          this.setState({
            IsResumed: true,
            inningsType: matchDetail.inningsType,
          });
          if (data.message == "InningCompleted") {
            this.setState({
              TossWon: matchDetail.tossWon,
              TeamChoose: matchDetail.teamChoose,
              inningsType: matchDetail.inningsType,
              SingleOrMultipleTeam: "Multiple",
              overs: matchDetail.totalOvers,
            });
            const { step } = this.state;
            this.setState({
              step: step + 3,
            });
          } else if (data.message == "InningResumed") {
            this.setState({
              teamNames: [
                {
                  teamA: matchDetail.battingTeamName,
                  teamB: matchDetail.bowlingTeamName,
                },
              ],
              overs: matchDetail.totalOvers,
              SingleOrMultipleTeam: "Multiple",
              battingTeamValue: matchDetail.battingTypeID,
              resumeData: matchDetail,
              IsInningCompleted: true,
            });
            const { step } = this.state;
            this.setState({
              step: step + 4,
            });
          }
        }
      });
    }
  };

  prevStep = (e) => {
    const { step } = this.state;
    this.setState({ step: step - e });
  };

  // proceed to the next step
  nextStep = (value, v) => {
    const { step } = this.state;
    this.setState({ step: step + value });
  };
  TeamNames = (values) => {
    this.setState({ teamNames: values });
  };

  totalPlayersFunc = (values) => {
    this.setState({ totalPlayers: values });
  };
  setValues = (names, values) => {
    this.setState({ playerWithTeams: values });
  };

  setDynamicValues = (itemname, values) => {
    this.setState({
      [itemname]: values,
    });
  };

  closeGuestPopup = () => {
    this.setState({
      IsGuest: false,
    });
    window.history.back();
  };
  render() {
    const {
      step,
      teamNames,
      IsGuest,
      playerWithTeams,
      TossCall,
      TossWon,
      TeamChoose,
      SingleOrMultipleTeam,
      twoTeams,
      battingTeamValue,
      overs,
      inningsType,
      resumeData,
      IsInningCompleted,
      IsResumed,
    } = this.state;

    const values = {
      playerWithTeams,
      TossCall,
      TossWon,
      TeamChoose,
      SingleOrMultipleTeam,
      teamNames,
      twoTeams,
      battingTeamValue,
      overs,
      inningsType,
      resumeData,
      IsInningCompleted,
      IsResumed,
    };
    switch (step) {
      case 0:
        return (
          <>
            <Welcomepage />
          </>
        );
      case 1:
        return (
          <>
            <LiveMatchPlayers_1
              nextStep={this.nextStep}
              totalPlayersFunc={this.totalPlayersFunc}
            />
          </>
        );
      case 2:
        return (
          <>
            <LiveMatchPlayers_2
              nextStep={this.nextStep}
              TeamNames={this.TeamNames}
              teamNames={teamNames}
              setValues={this.setValues}
              setDynamicValues={this.setDynamicValues}
              values={values}
              playerWithTeams={playerWithTeams}
            />
          </>
        );
      case 3:
        return (
          <>
            <LiveMatchPlayers_3
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              teamNames={teamNames}
              playerWithTeams={playerWithTeams}
              setDynamicValues={this.setDynamicValues}
              values={values}
            />
          </>
        );
      case 4:
        return (
          <>
            <LiveMatchPlayers_4
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              values={values}
              teamNames={teamNames}
              setDynamicValues={this.setDynamicValues}
            />
          </>
        );
      case 5:
        return (
          <>
            <LiveScoreCard
              values={values}
              teamNames={teamNames}
              prevStep={this.prevStep}
              setDynamicValues={this.setDynamicValues}
            />
          </>
        );

      default:
    }
  }
}
