import { useState, useEffect } from "react";
import Loader from "./Utils/Loader";
import { useNavigate } from "react-router-dom";

export function WelcomeUser() {
  const userType = window.localStorage.getItem("UserType");
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const createOwnMatch = () => {
    window.location = "/play-cricket";
  };
  const showAllMatch = () => {
    window.location = "/find-players";
  };
  const venueListing = () => {
    window.location = "/venue-search";
  };
  const [loading, setLoading] = useState(false);
  var userFullName = window.localStorage.getItem("UserName");
  useEffect(() => {}, []);
  const Data = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className=" px-0 ">
          <div className="col-12 px-4 mt-4" style={{ color: "#339900" }}>
            <p className="paraTxt">
              <b>
                Welcome {userFullName}, your registration has been successfully
                completed.you can enjoy features of app.
              </b>
            </p>
          </div>
          <div className="row p-4">
            <div className="col-12 px-0 mt-4">
              <p className="paraTxt">
                Dummy text Welcome user, your registration has been successfully
                completed.you can enjoy features of app.
              </p>
            </div>
            <div
              className="mt-5 d-flex availableMatchDiv centerAlign position-relative"
              onClick={venueListing}
            >
              <img
                src="/images/1stimage.svg"
                className="dashboardimageclass"
                alt=""
              />
              <div className="col-12 text-center mt-2">
                <span className="availableMatchTxtDiv">Book Sports Venue</span>
                <div className="row text-center">
                  <span className="descText">
                    Find and book your favorite sports venues near you (such as
                    Box Cricket, Football, Snooker, & many more).
                  </span>
                </div>
              </div>
              {/* <div className="col-3">
                  <img
                    src="/images/search_dashboard.svg"
                    className="img-fluid dashboardImage"
                    
                  />
                </div> */}
            </div>
            <div className="col-12 px-0 mt-4">
              <p className="paraTxt">
                Dummy text Welcome user, your registration has been successfully
                completed.you can enjoy features of app.
              </p>
            </div>
            <div
              className="d-flex availableMatchDiv centerAlign position-relative"
              onClick={createOwnMatch}
              style={{ marginTop: "64px" }}
            >
              <img
                src="/images/4thimage.svg"
                className="dashboardimageclass"
                alt=""
              />
              <div className="col-12 text-center mt-3">
                <span className="availableMatchTxtDiv"> Find Players</span>
                <div className="row text-center">
                  <span className="descText">
                    Find players, set up a match, and play with them.
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 px-0 mt-4">
              <p className="paraTxt">
                Dummy text Welcome user, your registration has been successfully
                completed.you can enjoy features of app.
              </p>
            </div>
            <div
              className="d-flex availableMatchDiv centerAlign position-relative"
              onClick={showAllMatch}
              style={{ marginTop: "64px" }}
            >
              <img
                src="/images/3rdimage.svg"
                className="dashboardimageclass"
                alt=""
              />
              <div className="col-12 text-center mt-3">
                <span className="availableMatchTxtDiv">Play Cricket</span>
                <div className="row text-center">
                  <span className="descText">
                    Join teams that need players, showcase your skills, and play
                    with them.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 px-0 mt-4">
              <p className="paraTxt">
                Dummy text Welcome user, your registration has been successfully
                completed.you can enjoy features of app.
              </p>
            </div>

            <div
              className="d-flex availableMatchDiv centerAlign position-relative"
              style={{ marginTop: "64px" }}
              onClick={() => navigate("/venue-information")}
            >
              <img
                src="/images/2ndimage.svg"
                className="dashboardimageclass"
                alt=""
              />
              <div className="col-12 text-center mt-3">
                <span className="availableMatchTxtDiv">List Your Venue</span>

                <div className="row text-center">
                  <span className="descText">
                    Easily list your venue on our app & start receiving bookings
                    after approval.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
