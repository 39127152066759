import React, { useState } from "react";
import DetailsCard from "./DetailsCard";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import {
  convertTo12HourFormat,
  formatDate,
  venueSportsData,
} from "../Utils/common";
import Popup from "../LiveScoreCardpopups/Popup";
import Withdrawalhistory from "../Withdrawal/Withdrawalhistory";
import Swal from "sweetalert2";
import { CustomPostAPI } from "../Utils/APIConnector";

export default function VenueOwnerDashbooard({
  dateid,
  setDateid,
  venueDetails,
  setVenueDetails,
  selectedVenue,
  setActiveLink,
  setCalendarCourtId,
  activeCalendarID,
  endDate,
  startDate,
  setStartDate,
  setEndDate,
}) {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [show, setShow] = useState(false);

  const payout = (e) => {
    var raw = JSON.stringify({
      userid: window.localStorage.getItem("userid"),
      amount: venueDetails?.availablebalance,
      venueid: selectedVenue,
    });

    CustomPostAPI("/payment/Payout", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        if (result === "success") {
          setVenueDetails((prev) => ({
            ...prev,
            availablebalance: 0,
          }));
          Swal.fire({
            icon: "success",
            title: "Your withdrawal request is under review",
            text: "The review process is usually completed within 2 business days.",
            showConfirmButton: true,
            confirmButtonText: "Ok",
            confirmButtonColor: "#000000",
          }).then((result) => {
            if (result.isConfirmed) {
              setVenueDetails((prev) => ({
                ...prev,
                availablebalance: 0,
              }));
            }
          });
        }
      }
    });
  };

  const categories = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const series = [
    {
      name: "Earnings",
      data: venueDetails?.calender,
    },
  ];

  const chartOptions = {
    chart: {
      type: "bar",
      height: 400,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          setSelectedIndex(dataPointIndex);
        },
        dataPointMouseEnter: (event, chartContext, { dataPointIndex }) => {
          setSelectedIndex(dataPointIndex);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "flat",
        borderRadius: 0,
        columnWidth: "50%", // Adjust this value as needed
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: categories.map((_, index) =>
            index === selectedIndex ? "#a51e22" : "black"
          ),
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Montserrat",
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.ceil(Math.max(...(venueDetails?.calender || [])) / 1000) * 1000,
      labels: {
        formatter: function (value) {
          if (value >= 1000) {
            return value / 1000 + "k";
          }
          return value;
        },
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Montserrat",
          colors: ["black"],
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#FBC9D8",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#E0E0E0",
            opacity: 1,
          },
        ],
      },
    },
    colors: ["#EE1E5F"],
    tooltip: {
      enabled: true,
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        const data = series[seriesIndex][dataPointIndex];
        return `<div style="background: #a51e22; color: #fff; padding: 5px 10px; border-radius: 6px; font-family: Montserrat; font-weight: 500; border: 1px solid #a51e22;">
          ₹${data}
        </div>`;
      },
    },
  };

  return (
    <React.Fragment>
      <div className="row mx-0 mt-3">
        <div
          className="px-0 pb-3"
          style={{
            border: "1px solid #F5F5F5",
            borderRadius: "8px",
          }}
        >
          <div className="d-flex justify-content-between d-flex align-items-center p-3">
            <div
              className={`d-flex align-items-center cursor-pointer ${
                dateid == 0 ? "acctiveday" : ""
              }`}
              onClick={() => setDateid(0)}
            >
              <span className="Montserrat fw-semibold fs-14 mx-1">Today</span>
            </div>
            <div
              className={`d-flex align-items-center cursor-pointer ${
                dateid == 1 ? "acctiveday" : ""
              }`}
              onClick={() => setDateid(1)}
            >
              <span className="Montserrat fw-semibold fs-14 mx-1">
                This Week
              </span>
            </div>
            <div
              className={`d-flex align-items-center cursor-pointer ${
                dateid == 2 ? "acctiveday" : ""
              }`}
              onClick={() => setDateid(2)}
            >
              <span className="Montserrat fw-semibold fs-14 mx-1">
                This Month
              </span>
            </div>
            <div
              className={`d-flex align-items-center cursor-pointer ${
                dateid == 3 ? "acctiveday" : ""
              }`}
              onClick={() => setDateid(3)}
            >
              <span className="Montserrat fw-semibold fs-14 mx-1">Custom</span>
            </div>
          </div>
          {dateid == 3 && (
            <div className="d-flex justify-content-evenly mb-4">
              <div>
                Start Date:
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                End Date:
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  min={startDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="row mx-0 px-0">
            <div className="col-6">
              <DetailsCard
                imageSrc="DashboardBookings"
                gradient="#FEC53D"
                description="Bookings"
                heading={venueDetails?.bookingCount}
              />
            </div>
            <div className="col-6">
              <DetailsCard
                imageSrc="DashboardEarnings"
                gradient="#4AD991B2"
                description="Earnings"
                heading={`${(venueDetails?.totalearnings / 1000).toFixed(1)}k`}
              />
            </div>
            <div className="col-6 mt-3">
              <DetailsCard
                imageSrc="Dashboardcbearning"
                gradient="#3D42DFB2"
                description="Earning from cricbuddy"
                heading={`${(venueDetails?.earningsfromcb / 1000).toFixed(1)}k`}
              />
            </div>
            <div className="col-6 mt-3">
              <DetailsCard
                imageSrc="DashboardUsers"
                gradient="#FFB6CC"
                description="Users"
                heading={venueDetails?.users}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0  mt-4">
        <div
          className="col-12 py-2"
          style={{
            border: "1px solid #D7D7D7",
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", color: "#636466" }}
          >
            <img className="me-2" src="/images/DashboardAvailabel.svg" alt="" />
            <span>Available Balance</span>
          </div>
          <b>₹{venueDetails?.availablebalance}</b>
        </div>
        <div
          className="col-12 py-2 mt-3"
          style={{
            border: "1px solid #D7D7D7",
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={(e) => {
            if (venueDetails.availablebalance > 0) {
              Swal.fire({
                icon: "warning",
                title: "Confirm Withdrawal",
                text: `Total Withdrawal Amount : ₹ ${venueDetails?.availablebalance}`,
                showConfirmButton: true,
                confirmButtonText: "Confirm",
                confirmButtonColor: "#000000",
              }).then((result) => {
                if (result.isConfirmed) {
                  // setIsLoading(true);
                  payout(e);
                }
              });
            } else if (venueDetails.availablebalance === 0) {
              Swal.fire({
                icon: "error",
                title: "Unable to Withdraw",
                text: `Available Balance is Zero `,
                showConfirmButton: true,
                confirmButtonText: "Ok",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Unable to Withdraw",
                text: `Request in Progress`,
                showConfirmButton: true,
                confirmButtonText: "Ok",
              });
            }
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", color: "#636466" }}
          >
            <img className="me-2" src="/images/withdrawal_btn.svg" alt="" />
            <span>Withdraw Money</span>
          </div>
          <img src="/images/DashboardNextClickIcon.svg" alt="" />
        </div>
        <div
          className="col-12 py-2 mt-3"
          style={{
            border: "1px solid #D7D7D7",
            borderRadius: "14px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={(e) => {
            setShow(true);
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", color: "#636466" }}
          >
            <img
              className="me-2"
              src="/images/DashboardWithdrawal.svg"
              alt=""
            />
            <span>Withdrawal History</span>
          </div>
          <img src="/images/DashboardNextClickIcon.svg" alt="" />
        </div>
      </div>
      <div className="row mx-0 mt-4">
        <div
          style={{ border: "1px solid #F5F5F5", borderRadius: "8px" }}
          className="px-0 h-100"
        >
          <div className="ps-3 pe-2 pt-3 d-flex justify-content-between align-items-center">
            <span className="Montserrat fw-semibold">
              Monthly Earning chart
            </span>
            <select
              className="EarningSelect"
              value={activeCalendarID}
              onChange={(e) => setCalendarCourtId(Number(e.target.value))}
            >
              <option value={0}>Total</option>
              <option value={1}>From Cricbuddy</option>
            </select>
          </div>
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="bar"
            height="400"
          />
        </div>
      </div>
      <div className="row mx-0 mt-4">
        <div
          className="py-3"
          style={{ border: "1px solid #F5F5F5", borderRadius: "8px" }}
        >
          <p className="Montserrat fw-semibold">Recent Bookings</p>
          {venueDetails?.recentBookings?.length > 0 ? (
            <>
              {venueDetails?.recentBookings?.map((booking) => (
                <Card className="mt-3 border-0 ">
                  <Card.Body className="bg-FAF4F4 borderRadius-6">
                    <div className="d-flex align-items-center Montserrat fw-500 cl-A51E22 fs-12">
                      {" "}
                      {formatDate(booking?.date?.split("T")[0])
                        .split(" ")
                        .slice(0, 2)
                        .join(" ")}{" "}
                      <img
                        className="mx-1"
                        src="/images/centeredDot.svg"
                        alt=""
                      />
                      {convertTo12HourFormat(booking?.startTime)}(
                      {booking?.slotDuration}
                      {booking?.slotDuration > 1 ? "hrs" : "hr"})
                    </div>
                    <div className="d-flex">
                      <div className="w-50">
                        <div className="Montserrat fw-600">
                          {" "}
                          {booking?.user?.firstname
                            ? booking?.user?.firstname
                            : "User"}
                          <div style={{ marginTop: "-8px" }}>
                            <span
                              className="fw-500 fs-12"
                              style={{ color: "#000000B2" }}
                            >
                              {booking?.user?.phone}
                            </span>
                          </div>
                        </div>
                        <div
                          className="Montserrat fw-500 fs-12 pt-1"
                          style={{ color: "#00000073" }}
                        >
                          <img
                            src={`/images/${venueSportsData(booking?.sport)}`}
                            alt=""
                            className="me-1"
                            style={{ opacity: "0.45" }}
                          />
                          {booking?.sport}
                        </div>
                      </div>
                      <div className="w-50 text-end Montserrat align-items-end justify-content-end d-flex flex-column">
                        {booking?.offlineBookingAmount ? (
                          <>
                            <div className="fs-14 cl-000000B2">
                              <label>Remaining</label> -{" "}
                              <span>
                                ₹
                                {booking?.offlineBookingAmount -
                                  booking?.offlinePaidAmount -
                                  booking?.offlineDiscountAmount}
                              </span>
                            </div>
                            <div className="fs-14 cl-000000B2">
                              <label>Discount</label> -{" "}
                              <span>
                                ₹
                                {booking?.offlineDiscountAmount
                                  ? booking?.offlineDiscountAmount
                                  : 0}
                              </span>
                            </div>
                            <div className="fs-14 cl-008A45">
                              <label>Paid</label> -{" "}
                              <span>
                                ₹
                                {booking?.offlinePaidAmount
                                  ? booking?.offlinePaidAmount
                                  : 0}
                              </span>
                            </div>
                            <div className="fw-semibold">
                              <label>Total</label> -{" "}
                              <span>₹{booking?.offlineBookingAmount}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="fs-14 cl-000000B2">
                              <label>Remaining</label> -{" "}
                              <span>₹{booking?.remainingAmt}</span>
                            </div>
                            <div className="fs-14 cl-008A45">
                              <label>Paid</label> -{" "}
                              <span>
                                ₹
                                {booking?.venuePayemntlog?.amount +
                                  booking.venuePayemntlog?.discountAmount}
                              </span>
                            </div>
                            <div className="fw-semibold">
                              <label>Total</label> -{" "}
                              <span>
                                ₹
                                {booking?.venuePayemntlog?.amount +
                                  booking?.remainingAmt +
                                  booking?.venuePayemntlog?.discountAmount}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
              <div className="text-end mt-1 Montserrat w-500 cl-A51E22 text-decoration-underline cursor-pointer">
                <span onClick={(e) => setActiveLink("booking")}>View More</span>
              </div>
            </>
          ) : (
            "No Recent Booking Found"
          )}
        </div>
      </div>
      <div className="row mx-0 mt-4">
        <div
          className="py-3"
          style={{ border: "1px solid #F5F5F5", borderRadius: "8px" }}
        >
          <p className="Montserrat fw-semibold">Top users</p>
          {venueDetails?.topUsers?.length > 0 ? (
            <>
              {venueDetails?.topUsers?.map((user) => (
                <Card className="mt-3 border-0 ">
                  <Card.Body className="bg-FAF4F4 borderRadius-6">
                    <div className="d-flex align-items-start Montserrat fw-600">
                      <div>
                        <img
                          className="me-2 img-fluid"
                          style={{ height: "30px", borderRadius: "50%" }}
                          src={user?.profileImageUrl}
                          alt=""
                        />
                      </div>
                      <div>
                        {user?.firstName ? user?.firstName : "User"}
                        <br />
                        <span className="fs-14 cl-000000B2">
                          {user?.phoneNumber}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </>
          ) : (
            "No Recent User Found"
          )}
        </div>
      </div>
      <Popup show={show} onHide={() => setShow(false)}>
        <Withdrawalhistory venueId={selectedVenue} />
      </Popup>
    </React.Fragment>
  );
}
