import React, { Fragment } from 'react'

export default function Features() {
  return (
    <Fragment>
        <div className='pt-5'>
            <h2 className='latestlandingpage-features-h4 mx-md-0 mx-4'>
            Other features that make the apps stand out.
            </h2>

            <div className="latestlandingpage-features-bg mt-md-5 mt-3">
            <div className="row align-content-center latestlandingpage-features-row mt-md-0 mt-5">
                <div className="col-md-2 text-md-end pl-5 latestlandingpage-features-img-div">
                    <img src="/images/Features1.svg" alt="features1" className='latestlandingpage-features-img'/>
                </div>
                <div className="col-md-5 offset-md-1 text-md-start px-md-0 px-5 mt-md-0 mt-3">
                    <h3  className='latestlandingpage-features-h5'>
                    Is there a shortage of players?
                    </h3>
                    <ul className='latestlandingpage-features-ul custom-list'>
                        <li className='py-1'>Create a match with time & venue details.</li>
                        <li className='py-1'>Describe the skills you are looking for in a player.</li>
                        <li className='py-1'>Receive requests from players, review and approve them to join.</li>
                    </ul>
                </div>
            </div>

            <div className="row mt-5 align-content-center latestlandingpage-features-row latestlandingpage-features-row-sm mt-5">
                <div className="col-md-5 offset-md-1 pl-md-5 px-md-0 px-5 mt-md-0 mt-3">
                    <h3 className='latestlandingpage-features-h5'>
                    Looking for a team to play with?
                    </h3>
                    <ul className='latestlandingpage-features-ul custom-list'>
                        <li className='py-1'>Find games nearby that need more players.</li>
                        <li className='py-1'>Showcase your skills and express interest to join.</li>
                        <li className='py-1'>Upon approval of your interest, join them and play!</li>
                    </ul>
                </div>
                <div className="col-md-2 pl-5 latestlandingpage-features-img-div">
                    <img src="/images/Features2.svg" alt="features2" className='latestlandingpage-features-img'/>
                </div>
            </div>
            </div>
        </div>
    </Fragment>
  )
}
