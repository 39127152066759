import React from "react";

export default function PaymentConfirmed() {
  const context = window.localStorage.getItem("MatchDetails");
  var matchDetails = JSON.parse(context);
  if (
    matchDetails === null ||
    matchDetails === undefined ||
    matchDetails === ""
  ) {
    window.location = "/find-players";
  }
  const detailsOfMatch = (matchID) => {
    window.localStorage.removeItem("MatchDetails");
    window.localStorage.setItem("MatchDetailID", matchID);
    window.location = "/match-details";
  };
  const myJoinedMatches = () => {
    window.localStorage.removeItem("MatchDetails");
    window.location = "/joined-matches";
  };
  const createdMatches = () => {
    window.localStorage.removeItem("MatchDetails");
    window.location = "/find-players";
  };

  return (
    <>
      <div className="confirmedPayment">
        <form>
          <div className="paymentTitle centerAlign p-2 mt-1">
            <div className="">
              <img
                src="/images/Payment Status/Pending.svg"
                className="img-fluid"
              />
            </div>
            <div className="" style={{ paddingLeft: "5px" }}>
              <h4 className="paymentTitle mb-0" style={{ color: "#FFb038" }}>
                <b>Requested</b>
              </h4>
            </div>
          </div>
          <div className="profileformControl mt-2">
            <div className="h6 text-center">
              Your match request has successfully sent to creator of match. You
              will be notified when your match request is accepted/rejected.
            </div>
            <div
              className="text-center my-3"
              onClick={() => detailsOfMatch(matchDetails.MatchId)}
            >
              {matchDetails.data.sportTypeName == "TennisBall" ? (
                <>
                  <div className="d-flex">
                    <div className="col-10 centerAlign">
                      <b
                        className="font2 "
                        style={{ textDecoration: "underline" }}
                      >{`${matchDetails.data.overs} Over Tennis match `}</b>
                    </div>
                    <div className="col-2 px-2">
                      <img
                        className="w-100"
                        src="./images/TennisBall.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-10 px-0">
                      <b
                        className="font1"
                        style={{ textDecoration: "underline" }}
                      >{`${matchDetails.data.overs} Over Season match `}</b>
                    </div>
                    <div className="col-2 px-2">
                      <img
                        className="w-100"
                        src="./images/SeasonBall.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <div className="row text-left">
              <div className="col-6">
                <label className="form-label textLabel">Match time : </label>
              </div>
              <div className="col-6">
                <lable className="dataText">{}</lable>
              </div>
            </div>
            <div className="row text-left">
              <div className="col-6">
                <label className="form-label textLabel">Ground Name : </label>
              </div>
              <div className="col-6">
                <lable className="dataText">{}</lable>
              </div>
            </div> */}

            <div className="textLabel row " style={{ fontSize: "14px" }}>
              <div className="row">
                <label className="form-label textLabel">
                  Order Id : {matchDetails.OrderId}
                </label>
                {}
              </div>
              <div className="row">
                <label className="form-label textLabel">
                  Payment Id : {matchDetails.PaymentId}
                </label>
                {}
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="row mt-3 justify-content-center">
        <div className="col-5">
          <button
            type="submit"
            className="loginbtn w-100"
            //   onClick={redirectToMatches}
            style={{ fontSize: "13px" }}
            onClick={myJoinedMatches}
          >
            Joined Matches
          </button>
        </div>
      </div>
      <div className="row mt-3 justify-content-center">
        <div className="col-5 ">
          <button
            type="submit"
            className="loginbtn w-100"
            //   onClick={redirectToMatches}
            style={{ fontSize: "13px" }}
            onClick={createdMatches}
          >
            Available Match
          </button>
        </div>
      </div>
    </>
  );
}
