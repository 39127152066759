import React from "react";
import { useNavigate } from "react-router-dom";
export default function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="row px-2">
        <div className="col-12">
          <img
            className="cursorClass"
            src="/images/backArrow.svg"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="px-md-5 px-4">
        <div className="row py-5 text-center privacy-policy-border">
          <div className="col-12 mb-3">
            <h1 className="privacy-policy-font1">
              Terms and Conditions of Use for CricBuddy
            </h1>
          </div>
          {/* <div className="col-12"><span className='privacy-policy-font2'>Effective and last updated as on: 10/10/2010</span></div> */}
        </div>
        <div className="row my-3">
          <div className="col-12">
            {/* <h2 className='privacy-policy-font3'>Introduction</h2> */}
            <ol className="privacy-policy-ol1 privacy-policy-mb-2" type="I">
              <li>
                <h2>ACCEPTANCE OF TERMS OF USE</h2>
                <span>
                  By accessing or using the CricBuddy Mobile App and Website (
                  <a href="https://cricbuddy.in">cricbuddy.in</a>), you agree to
                  be bound by these Terms and Conditions of Use. If you do not
                  agree to these Terms and Conditions, you may not access or use
                  the CricBuddy platform.
                  <br />
                  The ownership and operation of the CricBuddy Platform
                  currently reside with SprigStack, with its registered office
                  located at D-402, THE FIRST, behind Keshavbaug Party Plot, I I
                  M, Vastrapur, Ahmedabad, Gujarat 380015, India. References to
                  "We," "us," and "our" herein pertain to SprigStack and our
                  associates/partners/successors/permitted assigns. Before
                  engaging with the CricBuddy Platform, we strongly advise you
                  to carefully review these Terms of Use, as they contain
                  provisions delineating your boundaries, legal rights, and
                  obligations concerning your participation in the CricBuddy
                  Platform and your utilization of the services provided by it
                  (referred to collectively as the "Services").
                  <br />
                  These Terms of Use, along with the Privacy Policy, establish a
                  legally binding agreement between SprigStack and you. By
                  installing, downloading, or simply utilizing the CricBuddy
                  Platform, and by affirming your acceptance by clicking on "I
                  have read and agree to the terms of use," you enter into a
                  contractual relationship with SprigStack, indicating your
                  acknowledgment and acceptance of the Terms of Use and other
                  SprigStack policies (including, but not limited to, the
                  Privacy Policy) as they are periodically posted on the
                  CricBuddy Platform. This agreement becomes effective on the
                  date when you download, install, or commence using the
                  Services, thereby creating a legally binding commitment to
                  adhere to its terms.
                  <br />
                  Your understanding and agreement to these terms are essential.
                </span>
              </li>
              <li>
                <h2>USE OF PLATFORM AND SERVICES</h2>
                <span>
                  You may use the CricBuddy platform to book sports venues, play
                  cricket, join teams, find players, and list your venue for
                  bookings. By using the platform, you agree to abide by the
                  terms and conditions stated here and any additional terms and
                  policies provided by CricBuddy.
                </span>
              </li>
              <li>
                <h2>TERMS OF SERVICE</h2>
                <span>
                  When booking sports venues or participating in cricket
                  activities through CricBuddy, you agree to comply with all
                  applicable laws and regulations. CricBuddy reserves the right
                  to modify or discontinue any aspect of the platform at any
                  time without prior notice.
                </span>
              </li>
              <li>
                <h2>DISCLAIMERS</h2>
                <span>
                  CricBuddy makes no representations or warranties of any kind,
                  express or implied, regarding the accuracy, reliability, or
                  availability of the platform or the content provided therein.
                  Your use of the platform is at your own risk.
                </span>
              </li>
              <li>
                <h2>INTELLECTUAL PROPERTY</h2>
                <span>
                  All content and materials available through the CricBuddy
                  platform, including but not limited to text, graphics, logos,
                  and images, are the property of CricBuddy and are protected by
                  copyright and other intellectual property laws. You may not
                  use, reproduce, or distribute any content from the platform
                  without the prior written consent of CricBuddy.
                </span>
              </li>
              <li>
                <h2>TREATMENT OF USER CONTENT</h2>
                <span>
                  You may have the opportunity to submit content, such as
                  reviews or ratings, through the CricBuddy platform. By
                  submitting content, you grant CricBuddy a non-exclusive,
                  royalty-free, perpetual, irrevocable, and fully sublicensable
                  right to use, reproduce, modify, adapt, publish, translate,
                  create derivative works from, distribute, and display such
                  content throughout the world in any media.
                </span>
              </li>
              <li>
                <h2>THIRD PARTY CONTENT</h2>
                <span>
                  The CricBuddy platform may contain links to third-party
                  websites or services that are not owned or controlled by
                  CricBuddy. CricBuddy has no control over, and assumes no
                  responsibility for, the content, privacy policies, or
                  practices of any third-party websites or services. You further
                  acknowledge and agree that CricBuddy shall not be responsible
                  or liable, directly or indirectly, for any damage or loss
                  caused or alleged to be caused by or in connection with the
                  use of or reliance on any such content, goods, or services
                  available on or through any such websites or services.
                </span>
              </li>
              <li>
                <h2>GOVERNING LAW AND DISPUTE RESOLUTION</h2>
                <span>
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of India. Any dispute arising out
                  of or in connection with these Terms and Conditions shall be
                  subject to the exclusive jurisdiction of the courts of India.
                </span>
              </li>
              <li>
                <h2>CONTACT US</h2>
                <span>
                  If you have any questions about these Terms and Conditions of
                  Use, please contact us at{" "}
                  <a href="mailto:support@cricbuddy.in">support@cricbuddy.in</a>
                  . By using the CricBuddy platform, you agree to be bound by
                  these Terms and Conditions of Use. If you do not agree to
                  these Terms and Conditions, you may not access or use the
                  platform.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
