import React from "react";
import { useNavigate } from "react-router-dom";
export default function PrivacyPolicy() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="row px-2">
        <div className="col-12">
          <img
            className="cursorClass"
            src="/images/backArrow.svg"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="px-md-5 px-4">
        <div className="row py-5 text-center privacy-policy-border">
          <div className="col-12 mb-3">
            <h1 className="privacy-policy-font1">Privacy policy</h1>
          </div>
          <div className="col-12">
            <span className="privacy-policy-font2">
              Effective and last updated as on: 01<sup>st</sup> April 2024
            </span>
          </div>
        </div>
        <ol className="privacy-policy-ol1" type="I">
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Introduction</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Overview of CricBuddy:</h3>
                  <span>
                    CricBuddy, under the umbrella of SprigStack, is dedicated to
                    safeguarding the privacy of its users. This Privacy Policy
                    delineates our approach to collecting, utilizing, sharing,
                    and safeguarding your personal information when you engage
                    with our mobile app and website (
                    <a href="https://cricbuddy.in">https://cricbuddy.in</a>).
                    <br />
                    SprigStack, along with our
                    associates/partners/successors/permitted assigns, is fully
                    committed to upholding your privacy rights, as outlined in
                    this privacy policy ("Policy"). Within this Policy, the term
                    "you" refers to the users of the CricBuddy Platform,
                    encompassing all individuals, regardless of whether they
                    access the services offered on the Platform or complete
                    transactions through it.
                    <br />
                    This Policy provides a comprehensive framework covering two
                    key aspects:
                    <br />
                    (a) The types of information we may collect from you as you
                    access or use our services (collectively, our "Services")
                    through the CricBuddy Platform.
                    <br />
                    (b) Our procedures for collecting, utilizing, maintaining,
                    safeguarding, and disclosing that information.
                    <br />
                    The CricBuddy Platform, which includes both the Website and
                    CricBuddy App, whether accessed directly or through licenses
                    granted by us, is collectively referred to as the
                    "Platform.”
                    <br />
                    By accessing or utilizing this Platform and providing
                    Information (as defined below), you explicitly agree to
                    adhere to the terms and conditions outlined in this Policy.
                    Furthermore, you expressly consent to our use and disclosure
                    of your Information as described herein, indicating your
                    acceptance of this Policy and the Terms of Use (which are
                    incorporated herein by reference).
                    <br />
                    If you do not consent to the terms and conditions outlined
                    in this Policy, please refrain from proceeding further,
                    accessing or utilizing this Platform, or providing us with
                    any information.
                  </span>
                </li>
                <li>
                  <h3>Importance of Privacy:</h3>
                  <span>
                    We understand the importance of privacy and are dedicated to
                    maintaining the confidentiality of your personal
                    information.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Information We Collect</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Personal Information:</h3>
                  <span>
                    We may collect personal information such as your name, email
                    address, phone number, and payment details when you register
                    for an account or make bookings through our platform.
                  </span>
                </li>
                <li>
                  <h3>Usage Information:</h3>
                  <span>
                    We collect information about how you interact with our
                    platform, including your browsing history, search queries,
                    and preferences.
                  </span>
                </li>
                <li>
                  <h3>Location Information:</h3>
                  <span>
                    With your consent, we may collect precise location data to
                    provide location-based services, such as finding nearby
                    sports venues.
                  </span>
                </li>
                <li>
                  <h3>Cookies and Tracking Technologies:</h3>
                  <span>
                    We use cookies and similar tracking technologies to enhance
                    your experience on our platform and analyze usage patterns.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>How We Use Your Information</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Providing and Improving Services:</h3>
                  <span>
                    We use your information to provide and improve our services,
                    including booking sports venues and connecting users with
                    teams and players.
                  </span>
                </li>
                <li>
                  <h3>Communication:</h3>
                  <span>
                    We may use your contact information to communicate with you
                    about your bookings, account updates, and promotional
                    offers.
                  </span>
                </li>
                <li>
                  <h3>Personalization:</h3>
                  <span>
                    We personalize your experience on our platform by displaying
                    tailored content and recommendations based on your
                    preferences.
                  </span>
                </li>
                <li>
                  <h3>Legal Compliance:</h3>
                  <span>
                    We may use your information to comply with legal
                    obligations, such as responding to legal requests and
                    enforcing our terms and policies.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Sharing of Your Information</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>With Third-Party Service Providers:</h3>
                  <span>
                    We may share your information with third-party service
                    providers who assist us in providing and improving our
                    services.
                  </span>
                </li>
                <li>
                  <h3>With Venue Owners and Users:</h3>
                  <span>
                    We may share your information with venue owners and other
                    users as necessary to facilitate bookings and interactions
                    on our platform.
                  </span>
                </li>
                <li>
                  <h3>Legal Requirements:</h3>
                  <span>
                    We may disclose your information in response to legal
                    requests or to protect our rights, property, or safety.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Your Choices and Rights</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Accessing and Updating Your Information:</h3>
                  <span>
                    You can access and update your personal information through
                    your account settings.
                  </span>
                </li>
                <li>
                  <h3>Opting Out of Communications:</h3>
                  <span>
                    You have the option to opt out of receiving promotional
                    communications from us.
                  </span>
                </li>
                <li>
                  <h3>Cookies and Tracking Technologies:</h3>
                  <span>
                    You can manage your cookie preferences through your browser
                    settings.
                  </span>
                </li>
                <li>
                  <h3>Data Retention and Deletion:</h3>
                  <span>
                    We retain your information for as long as necessary to
                    fulfill the purposes outlined in this Privacy Policy. You
                    may request deletion of your account and associated data.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Security Measures</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Data Security:</h3>
                  <span>
                    We implement technical and organizational measures to
                    protect your information against unauthorized access,
                    alteration, and disclosure.
                  </span>
                </li>
                <li>
                  <h3>Account Security:</h3>
                  <span>
                    We implement technical and organizational measures to
                    protect your information against unauthorized access,
                    alteration, and disclosure.
                  </span>
                </li>
                <li>
                  <h3>Children's Privacy:</h3>
                  <span>
                    Our platform is not intended for children under the age of
                    15, and we do not knowingly collect personal information
                    from children.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Third-Party Links and Services</h2>
              </li>
              <ol className="privacy-policy-ol2" type="A">
                <li>
                  <h3>Links to Third-Party Websites:</h3>
                  <span>
                    Our platform may contain links to third-party websites. This
                    Privacy Policy does not apply to those websites, so we
                    recommend reviewing their privacy policies.
                  </span>
                </li>
                <li>
                  <h3>Integration with Third-Party Services:</h3>
                  <span>
                    We may integrate with third-party services, such as social
                    media platforms, to enhance your experience on our platform.
                    Your interactions with these services are governed by their
                    respective privacy policies.
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>International Data Transfers</h2>
              </li>
              <span>
                If you access our platform from outside India, please be aware
                that your information may be transferred to, stored, and
                processed in India and other countries where our service
                providers are located.
              </span>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Updates to this Privacy Policy</h2>
              </li>
              <span>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices and legal requirements. We will notify
                you of any material changes by posting the updated Privacy
                Policy on our platform.
              </span>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <li>
                <h2>Contact Us</h2>
              </li>
              <span>
                If you have any questions or concerns about this Privacy Policy
                or our privacy practices, please contact us at{" "}
                <a href="mailto:support@cricbuddy.in">support@cricbuddy.in</a>.
                <br />
                By using the CricBuddy platform, you consent to the collection,
                use, sharing, and protection of your personal information as
                described in this Privacy Policy.
              </span>
            </div>
          </div>
        </ol>
      </div>
    </React.Fragment>
  );
}
