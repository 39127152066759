import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import $ from "jquery";
import { isValid } from "../constants";
import Loader from "../Utils/Loader";
import { GetAPI, PostAPI } from "../Utils/APIConnector";

export default function AddBowler(props) {
  const [bolwerStatistic, setbolwerStatistic] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);
  const prevBowler = window.localStorage.getItem("strikerBowler");
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  useEffect(async () => {
    await GetAPI(
      "/MatchScoreCard/GetBolwerStatistics?matchid=" +
        parseInt(matchDetailsID) +
        `&InningType=${props.inningsType}`
    ).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        if (result.status === "Success") {
          setbolwerStatistic(result.bolwerStatistics);
          SetIsLoading(false);
        }
      }
    });
  }, []);
  const addBowler = async () => {
    var isFound = true;
    var newBowler = $(".newBowler option:selected").val();
    if (isValid(newBowler)) {
      $(".bowlerError").addClass("d-none");
      window.localStorage.setItem("strikerBowler", newBowler);
      var newBowlerName = $(".newBowler option:selected").text();
      for (let i = 0; i < bolwerStatistic.length; i++) {
        if (bolwerStatistic[i].id === parseInt(newBowler)) {
          isFound = false;
          props.setbowlerScore({
            name: bolwerStatistic[i].bolwerName
              .split(" ")[0]
              .concat(
                " ",
                bolwerStatistic[i].bolwerName.split(" ")[1]
                  ? bolwerStatistic[i].bolwerName.split(" ")[1].charAt(0)
                  : ""
              ),
            over: bolwerStatistic[i].overs,
            maiden: bolwerStatistic[i].maiden,
            runs: bolwerStatistic[i].runs,
            wickets: bolwerStatistic[i].wickets,
            eRate: bolwerStatistic[i].eRate,
          });
        }
      }
      if (isFound) {
        props.setbowlerScore({
          name: newBowlerName,
          over: 0,
          maiden: 0,
          runs: 0,
          wickets: 0,
          eRate: 0,
        });
      }
      props.SetFinalOverButton(false);
      props.onHide();
    } else {
      if (parseInt(props.overs) == parseInt(props.overCount)) {
        var raw = JSON.stringify({
          MatchId: props.currentBall.MatchID,
          BallNumber: props.currentBall.BallNumber,
          BatsmenID: props.currentBall.BatsmenID,
          BowlerID: props.currentBall.BowlerID,
          Run: props.currentBall.Run,
          ExtraType: props.currentBall.ExtraType,
          OutType: props.currentBall.OutType,
          OutBy: props.currentBall.OutBy,
          TeamNumber: props.currentBall.TeamNumber,
          OutPlayerID: props.currentBall.OutPlayerID,
        });
        await PostAPI("/MatchScoreCard/SaveOver/", raw).then((result) => {
          if (result == undefined || result == "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result.status === "Success") {
              props.setbowlerScore({
                over: 0,
                maiden: 0,
                runs: 0,
                wickets: 0,
                eRate: 0,
              });
              props.onHide();
              props.setDynamicValues("inningsType", "SecondInning");
              props.prevStep(1);
            } else {
              alert(result.Message);
            }
          }
        });
      } else {
        $(".bowlerError").removeClass("d-none");
      }
    }
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <Table hover responsive>
              <thead>
                <tr>
                  <th>Names</th>
                  <th>O</th>
                  <th>M</th>
                  <th>R</th>
                  <th>W</th>
                  <th>ECO</th>
                </tr>
              </thead>
              <tbody>
                {bolwerStatistic.map((data, i) => {
                  var returnData;
                  returnData = (
                    <>
                      <tr>
                        <td>{data.bolwerName}</td>
                        <td>{data.overs}</td>
                        <td>{data.maiden}</td>
                        <td>{data.runs}</td>
                        <td>{data.wickets}</td>
                        <td>{data.eRate}</td>
                      </tr>
                    </>
                  );
                  return returnData;
                })}
              </tbody>
            </Table>
          </div>
        </>
      )}

      <div className="row px-3 my-3">
        {props.overs == props.overCount ? (
          <>
            <>
              <div className="">Complete this inning</div>
            </>
          </>
        ) : (
          <>
            <select
              className="form-select OutSelect newBowler"
              defaultValue="0"
            >
              <option value="0">Please Select your new Bowler</option>
              {props.totalPlayers.map((data, i) =>
                data.id === parseInt(prevBowler) ? null : (
                  <option key={i} value={data.id}>
                    {data.fullName}
                  </option>
                )
              )}
            </select>
          </>
        )}
      </div>
      <span
        className="bowlerError centerAlign d-none my-2"
        style={{ color: "red" }}
      >
        Please select Bowler
      </span>
      <div className="row ">
        <div className="col-12 centerAlign">
          <button className="loginbtn" onClick={addBowler}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
