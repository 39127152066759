export default async function displayRazorpay(totalPayment) {

  var information = JSON.stringify({
    "TotalAmount": 201
  })
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append('Access-Control-Allow-Origin', '*');

  const data = await fetch("http://localhost:1337/razorpay", {
    method: "POST",
    body: {
      "TotalAMount":200
    },
    headers: myHeaders
  }).then((t) => t.json());


  const options = {
    key: process.env.RAZORPAY_KEY_ID,
    currency: data.currency,
    amount: 20100,
    name: "Cricket Match Registration",
    description: "Payment of registration of match",
    image: "http://localhost:1337/logo.svg",
    order_id: data.id,
    handler: function (response) {
      alert("PAYMENT ID ::" + response.razorpay_payment_id);
      alert("ORDER ID :: " + response.razorpay_order_id);
    },
    prefill: {
      name: "CricBuddy Test",
      email: "team.sprigstack@gmail.com",
      contact: "9999999999",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

export async function RefundPayment() {
  const data = await fetch("http://localhost:1337/refund", {
    method: "POST",
  }).then((t) => t.json());


  const options = {
    key: process.env.RAZORPAY_KEY_ID,
    amount: data.amount,
    handler: function (response) {
      alert("PAYMENT ID ::" + response.razorpay_payment_id);
    }
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}