import { useEffect, useState } from "react";
import $ from "jquery";
import { ConvertDateTime } from "../constants";
import { PostAPI, CustomPostAPI } from "../Utils/APIConnector";
import { VenueDate, convertTo12HourFormat } from "../Utils/common";

export const MatchDetailPreview = ({
  prevStep,
  nextStep,
  handleChange,
  values,
  validatedInputs,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
  }, []);
  const Continue = (e) => {
    e.preventDefault();
    nextStep();
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const timeAndDate =
    values.MatchDate + " / " + convertTo12HourFormat(values.MatchTime);
  const UserID = parseInt(window.localStorage.getItem("userid"));
  const submitMatchDetails = async () => {
    var raw = JSON.stringify({
      Name: values.MatchName,
      UserId: UserID,
      NumberOfExistingPlayers: values.currentPlayer,
      NumberOfRequiredPlayers: values.needPlayer,
      NumberOfBatsmen: values.TotalBatsmen == "" ? null : values.TotalBatsmen,
      NumberOfBowlers: values.TotalBowler == "" ? null : values.TotalBowler,
      NumberOfAllRounders: values.AllRounders == "" ? null : values.AllRounders,
      NumberOfWicketKeepers:
        values.WicketKeeprs == "" ? null : values.WicketKeeprs,
      GroundId: values.GroundName,
      PgType: values.MatchType,
      Overs: values.TotalOvers.trim(),
      SportTypeId: values.TypeOfMatch.trim() == "Season" ? 3 : 4,
      EntryFees: parseInt(values.MatchFees),
      MatchDateTime: values.MatchDate + " " + values.MatchTime,
      MatchId: 0,
      PGaddress: values.otherGroundAddress ? values.otherGroundAddress : "",
    });
    await PostAPI("/CreateMatches/CreatMatches/", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        if (result.status == "Success") {
          validatedInputs("MatchID", parseInt(result.matchDetails.id));
          nextStep();
        } else {
          alert(result.message);
        }
      }
    });
  };
  return (
    <>
      <div className="px-2">
        <div id="previewDetails" className="row mt-2">
          <div className="p-2 text-center">
            <label style={{ color: "#A51E22" }}>
              <h4>
                <b>Preview Details</b>
              </h4>
            </label>
            <hr />
          </div>
          <div className="col-12">
            <form>
              <div className=" profileformControl">
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Match Name :{" "}
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.MatchName}</label>
                  </div>
                </div>

                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Ground Name :
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.NameOfGround}</label>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Match Date & Time :
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">

                      {/* {ConvertDateTime(timeAndDate)} */}

                      {
                        VenueDate(values.MatchDate.split("-").join("/"))
                          .formattedDate
                      }
                      , {convertTo12HourFormat(values.MatchTime)}
                    </label>
                  </div>
                </div>

                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">Match Type :</label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.TypeOfMatch}</label>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Match Overs :
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.TotalOvers}</label>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">Match Fees :</label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">₹ {values.MatchFees} </label>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Availabel Players :
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.currentPlayer}</label>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-5 pe-0">
                    <label className="form-label textLabel">
                      Required Players :
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">{values.needPlayer}</label>
                  </div>
                </div>
                <div className="row text-left">
                  {/* <div className="col-6">
                                        <label className="form-label textLabel"></label>
                                    </div> */}
                  <div className="col-12">
                    <label className="row">
                      {values.IsChecked ? (
                        <label className="row">
                          {values.TotalBowler != "" ? (
                            <div className="col-6">
                              <label className="dataText">
                                Bowlers: {values.TotalBowler}
                              </label>
                            </div>
                          ) : null}
                          {values.TotalBatsmen != "" ? (
                            <div className="col-6">
                              <label className="dataText">
                                Batsmen: {values.TotalBatsmen}
                              </label>
                            </div>
                          ) : null}
                          {values.WicketKeeprs != "" ? (
                            <div className="col-6">
                              <label className="dataText">
                                Wicket Keepers: {values.WicketKeeprs}
                              </label>
                            </div>
                          ) : null}
                          {values.AllRounders != "" ? (
                            <div className="col-6">
                              <label className="dataText">
                                All Rounders: {values.AllRounders}
                              </label>
                            </div>
                          ) : null}
                        </label>
                      ) : null}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center ">
                <label
                  className="form-label textLabel mb-0"
                  style={{ textTransform: "uppercase" }}
                >
                  Match Fees Payable now for {values.currentPlayer} Players
                </label>
                <div
                  className="p-2 dataText"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <label className="dataText profileformControl m-2">
                    {values.currentPlayer}
                  </label>
                  X
                  <label className="form-label dataText profileformControl m-2">
                    {values.MatchFees}
                  </label>
                  =
                  <label className="form-label dataText profileformControl m-2">
                    ₹{" "}
                    {parseInt(values.currentPlayer) *
                      parseInt(values.MatchFees)}{" "}
                  </label>
                </div>
              </div>
              <span
                className="requiredValidation text-center validationText"
                style={{ fontSize: "14px", color: "black" }}
              >
                <p>
                  *We will charge{" "}
                  <span className="fw-bold">
                    {process.env.REACT_APP_CUSTOM_PLATFORM_FEE}
                  </span>{" "}
                  platform fees and{" "}
                  <span className="fw-bold">
                    {process.env.REACT_APP_CUSTOM_REFUND_FEE}
                  </span>{" "}
                  fees will be refunded to your payment source after match is
                  completed.
                </p>
              </span>
            </form>
          </div>
        </div>
        <div id="previewButtons" className="mt-3 mb-3">
          <hr />
          <div className="row">
            {/*  <div className="col-6 "> 
             <button type="submit" className="col-8 pr-2 loginbtn" onClick={Previous}>Prev Step</button> 
           <div
              className="col-1"
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                display: "flex",
              }}
            >
              <a onClick={Previous}>
                <img src="/images/Fill_BackArrow.svg" />
              </a>
            </div> */}
            <div className="row mt-4 d-flex justify-content-center">
              <div className="col-md-3 col-4">
                <button onClick={Previous} className="btn btn-gray">
                  Previous
                </button>
              </div>
              <div className="col-md-3 col-4">
                <button onClick={submitMatchDetails} className="btn btn-danger">
                  Confirm
                </button>
              </div>
            </div>

            {/* </div>*/}
            {/* <div
              className="col-11"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <button
                type="submit"
                className="loginbtn col-5"
                onClick={submitMatchDetails}
              >
                Confirm
              </button>
            </div> */}
          </div>
        </div>
        <div id="paymentDiv" className="d-none">
          <div className="p-2 text-center ">
            <label>
              <h4>
                <b>Payment Details</b>
              </h4>
            </label>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};
