import React, { useState, useLayoutEffect, useEffect } from "react";
import {
  CheckValidation,
  convertDecimalToTime,
  convertTo12HourFormat,
} from "../Utils/common";
import Select from "react-select";
import $ from "jquery";
import { Card, Modal } from "react-bootstrap";
import { CustomPostAPI, PostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import WeekDays from "./PricingDetails/WeekDays";
import Popup from "../LiveScoreCardpopups/Popup";

export const AddSurface = ({
  // isLoading,
  // setIsLoading,
  setcurrentStep,
  surfaces,
  sportstype,
  formValuesSportsFacilities,
  setFormValues,
  listData,
  isSecondTime,
  setIsSecondTime,
  state,
  weekdaytype,
  setweekdaytype,
  slotDuration,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [requiredsatsun, setRequiredSatSun] = useState(false);
  const [selectedIndex, setselectedIndex] = useState("");
  const [daysToShow, setDaysToShow] = useState([]);
  const [AllDaysChecked, setAllDaysChecked] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]); // New state for error messages
  const [expandedIndex, setExpandedIndex] = useState(null);
  const hasMonFriData = (monFriArray) => {
    return (
      monFriArray &&
      monFriArray.some((item) => item.startTime && item.endTime && item.amount)
    );
  };
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, []);

  const [showVenueListingMsg, setShowVenueListingMsg] = useState(false);

  let handleChange = (i, e) => {
    if (e.target.name === "NoOfCarts" && e.target.value === "0") {
      return;
    }
    let newFormValues = [...formValuesSportsFacilities];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    //  console.log("Current value of isSecondTime:", isSecondTime);
    isSecondTime === "0" ? setIsSecondTime("0") : setIsSecondTime("2");
    // console.log("Updated value of isSecondTime:", isSecondTime);
  };

  const handleSelectChange = (i, selectedOptions) => {
    if (
      selectedOptions.length > 1 &&
      selectedOptions[0].label === "Go-Karting"
    ) {
      selectedOptions = selectedOptions.filter((option) => option.value !== 7);
    }

    const filteredOptions = selectedOptions.filter(
      (option) => option.label === "Go-Karting"
    );

    const newFormValues = [...formValuesSportsFacilities];
    newFormValues[i].Sports =
      filteredOptions.length > 0 ? filteredOptions : selectedOptions;

    setFormValues(newFormValues);
    isSecondTime === "0" ? setIsSecondTime("0") : setIsSecondTime("2");
  };

  let addFormFields = (i) => {
    let arrayData = [...listData];
    arrayData[0].weekDay[0].startTime = state.StartTime;
    arrayData[0].weekDay[0].endTime = state.EndTime;
    // arrayData[0].Courtname =
    //   state?.category === "sports"
    //     ? "Space " + (formValuesSportsFacilities.length + 1)
    //     : "Space " + (formValuesSportsFacilities.length + 1);
    const updatedFormValues = [...formValuesSportsFacilities, listData[0]];
    setFormValues(updatedFormValues);
  };

  useEffect(() => {
    // Close all other accordions
    formValuesSportsFacilities?.forEach((_, idx) => {
      const accordionContent = document.getElementById(`collapse${idx}`);
      const accordionButton = document.getElementById(
        `accordion-button-${idx}`
      );

      if (accordionContent && accordionButton) {
        accordionContent.classList.remove("show");
        accordionButton.classList.add("collapsed");
      }
    });

    // Open the newly added accordion
    const newIndex = formValuesSportsFacilities.length - 1;
    const newAccordionContent = document.getElementById(`collapse${newIndex}`);
    const newAccordionButton = document.getElementById(
      `accordion-button-${newIndex}`
    );

    if (newAccordionContent && newAccordionButton) {
      newAccordionContent.classList.add("show");
      newAccordionButton.classList.remove("collapsed");
    }
  }, [formValuesSportsFacilities && formValuesSportsFacilities.length]);

  const removeFormFields = (i) => {
    // Create a copy of the formValuesSportsFacilities array
    const newFormValues = [...formValuesSportsFacilities];

    // Remove the item at the specified index
    newFormValues.splice(i, 1);

    // If all items are removed, set data to an empty array while keeping the structure
    if (newFormValues.length === 0) {
      newFormValues.push({
        venuesportId: null,
        SurfaceType: state?.category == "sports" ? "" : 10,
        Courtname: "",
        CourtCount: "",
        MaxCapacity: "1",
        NoOfCarts: "",
        Sports: [],
        weekDay: [
          {
            weekDays: "",
            data: [
              {
                startTime: "",
                endTime: "",
                amount: "",
                duration1: "",
                duration2: "",
                duration3: "",
              },
            ],
          },
        ],
        VenueId: 0,
      });
    }
    isSecondTime === "0" ? setIsSecondTime("0") : setIsSecondTime("2");
    // Update the form values
    setFormValues(newFormValues);
  };
  function isAllDataBlank(data) {
    for (const item of data) {
      if (item?.amount.trim() !== "" && item?.amount.trim() !== "0") {
        return false;
      }
    }
    return true;
  }

  const nextStep = async () => {
    setIsLoading(true);
    const isFormValid = CheckValidation({ formID: "AddSurface" });
    let isValidSelect = false;
    let hasErrors = false;
    let pricingAdded = true;
    let newErrorMessages = [...errorMessages];
    let newExpandedIndex = null;

    for (let i = 0; i < formValuesSportsFacilities.length; i++) {
      const facility = formValuesSportsFacilities[i];

      if (
        facility.weekDay.length < 1 ||
        (facility.weekDay.length === 1 &&
          isAllDataBlank(facility.weekDay[0].data))
      ) {
        pricingAdded = false;
        newErrorMessages[i] = "Please add at least one pricing.";
        newExpandedIndex = i;
        break;
      } else {
        newErrorMessages[i] = ""; // Clear the error message for this index if pricing is added
      }
    }

    if (!pricingAdded) {
      setErrorMessages(newErrorMessages); // Set the error messages state
      setExpandedIndex(newExpandedIndex);
      setIsLoading(false);
      return;
    }

    // Clear all error messages if all pricing is added
    setErrorMessages([]);
    setExpandedIndex(null);

    formValuesSportsFacilities.forEach((_, idx) => {
      const accordionContent = document.getElementById(`collapse${idx}`);
      const accordionButton = document.getElementById(
        `accordion-button-${idx}`
      );

      if (accordionContent && accordionButton) {
        accordionContent.classList.remove("show");
        accordionButton.classList.add("collapsed");
      }
    });

    for (let i = 0; i < formValuesSportsFacilities.length; i++) {
      const sportsArray = formValuesSportsFacilities[i]?.Sports;
      if (sportsArray && sportsArray.length === 0) {
        $(`.sport-error-${i}`).text("* This field is Required!");
        isValidSelect = true;
        hasErrors = true;
      } else {
        $(`.sport-error-${i}`).text("");
        isValidSelect = false;
      }
      const x = formValuesSportsFacilities[i];
      if (
        Object.entries(x).some(
          ([key, value]) =>
            !["extrahourprice", "rehearsalprice", "venuesportId"].includes(
              key
            ) &&
            (value === "" ||
              value === null ||
              value === undefined ||
              (Array.isArray(value) && value.length === 0))
        )
      ) {
        const emptyAcc = document.getElementById(`heading-${i}`);
        const accordionItem = emptyAcc.closest(".accordion-item");
        if (accordionItem) {
          accordionItem.style.border = "1px solid red";
        } else {
          accordionItem.style.border = "1px solid rgba(0,0,0,.125)";
        }

        // Open the first accordion with invalid fields
        const accordionContent = document.getElementById(`collapse${i}`);
        const accordionButton = document.getElementById(
          `accordion-button-${i}`
        );

        if (accordionContent && accordionButton) {
          accordionContent.classList.add("show");
          accordionButton.classList.remove("collapsed");
          hasErrors = true;
          break; // Open only the first invalid accordion
        }

        hasErrors = true;
      }
    }

    formValuesSportsFacilities?.map((monfri) => {
      if (monfri?.Sports[0]?.label === "Go-Karting") {
        monfri?.MonFri?.forEach((value) => {
          if (
            value.duration1 === "" &&
            value.duration2 === "" &&
            value.duration3 === ""
          ) {
            setRequired(true);
          } else {
            setRequired(false);
          }
        });
        if (weekdaytype === "Yes") {
          let monFriDuration1HasValue = false;
          let monFriDuration2HasValue = false;
          let monFriDuration3HasValue = false;
          monfri?.MonFri?.forEach((value) => {
            if (value.duration1 && value.duration1 !== "") {
              monFriDuration1HasValue = true;
            }
            if (value.duration2 && value.duration2 !== "") {
              monFriDuration2HasValue = true;
            }
            if (value.duration3 && value.duration3 !== "") {
              monFriDuration3HasValue = true;
            }
          });

          monfri?.SatSun?.forEach((value) => {
            if (monFriDuration1HasValue && value.duration1 === "") {
              setRequiredSatSun(true);
            } else {
              monFriDuration1HasValue = false;
            }
            if (monFriDuration2HasValue && value.duration2 === "") {
              setRequiredSatSun(true);
            } else {
              monFriDuration2HasValue = false;
            }
            if (monFriDuration3HasValue && value.duration3 === "") {
              setRequiredSatSun(true);
            } else {
              monFriDuration3HasValue = false;
            }
          });
          if (
            !monFriDuration1HasValue &&
            !monFriDuration2HasValue &&
            !monFriDuration3HasValue
          ) {
            setRequiredSatSun(false);
          }
        } else {
          monfri.SatSun = monfri?.MonFri;
        }
      }
    });

    if (isFormValid && !hasErrors && !required && !requiredsatsun) {
      if (isSecondTime === "0") {
        const raw = JSON.stringify({
          stage: 2,
          Userid: window.localStorage.getItem("userid"),
          VenueId: formValuesSportsFacilities[0].VenueId,
          SportsFacilities: formValuesSportsFacilities,
          Email: state?.Email,
          WeekendRate: weekdaytype === "Yes" ? true : false,
          MinSlotTime: Math.round(slotDuration * 60),
        });
        await CustomPostAPI("/Venue/Createvenue", raw).then((result) => {
          if (result == undefined || result == "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result.status == "Success") {
              setIsLoading(false);
              window.localStorage.setItem("UserType", "Venueowner");
              setIsSecondTime("1");
              setShowVenueListingMsg(true);
            } else {
              alert(result.message);
            }
          }
        });
      } else if (isSecondTime === "2") {
        const raw = JSON.stringify({
          stage: 2,
          Userid: window.localStorage.getItem("userid"),
          VenueId: formValuesSportsFacilities[0].VenueId,
          SportsFacilities: formValuesSportsFacilities,
          WeekendRate: weekdaytype === "Yes" ? true : false,
          MinSlotTime: Math.round(slotDuration * 60),
        });
        await CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
          if (result == undefined || result == "") {
            // alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            setIsLoading(false);
            setIsSecondTime("1");
            setcurrentStep(3);
          }
        });
      } else {
        setIsSecondTime("1");
        setcurrentStep(3);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  // const nextStep = async () => {
  //   setIsLoading(true);
  //   const isFormValid = CheckValidation({ formID: "AddSurface" });
  //   let isValidSelect = false;
  //   let hasErrors = false;

  //   formValuesSportsFacilities.forEach((_, idx) => {
  //     const accordionContent = document.getElementById(`collapse${idx}`);
  //     const accordionButton = document.getElementById(
  //       `accordion-button-${idx}`
  //     );

  //     if (accordionContent && accordionButton) {
  //       accordionContent.classList.remove("show");
  //       accordionButton.classList.add("collapsed");
  //     }
  //   });

  //   for (let i = 0; i < formValuesSportsFacilities.length; i++) {
  //     const sportsArray = formValuesSportsFacilities[i]?.Sports;
  //     if (sportsArray && sportsArray.length === 0) {
  //       $(`.sport-error-${i}`).text("* This field is Required!");
  //       isValidSelect = true;
  //       hasErrors = true;
  //     } else {
  //       $(`.sport-error-${i}`).text("");
  //       isValidSelect = false;
  //     }

  //     const x = formValuesSportsFacilities[i];
  //     if (
  //       Object.values(x).some(
  //         (value) => value === "" || value === null || value === undefined
  //       )
  //     ) {
  //       const emptyAcc = document.getElementById(`heading-${i}`);
  //       const accordionItem = emptyAcc.closest(".accordion-item");
  //       if (accordionItem) {
  //         accordionItem.style.border = "1px solid red";
  //       } else {
  //         accordionItem.style.border = "1px solid rgba(0,0,0,.125)";
  //       }

  //       // Open the first accordion with invalid fields
  //       const accordionContent = document.getElementById(`collapse${i}`);
  //       const accordionButton = document.getElementById(
  //         `accordion-button-${i}`
  //       );

  //       if (accordionContent && accordionButton) {
  //         accordionContent.classList.add("show");
  //         accordionButton.classList.remove("collapsed");
  //         break; // Open only the first invalid accordion
  //       }

  //       hasErrors = true;
  //     }
  //   }
  //   formValuesSportsFacilities?.map((monfri) => {
  //     if (monfri?.Sports[0]?.label === "Go-Karting") {
  //       monfri?.MonFri?.forEach((value) => {
  //         if (
  //           value.duration1 === "" &&
  //           value.duration2 === "" &&
  //           value.duration3 === ""
  //         ) {
  //           setRequired(true);
  //         } else {
  //           setRequired(false);
  //         }
  //       });
  //       if (weekdaytype === "Yes") {
  //         let monFriDuration1HasValue = false;
  //         let monFriDuration2HasValue = false;
  //         let monFriDuration3HasValue = false;
  //         monfri?.MonFri?.forEach((value) => {
  //           if (value.duration1 && value.duration1 !== "") {
  //             monFriDuration1HasValue = true;
  //           }
  //           if (value.duration2 && value.duration2 !== "") {
  //             monFriDuration2HasValue = true;
  //           }
  //           if (value.duration3 && value.duration3 !== "") {
  //             monFriDuration3HasValue = true;
  //           }
  //         });

  //         monfri?.SatSun?.forEach((value) => {
  //           if (monFriDuration1HasValue && value.duration1 === "") {
  //             setRequiredSatSun(true);
  //           } else {
  //             monFriDuration1HasValue = false;
  //           }
  //           if (monFriDuration2HasValue && value.duration2 === "") {
  //             setRequiredSatSun(true);
  //           } else {
  //             monFriDuration2HasValue = false;
  //           }
  //           if (monFriDuration3HasValue && value.duration3 === "") {
  //             setRequiredSatSun(true);
  //           } else {
  //             monFriDuration3HasValue = false;
  //           }
  //         });
  //         if (
  //           !monFriDuration1HasValue &&
  //           !monFriDuration2HasValue &&
  //           !monFriDuration3HasValue
  //         ) {
  //           setRequiredSatSun(false);
  //         }
  //       } else {
  //         monfri.SatSun = monfri?.MonFri;
  //       }
  //     }
  //   });
  //   console.log(
  //     formValuesSportsFacilities,
  //     "formValuesSportsFacilitiesformValuesSportsFacilities"
  //   );
  //   if (isFormValid && !hasErrors && !required && !requiredsatsun) {
  //     if (isSecondTime === "0") {
  //       const raw = JSON.stringify({
  //         stage: 2,
  //         Userid: window.localStorage.getItem("userid"),
  //         VenueId: formValuesSportsFacilities[0].VenueId,
  //         SportsFacilities: formValuesSportsFacilities,
  //         Email: state?.Email,
  //         WeekendRate: weekdaytype === "Yes" ? true : false,
  //       });
  //       await CustomPostAPI("/Venue/Createvenue", raw).then((result) => {
  //         if (result == undefined || result == "") {
  //           alert("Something went wrong");
  //         } else {
  //           result = JSON.parse(result);
  //           if (result.status == "Success") {
  //             setIsLoading(false);
  //             window.localStorage.setItem("UserType", "Venueowner");
  //             setIsSecondTime("1");
  //             setShowVenueListingMsg(true);
  //           } else {
  //             alert(result.message);
  //           }
  //         }
  //       });
  //     } else if (isSecondTime === "2") {
  //       const raw = JSON.stringify({
  //         stage: 2,
  //         Userid: window.localStorage.getItem("userid"),
  //         VenueId: formValuesSportsFacilities[0].VenueId,
  //         SportsFacilities: formValuesSportsFacilities,
  //         WeekendRate: weekdaytype === "Yes" ? true : false,
  //       });
  //       await CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
  //         if (result == undefined || result == "") {
  //           // alert("Something went wrong");
  //         } else {
  //           result = JSON.parse(result);
  //           setIsLoading(false);
  //           setIsSecondTime("1");
  //           setcurrentStep(3);
  //         }
  //       });
  //     } else {
  //       setIsSecondTime("1");
  //       setcurrentStep(3);
  //       setIsLoading(false);
  //     }
  //   } else {
  //     setIsLoading(false);
  //   }
  // };

  const prevStep = () => {
    setcurrentStep(1);
  };
  const handleDeleteSelectedData = (formIndex, dayIndex) => {
    const newData = formValuesSportsFacilities.map((item, index) => {
      if (index === formIndex) {
        const updatedWeekDay = item.weekDay.filter(
          (_, wdIndex) => wdIndex !== dayIndex
        );
        return {
          ...item,
          weekDay: updatedWeekDay,
        };
      }
      setAllDaysChecked(false);

      return item;
    });
    isSecondTime === "0" ? setIsSecondTime("0") : setIsSecondTime("2");
    // If all WeekDay items are removed, reset the structure
    const updatedData = newData.map((item) => {
      if (item.weekDay.length === 0) {
        return {
          ...item,
          weekDay: [
            {
              weekDays: "",
              data: [
                {
                  startTime: "",
                  endTime: "",
                  amount: "",
                  duration1: "",
                  duration2: "",
                  duration3: "",
                },
              ],
            },
          ],
        };
      }
      return item;
    });

    setFormValues(updatedData);
  };
  const [days, setDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  useEffect(() => {
    let weekDaysToExclude = [];

    formValuesSportsFacilities[selectedIndex]?.weekDay?.forEach((item) => {
      if (item.weekDays) {
        weekDaysToExclude = weekDaysToExclude.concat(
          item.weekDays.split(",").map((day) => day.trim())
        );
      }
    });

    const filteredDays = Object.keys(days).filter(
      (day) => !weekDaysToExclude.includes(day)
    );
    setDaysToShow(filteredDays);
  }, []);

  function areAllWeekdaysCovered(data) {
    // Define an array of all weekdays
    const allWeekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Extract the WeekDays from the input data and split any combined weekdays
    const weekdaysInData = data?.weekDay?.flatMap((weekdayObj) =>
      weekdayObj.weekDays.split(",").map((day) => day.trim())
    );

    // Check if all weekdays are covered
    for (let weekday of allWeekdays) {
      if (!weekdaysInData.includes(weekday)) {
        return true; // If any weekday is missing, return true
      }
    }

    return false; // If all weekdays are present, return false
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader mt="30%" />
      ) : (
        <div className="row px-2">
          <div className="col-12 venueInformationHeader">Pricing</div>
          <div className="col-12 venueInformationBody mt-2">
            <div className="row" id="AddSurface">
              <div className="accordion mt-2" id="accordionExample">
                {formValuesSportsFacilities?.map((element, index) => (
                  <>
                    <div class="accordion-item my-2">
                      <h2 class="accordion-header" id={`heading-${index}`}>
                        <div
                          className="row mx-0 p-2"
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <div
                            className="col-4 ps-0 display-center"
                            style={{
                              backgroundColor: "#F2F2F2 !important",
                              color: "black !important",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {state?.category == "sports"
                              ? "Court/Box"
                              : "Event Space"}{" "}
                            {index + 1}*
                          </div>
                          {/* <div
                            className="col-4 ps-0 display-center"
                            style={{
                              backgroundColor: "#F2F2F2 !important",
                              color: "black !important",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            Surface Type*
                          </div> */}
                          <div className="col-5 col-md-4 px-0 parent">
                            <input
                              type="text"
                              className="form-control px-1"
                              name="Courtname"
                              value={
                                formValuesSportsFacilities[index]?.Courtname
                              }
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                              placeholder="Name"
                              required
                            />
                            <span
                              className="form-text invalid-feedback"
                              style={{ fontSize: "14px" }}
                            >
                              * This field is Required!
                            </span>
                          </div>
                          {/* <div className="col-5 col-md-4 px-0 parent">
                            <select
                              id="selectSurface"
                              required
                              className="form-select "
                              aria-label="Select Surface Type"
                              name="SurfaceType"
                              value={
                                formValuesSportsFacilities[index]?.SurfaceType
                              }
                              onChange={(e) => {
                                handleChange(index, e);
                              }}
                            >
                              <option
                                value="0"
                                defaultValue={true}
                                style={{
                                  backgroundColor: "black !important",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Select
                              </option>
                              {surfaces?.map((data, i) => (
                                <option
                                  value={data.surfaceId}
                                  defaultValue={true}
                                  style={{
                                    backgroundColor: "black !important",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {data.surfaceName}
                                </option>
                              ))}
                            </select>
                            <span
                              className="form-text invalid-feedback"
                              style={{ fontSize: "14px" }}
                            >
                              * This field is Required!
                            </span>
                          </div> */}

                          <div className="col-1 col-md-2  pe-0">
                            <div className="row">
                              <div
                                className="col-12 px-1"
                                style={{
                                  placeSelf: "center",
                                  textAlign: "end",
                                }}
                              >
                                {index == 0 ? (
                                  <img
                                    className="img-fluid "
                                    src="/images/addicon.svg"
                                    alt="icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      addFormFields(index);
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid "
                                    src="/images/deleteicon.svg"
                                    alt="icon"
                                    onClick={() => removeFormFields(index)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-2 pe-0">
                            <button
                              id={`accordion-button-${index}`}
                              className={`accordion-button accordion-button-child ${
                                expandedIndex !== index ? "collapsed" : ""
                              }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${index}`}
                              aria-expanded={
                                expandedIndex === index ? "true" : "false"
                              }
                              aria-controls={`collapse${index}`}
                              style={{ float: "right" }}
                              onClick={() =>
                                setExpandedIndex(
                                  expandedIndex === index ? null : index
                                )
                              }
                            ></button>
                          </div>
                        </div>
                      </h2>

                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          expandedIndex === index ? "show" : ""
                        }`}
                        collapse
                        show
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          style={{ backgroundColor: "#F9F9F9" }}
                        >
                          {state?.category == "sports" && (
                            <div className="row mt-3">
                              <div className="col-5">
                                <span className="venueText">Surface Type*</span>
                              </div>
                              <div className="col-7 parent">
                                <select
                                  id="selectSurface"
                                  required
                                  className="form-select "
                                  aria-label="Select Surface Type"
                                  name="SurfaceType"
                                  value={
                                    formValuesSportsFacilities[index]
                                      ?.SurfaceType
                                  }
                                  onChange={(e) => {
                                    handleChange(index, e);
                                  }}
                                >
                                  <option
                                    value="0"
                                    defaultValue={true}
                                    style={{
                                      backgroundColor: "black !important",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    Select
                                  </option>
                                  {surfaces?.map((data, i) => (
                                    <option
                                      value={data.surfaceId}
                                      defaultValue={true}
                                      style={{
                                        backgroundColor: "black !important",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {data.surfaceName}
                                    </option>
                                  ))}
                                </select>
                                <span
                                  className="form-text invalid-feedback"
                                  style={{ fontSize: "14px" }}
                                >
                                  * This field is Required!
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="row mt-3">
                            <div className="col-5">
                              <span className="venueText">
                                {state?.category == "sports"
                                  ? "Sports"
                                  : "Event Space Type"}
                                *
                              </span>
                            </div>

                            <div className="col-7 parent">
                              <Select
                                key={index}
                                value={
                                  formValuesSportsFacilities[index]?.Sports
                                }
                                id={`selectSports-${index}`}
                                aria-label={`Select Sports ${index + 1}`}
                                name="Sports"
                                isRequired
                                onChange={(selectedOptions) =>
                                  handleSelectChange(index, selectedOptions)
                                }
                                options={sportstype}
                                isMulti
                              />

                              <span
                                className={`form-text text-danger sport-error-${index}`}
                              ></span>
                            </div>
                          </div>
                          {formValuesSportsFacilities[index]?.Sports[0]
                            ?.label != "Go-Karting" ? (
                            <div className="row mt-3 parent">
                              <div className="col-5">
                                <span className="venueText">Max Capacity*</span>
                              </div>
                              <div className="col-7">
                                <input
                                  required
                                  type="number"
                                  inputMode="numeric"
                                  name="MaxCapacity"
                                  value={
                                    formValuesSportsFacilities[index]
                                      ?.MaxCapacity
                                  }
                                  onChange={(e) => {
                                    handleChange(index, e);
                                  }}
                                  className="form-control"
                                />
                                <span className="form-text invalid-feedback">
                                  * This field is Required!
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="row mt-3">
                              <div className="col-5">
                                <span className="venueText"> No Of Karts*</span>
                              </div>
                              <div className="col-7 parent">
                                <input
                                  required
                                  type="number"
                                  inputMode="numeric"
                                  name="NoOfCarts"
                                  value={
                                    formValuesSportsFacilities[index]?.NoOfCarts
                                  }
                                  onChange={(e) => {
                                    handleChange(index, e);
                                  }}
                                  className="form-control"
                                />
                                <span className="form-text invalid-feedback">
                                  * This field is Required!
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="accordion" id="BookingRates">
                            <div className="accordion-item mt-3">
                              <h2 className="accordion-header">
                                <button
                                  className="accordion-button accordion-button-parent"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseBooking"
                                  aria-expanded="true"
                                  aria-controls=""
                                  style={{
                                    backgroundColor: "white !important",
                                    color: "black !important",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Booking Day Availability &&nbsp;
                                  <span style={{ color: "#a51e22" }}>
                                    Rates
                                  </span>
                                </button>
                              </h2>

                              {areAllWeekdaysCovered(
                                formValuesSportsFacilities[index]
                              ) && (
                                <button
                                  className="btn mt-3"
                                  onClick={() => {
                                    setShow(true);
                                    setselectedIndex(index);
                                  }}
                                >
                                  Add Pricing
                                </button>
                              )}
                              {errorMessages[index] && (
                                <div
                                  className="error-message"
                                  style={{ color: "red" }}
                                >
                                  {errorMessages[index]}
                                </div>
                              )}
                              {/* )} */}
                              {/* )} */}

                              <div className="row mt-3">
                                {formValuesSportsFacilities[
                                  index
                                ]?.weekDay?.map((day, dayIndex) => {
                                  const isGoKarting =
                                    formValuesSportsFacilities[index]?.Sports[0]
                                      ?.label === "Go-Karting";

                                  if (isGoKarting) {
                                    return (
                                      day?.weekDays !== "" &&
                                      day?.data?.length > 0 && (
                                        <div
                                          key={dayIndex}
                                          className="col-md-6"
                                        >
                                          <div
                                            className="p-3 mb-3"
                                            style={{
                                              backgroundColor: "#e8e8e8",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div
                                                className="fw-600"
                                                style={{ fontSize: "16px" }}
                                              >
                                                {day.weekDays}
                                              </div>
                                              <div>
                                                <img
                                                  className="cursor-pointer ms-2"
                                                  src="/images/deleteicon.svg"
                                                  title="Delete Slot"
                                                  alt="icon"
                                                  onClick={() =>
                                                    handleDeleteSelectedData(
                                                      index,
                                                      dayIndex
                                                    )
                                                  }
                                                  height="22px"
                                                />
                                              </div>
                                            </div>
                                            {day?.data?.map((item, idx) => (
                                              <div key={idx} className="mt-2">
                                                <div>
                                                  {item.duration1 &&
                                                    "5 Min: Amount: ₹" +
                                                      item.duration1}{" "}
                                                  <br />
                                                  {item.duration2 &&
                                                    "10 Min: Amount: ₹" +
                                                      item.duration2}{" "}
                                                  <br />
                                                  {item.duration3 &&
                                                    "15 Min: Amount: ₹" +
                                                      item.duration3}{" "}
                                                  <br />
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )
                                    );
                                  } else {
                                    return (
                                      day?.weekDays !== "" &&
                                      day?.data?.length > 0 &&
                                      day?.data[0]?.startTime !== "" &&
                                      day?.data[0]?.amount !== "" &&
                                      day?.data[0]?.amount !== false &&
                                      day?.data[0]?.amount !== "0" && (
                                        <div
                                          key={dayIndex}
                                          className="col-md-6"
                                        >
                                          <div
                                            className="p-3 mb-3"
                                            style={{
                                              backgroundColor: "#e8e8e8",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            <div className="d-flex align-items-center justify-content-between">
                                              <div
                                                className="fw-600"
                                                style={{ fontSize: "16px" }}
                                              >
                                                {day.weekDays}
                                              </div>
                                              <div>
                                                <img
                                                  className="cursor-pointer ms-2"
                                                  src="/images/deleteicon.svg"
                                                  title="Delete Slot"
                                                  alt="icon"
                                                  onClick={() =>
                                                    handleDeleteSelectedData(
                                                      index,
                                                      dayIndex
                                                    )
                                                  }
                                                  height="22px"
                                                />
                                              </div>
                                            </div>
                                            {day?.data?.map((item, idx) => (
                                              <div key={idx} className="mt-2">
                                                <div>
                                                  Time:{" "}
                                                  {convertTo12HourFormat(
                                                    item.startTime
                                                  )}{" "}
                                                  to{" "}
                                                  {convertTo12HourFormat(
                                                    item.endTime
                                                  )}{" "}
                                                  <br />
                                                  Amount: ₹{item.amount}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      )
                                    );
                                  }
                                })}
                              </div>
                            </div>
                          </div>
                          {formValuesSportsFacilities[0]?.Sports[0]?.label ===
                            "Auditorium" && (
                            <>
                              <div className="row mt-3 parent">
                                <div className="col-12">
                                  <span className="venueText">
                                    Rehearsal Price
                                  </span>
                                  &nbsp;
                                  <small>
                                    (Enter the price if you provide the
                                    auditorium for rehearsals from Monday to
                                    Thursday)
                                  </small>
                                </div>
                                <div className="col-12 mt-2">
                                  <input
                                    type="number"
                                    inputMode="numeric"
                                    name="rehearsalprice"
                                    value={
                                      formValuesSportsFacilities[index]
                                        ?.rehearsalprice
                                    }
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                    className="form-control"
                                    placeholder="Per hour price(optional)"
                                  />
                                  <span className="form-text invalid-feedback">
                                    * This field is Required!
                                  </span>
                                </div>
                              </div>
                              <div className="row mt-3 parent">
                                <div className="col-12">
                                  <span className="venueText">
                                    Extra Hour Charge
                                  </span>{" "}
                                  <small>
                                    (Enter the price if you charge extra amount
                                    after booking on hourly basis)
                                  </small>
                                </div>
                                <div className="col-12">
                                  <input
                                    type="number"
                                    inputMode="numeric"
                                    name="extrahourprice"
                                    value={
                                      formValuesSportsFacilities[index]
                                        ?.extrahourprice
                                    }
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                    className="form-control"
                                    placeholder="Extra per hour charge(optional)"
                                  />
                                  <span className="form-text invalid-feedback">
                                    * This field is Required!
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                <Popup
                  show={show}
                  onHide={() => {
                    setShow(false);
                    setselectedIndex("");
                  }}
                  venueflag={1}
                >
                  <WeekDays
                    WeekDayData={
                      formValuesSportsFacilities[selectedIndex]?.weekDay
                    }
                    required={required}
                    setRequired={setRequired}
                    data={formValuesSportsFacilities[selectedIndex]?.Sports}
                    weekdaytype={weekdaytype}
                    state={state}
                    isSecondTime={isSecondTime}
                    setIsSecondTime={setIsSecondTime}
                    dispatch={setFormValues}
                    index={selectedIndex}
                    setShow={setShow}
                    ShowEnterData={
                      formValuesSportsFacilities[selectedIndex]?.weekDay
                    }
                    setselectedIndex={setselectedIndex}
                    selectedIndex={selectedIndex}
                    setDaysToShow={setDaysToShow}
                    daysToShow={daysToShow}
                    days={days}
                    setDays={setDays}
                    setAllDaysChecked={setAllDaysChecked}
                  />
                </Popup>
              </div>
            </div>
          </div>
          <div className="row mt-4 d-flex justify-content-center">
            <div className="col-md-4 col-4">
              <button onClick={prevStep} className="btn btn-gray">
                Previous
              </button>
            </div>
            <div className="col-md-3 col-4">
              <button onClick={nextStep} className="btn btn-danger">
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showVenueListingMsg}
        onHide={() => setShowVenueListingMsg(false)}
      >
        <Modal.Body>
          <div className="text-center">
            <img src="/images/correct2.svg" className="text-center" alt="" />

            <h4 className="mt-3">Congratulations!</h4>
            <p className="paymentText">
              Your Venue listing is submitted successfully, we will review
              details and verify your venue soon. Once verified, your venue will
              be available for online booking.
              <br /> Please select available
              <b> amenities</b> at your venue in next step.
            </p>
            {/* <p className="paymentText">
              Proceed to <span className="fw-bold">amenities</span> selection
            </p> */}
            <button
              className="statusButtons w-75"
              style={{ backgroundColor: "#339900" }}
              onClick={() => setcurrentStep(3)}
            >
              OK
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
