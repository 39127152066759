import "../css/common.css";
import { useState, useEffect } from "react";
import { Navigationbar } from "./navigation";
import { Footer } from "./Footer";
import { DashboardHome } from "./homepage";
import Loader from "./Utils/Loader";
import NewHomePage from "./Homepage/NewHomePage";

export function Dashboard() {
  const userType = window.localStorage.getItem("UserType");
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    Data();
    return () => {
      setState({});
    };
  }, []);
  const Data = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  return (
    <>
      {/* <DashboardHome /> */}
      <NewHomePage />
    </>
  );
}
