// import { useField } from "@mui/x-date-pickers/internals";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "./Utils/APIConnector";
import { venueSportsData } from "./Utils/common";
import { Card, Modal, Badge } from "react-bootstrap";
import _ from "lodash";
import Loader from "./Utils/Loader";
import Swal from "sweetalert2";
import { isValid } from "./constants";

export default function ShowListAdmin() {
  const navigate = useNavigate();
  const params = useParams;
  const userID = localStorage.getItem("userid");
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [showSearchList, setShowsearchList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const SearchRef = useRef(null);
  const FilterRef = useRef(null);
  const [filterSelect, setFilterSelect] = useState({
    venueName: "",
    status: "0",
  });
  const [venuesCreated, setVenuesCreated] = useState({
    userdetails: {},
    venues: [],
  });

  const UserType = localStorage.getItem("UserType");
  useEffect(() => {
    if (UserType != "Admin") {
      window.location.href = "/home";
    }
  }, [UserType]);
  const handleSwitch = (id, IsAcrive, index) => {
    SetIsLoading(true);
    var raw = JSON.stringify({
      venueid: id,
      IsAcrive: !IsAcrive,
    });
    let arrayData = [...filterData.venues];
    arrayData[index].isAcrive = !arrayData[index].isAcrive;

    CustomPostAPI("/Venue/Editvenue", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // window.location.reload();
        SetIsLoading(false);
      }
    });
  };

  const handleSwitchStatus = (event, id, IsAcrive, index) => {
    event.stopPropagation();
    handleSwitch(id, IsAcrive, index);
  };

  const [showVenueList, setShowVenueList] = useState({ venues: [] });
  // const [showApprovedVenues, setShowApprovedVenues] = useState({ venues: [] });
  const [filterData, setFilterData] = useState({
    venues: [],
  });

  const verifyvenue = (e, id) => {
    // console.log(id);
    e.stopPropagation();
    Swal.fire({
      title: "Enter Phone Number and Email",
      html:
        '<input id="swal-input1" class="swal2-input" placeholder="Phone Number" type="number">' +
        '<div class="parent d-none" id="parent">' +
        '<small id="phone-error" class="error-message text-danger text-start ">*Required</small>' +
        "</div>" +
        '<input id="swal-input2" class="swal2-input" placeholder="Email" type="email">' +
        '<div class="swal2-radio-container mt-3">' +
        '<input type="radio" id="option1" name="option" value="4">' +
        '<label for="option1">&nbsp;Online Booking&nbsp &nbsp; &nbsp</label>' +
        '<input type="radio" id="option2" name="option" value="5">' +
        '<label for="option2">&nbsp;Offline Booking&nbsp &nbsp; &nbsp </label>' +
        '<input type="radio" id="option3" name="option" value="6">' +
        '<label for="option3">&nbsp;Info&nbsp;</label>' +
        "</div>",
      focusConfirm: false,
      preConfirm: () => {
        const phoneNumber = document.getElementById("swal-input1").value;
        if (!isValid(phoneNumber)) {
          document.getElementById("parent").classList.remove("d-none");
          return false; // Prevent form submission
        } else {
          document.getElementById("parent").classList.add("d-none");
        }
        return [
          phoneNumber,
          document.getElementById("swal-input2").value,
          document.querySelector('input[name="option"]:checked').value,
        ];
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const phoneNumber = result.value[0];
        const email = result.value[1];
        const selectedOption = result.value[2];

        const raw = JSON.stringify({
          VenueId: id,
          PhoneNumber: phoneNumber,
          Email: email,
          IsAcrive: selectedOption,
        });
        CustomPostAPI("/Auth/VenueownerUpdate", raw).then((result) => {
          // console.log(result);
        });
      }
    });
  };

  const [isLoading, SetIsLoading] = useState(true);
  const [selectedDate, setselectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const delayedSearch = useCallback(
    _.debounce(async (val) => {
      if (val !== "") {
        try {
          await CustomGetAPI("/Venue/GetCitiesByName/" + val).then((result) => {
            if (result == undefined || result == "") {
              alert("Something went wrong");
            } else {
              result = JSON.parse(result);
              setShowsearchList(true);
              setSearchData(result?.cities);
            }
          });
        } catch (error) {
          // console.log(error);
        } finally {
          // setLoader(false)
        }
      } else {
        setShowsearchList(false);
      }
    }, 500),
    []
  );

  const fetchData = async (raw) => {
    SetIsLoading(true);
    return await CustomPostAPI("/Venue/GetAdminVenues", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        // const approvedVenues = result?.venues.filter(
        //   (venue) => venue?.isAcrive.toString() === "0"
        // );
        SetIsLoading(false);
        setShowVenueList({
          venues: result?.venues,
        });
        setFilterData({
          venues: result?.venues,
        });
        return result;
      }
    });
  };

  const handleSearchChange = (e) => {
    // SetIsLoading(true);
    const value = e.target.value;
    delayedSearch(value);
    setSearchText(value);
    if (value == "") {
      const raw = JSON.stringify({
        Date: selectedDate,
      });
      setSearchText("");
      localStorage.removeItem("selectedCityId");
      localStorage.removeItem("selectedCityName");
      fetchData(raw);
    }
  };

  const handleCitySelection = async (cityName) => {
    SetIsLoading(true);
    const selectedCity = searchData?.find((city) => city?.name === cityName);
    setSearchText(cityName);
    localStorage.setItem("selectedCityName", cityName);
    setShowsearchList(false);
    if (!selectedCity) {
      alert("City not found");
      return;
    }
    const cityId = selectedCity.id;
    localStorage.setItem("selectedCityId", cityId);
    const raw = JSON.stringify({
      CityId: cityId,
      Date: selectedDate,
    });
    fetchData(raw);
  };

  const handleVenueListing = (id) => {
    navigate("/admin-venue-details", { state: { id: id } });
  };

  const toggleFilters = () => {
    setShowFilter(!showFilter);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterSelect((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const filterVenues = (filterOptions) => {
    let filteredVenues = showVenueList?.venues;
    if (filterOptions?.venueName) {
      filteredVenues = filteredVenues.filter((venue) =>
        venue?.name
          .toLowerCase()
          .includes(filterOptions?.venueName.toLowerCase())
      );
    }
    if (filterOptions?.status !== "0") {
      filteredVenues = filteredVenues.filter(
        (venue) => venue?.isAcrive.toString() === filterOptions?.status
      );
    }
    setFilterData({ venues: filteredVenues });
    localStorage.setItem("filterSelect", JSON.stringify(filterOptions));
    setShowFilter(false);
  };

  useEffect(() => {
    const storedFilterSelect = JSON.parse(localStorage.getItem("filterSelect"));
    if (storedFilterSelect) {
      setFilterSelect(storedFilterSelect);
      if (showVenueList?.venues) {
        filterVenues(storedFilterSelect);
      }
    }
  }, [showVenueList]);

  const resetVenues = () => {
    setFilterSelect({
      venueName: "",
      status: "0",
    });
    localStorage.removeItem("filterSelect");
    const approvedVenues = showVenueList?.venues.filter(
      (venue) => venue?.isAcrive.toString() === "1"
    );
    setFilterData({ venues: approvedVenues });
    setShowFilter(false);
  };

  useEffect(() => {
    const selectedCityId = localStorage.getItem("selectedCityId");
    const selectedCityName = localStorage.getItem("selectedCityName");
    if (selectedCityId) {
      const raw = JSON.stringify({
        Date: selectedDate,
        CityId: selectedCityId,
      });
      fetchData(raw);
      setSearchText(selectedCityName);
    } else {
      const raw = JSON.stringify({
        Date: selectedDate,
      });
      fetchData(raw);
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(ref) {
      return function (event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowFilter(false);
          setShowsearchList(false);
        }
      };
    }
    const handleFilterClick = handleClickOutside(FilterRef);
    const handleSeacrhClick = handleClickOutside(SearchRef);

    document.addEventListener("mousedown", handleFilterClick);
    document.addEventListener("mousedown", handleSeacrhClick);

    return () => {
      document.removeEventListener("mousedown", handleFilterClick);
      document.removeEventListener("mousedown", handleSeacrhClick);
    };
  }, [FilterRef, SearchRef]);

  return (
    <React.Fragment>
      <div className="row px-2">
        <div className="col-12">
          <img
            src="/images/backArrow.svg"
            onClick={() => navigate("/home")}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      <div className="Container mt-3">
        {/* <div className="Container mt-3" style={{ height: "75dvh" }}> */}
        <div className="row position-relative mt-2">
          <div className="col-8 position-relative">
            <input
              value={searchText}
              onChange={(e) => {
                handleSearchChange(e);
              }}
              type="text"
              className="form-control"
              placeholder="Search by City"
              style={{ position: "relative", paddingLeft: "30px" }}
            />
            {showSearchList && searchData?.length > 0 && (
              <>
                <div
                  className="results-list"
                  style={{
                    zIndex: "9999",
                    position: "absolute",
                    width: "calc(93%)",
                  }}
                  ref={SearchRef}
                >
                  {searchData.map((result) => (
                    <div
                      className="search-result"
                      onClick={() => handleCitySelection(result?.name)}
                      key={result?.id}
                    >
                      {result?.name}
                    </div>
                  ))}
                </div>
              </>
            )}
            <img
              src="/images/Location(1).svg"
              alt="location"
              className="locationSearch"
            />
          </div>
          <div className="col-4">
            <button
              className="venueListingTop w-100"
              style={{ padding: "6px" }}
              onClick={toggleFilters}
            >
              <img className="mx-1" src="/images/sorting.svg" alt="" />
              Filters
            </button>
          </div>
          {showFilter && (
            <>
              <div
                className="mt-3 mb-3 position-absolute"
                id="filters"
                ref={FilterRef}
              >
                <div className="filtersOpen p-3 mx-3">
                  <div className="">
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="venueText">Name</span>
                      </div>
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          name="venueName"
                          placeholder="Venue Name"
                          autoComplete="off"
                          value={filterSelect?.venueName}
                          style={{ paddingLeft: "10px" }}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row mt-2 d-flex justify-content-center align-items-center">
                      <div className="col-4">
                        <span className="venueText">Status</span>
                      </div>
                      <div className="col-8">
                        <select
                          className="custom-select"
                          name="status"
                          aria-label="Select Status"
                          value={filterSelect?.status}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="0">All</option>
                          <option value="1">Approved</option>
                          <option value="2">Pending</option>
                          <option value="3">Rejected</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6">
                        <button
                          className="filterButtons px-1 w-100"
                          style={{ background: "#3C3C40" }}
                          onClick={() => filterVenues(filterSelect)}
                        >
                          Apply Filters
                        </button>
                      </div>

                      <div className="col-6">
                        <button
                          className="filterButtons w-100 h-100"
                          style={{ background: "#a51e22" }}
                          onClick={() => resetVenues()}
                        >
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {filterData?.venues.length != 0 ? (
                filterData?.venues?.map((venue, index) => (
                  <Card
                    className="my-4"
                    style={{
                      borderRadius: "16px",
                      boxShadow: "0px 0px 5px 0px #80808094",
                      // pointerEvents: !venue.isAvailable ? "none" : "auto",
                      // opacity: !venue.isAvailable ? 0.6 : 1,
                    }}
                    onClick={() => handleVenueListing(venue?.id)}
                  >
                    <Card.Body className="py-0 px-0">
                      <div className="venueImages position-relative">
                        <div className="row" style={{ padding: "0 12px" }}>
                          {venue?.venueImages?.map((data, i) => {
                            if (i === 0) {
                              return (
                                <>
                                  <div className="col-12 px-0" key={i}>
                                    <img
                                      src={data}
                                      className="venuePhotos"
                                      alt="image"
                                    />
                                  </div>
                                </>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>

                      <div className="row mx-0 mt-2">
                        <div className="col-md-8 col-7">
                          <div className="row">
                            <div className="col-12 mt-3">
                              <h5 className="">{venue?.name}</h5>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <p className="text-muted">{venue?.address}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-5">
                          <div className="row mt-3">
                            <span>Sports available</span>
                          </div>
                          <div className="row my-2 mx-0">
                            {venue?.venueSports?.map((data, i) => {
                              return (
                                <>
                                  {(i == 0 || i == 1) && (
                                    <div className="col-md-2 col-2 ">
                                      <img
                                        src={`/images/${venueSportsData(
                                          data?.name
                                        )}`}
                                        className="sportsAmenities"
                                        alt="image"
                                        title={data?.name}
                                      />
                                    </div>
                                  )}
                                </>
                              );
                            })}
                            {venue?.venueSports?.length > 2 && (
                              <div className="col-md-8 col-8">
                                <span className="venueText">
                                  +{venue?.venueSports?.length - 2} more
                                </span>
                              </div>
                            )}
                            {venue?.venueSports?.length == 0 && (
                              <div className="col-12">
                                <span className="venueText">
                                  No sports available
                                </span>
                              </div>
                            )}
                          </div>

                          <div
                            className="text-end position-absolute"
                            style={{ bottom: "10px", right: "15px" }}
                          >
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheckChecked${index}`}
                                checked={venue?.isAcrive}
                                onClick={(event) => {
                                  handleSwitchStatus(
                                    event,
                                    venue.id,
                                    venue.isAcrive,
                                    index
                                  );
                                }}
                              />
                            </div>
                            <img
                              className="img-fluid"
                              src="/images/Match Status/Approve.svg"
                              onClick={(e) => verifyvenue(e, venue.id)}
                            />
                          </div>

                          {/* <div
                            className="text-end position-absolute"
                            style={{ bottom: "10px", right: "15px" }}
                          >
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheckChecked${index}`}
                                checked={venue?.isAcrive}
                                onClick={(event) => {
                                  handleSwitchStatus(
                                    event,
                                    venue.id,
                                    venue.isAcrive,
                                    index
                                  );
                                }}
                              />
                            </div>
                          </div> */}

                          {/* <div className="row mt-3">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheckChecked${index}`}
                                checked={venue?.isAcrive}
                                onClick={(event) => {
                                  handleSwitchStatus(
                                    event,
                                    venue.id,
                                    venue.isAcrive,
                                    index
                                  );
                                }}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* {!venue?.isAvailable && (
                        <Badge
                          bg="danger"
                          className="position-absolute top-0 end-0 m-2"
                        >
                          Booked
                        </Badge>
                      )} */}
                    </Card.Body>
                  </Card>
                ))
              ) : (
                <div className="citiesbox text-center mt-2 border-0">
                  No Venues available
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
