import React from "react";

export default function GoogleMapLocation({ address }) {
  const handleGetDirections = () => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <>
      <div className="col-2 d-flex justify-content-center aline-item-center">
        <img
          className="img-fluid google-width"
          src="/images/googlemap.svg"
          alt=""
          onClick={handleGetDirections}
        />
      </div>
      {/* <div className="col-6">
         <button className="venueListingTop w-100" onClick={handleGetDirections}>Get Directions</button>
         </div> */}
    </>
  );
}

// import React from 'react'
// import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

// const libraries = ['places'];
// const mapContainerStyle = {
//     width: '100vw',
//     height: '100vh',
// };
// const center = {
//     lat: 7.2905715, // default latitude
//     lng: 80.6337262, // default longitude
// };

// export default function GoogleMapLocation() {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: 'AIzaSyCbeLrFpnymPhxxFgvgWaTVoMOwjX1kZog',
//         libraries,
//     });

//     if (loadError) {
//         return <div>Error loading maps</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading maps</div>;
//     }

//     return (
//         <>
//             <GoogleMap
//                 mapContainerStyle={mapContainerStyle}
//                 zoom={10}
//                 center={center}
//             >
//                 <Marker position={center} />
//             </GoogleMap>
//         </>
//     )
// }
