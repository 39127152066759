import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CustomPostAPI } from "../Utils/APIConnector";
import { Card, CardImg, Badge } from "react-bootstrap";
import { capitalizeWord, venueSportsData } from "../Utils/common";
import Loader from "../Utils/Loader";

export default function Popularplace(props) {
  const navi = useNavigate();
  const userLocation = window.localStorage.getItem("userLocation");
  const [currentCity, setcurrentCity] = useState(userLocation);
  const [venuesData, setVenuesData] = useState([]);
  const [isHovering, setIsHovering] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const redirectURL = window.localStorage.getItem("RedirectURL");

  useEffect(() => {
    if (!props.data || props.data == "0") {
      setcurrentCity("Ahmedabad");
    } else {
      setcurrentCity(props.data.charAt(0).toUpperCase() + props.data.slice(1));
    }

    Getallvenues();
  }, [props.data]);

  function Getallvenues() {
    setIsLoading(true);
    const raw = JSON.stringify({
      UserId: 0,
      cityname: props.data,
      Name: "",
      Sport: "",
      Date: "2024-07-16",
      TimeId: null,
      Sortby: "0",
      areaname: "",
    });
    CustomPostAPI("/Venue/GetAllVenues", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        const venueslice = result.venues.slice(0, 3);
        setVenuesData(venueslice);
        setIsLoading(false);
      }
    });
  }

  const handleVenueListing = (venuename) => {
    window.localStorage.setItem("venueRedirectURL", "/");
    navi(
      `/venue-search/${currentCity.toLowerCase()}/` +
        venuename.toLowerCase().trim().split(" ").join("-"),
      { state: { locationlatestlandingpage: "/" } }
    );
  };

  return (
    <Fragment>
      <div className="pt-5 mx-md-4">
        <div className="d-flex justify-content-center">
          <h2 className="latestlandingpage-popularplace-h4 position-relative col-12 text-center mt-md-0 mt-0">
            Popular venues in {currentCity}
          </h2>
          {venuesData?.length != 0 ? (
            <span
              className="latestlandingpage-popularplace-span pe-2 col-md-3 offset-md-1 text-md-end cursor-pointer d-md-block d-none position-absolute"
              style={{ right: "1%" }}
              onClick={() => {
                window.location.href = `/venue-search/${currentCity.toLocaleLowerCase()}/sports/${"all"}`;
              }}
            >
              All sports venues in {currentCity} &gt;
            </span>
          ) : null}
        </div>
        {isLoading ? (
          <Loader mt="5%" />
        ) : (
          <div className="row d-flex flex-nowrap px-0 w-100 scrollpopularsports">
            {/* ms-md-0 ms-3 px-md-4 */}
            {venuesData?.length != 0 ? (
              venuesData?.map((venue, index) => (
                <div className="col-md-4 col-11 mb-5 px-4 pe-0" key={index}>
                  <Card
                    className="cursor-pointer h-100"
                    style={{
                      borderBottom: "0px, 0px, 0.5px, 0px solid #E1E1E1",
                      borderRadius: "16px",
                      boxShadow: "0px 0px 5px 0px #0000001A",
                      opacity: !venue.isAvailable ? 0.6 : 1,
                      position: "relative",
                      border: "0.5px solid #E1E1E1",
                    }}
                    onClick={() => handleVenueListing(venue?.name)}
                  >
                    {venue?.hasOffers && (
                      <img
                        src="/images/specialoffer.svg"
                        className="img-fluid"
                        alt="Special Offer"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          zIndex: 1,
                          maxWidth: "100px",
                        }}
                      />
                    )}
                    <Card.Body
                      className="py-0 px-0 d-flex flex-column"
                      onMouseEnter={() =>
                        setIsHovering((prev) => {
                          return prev ? prev : index;
                        })
                      }
                      onMouseLeave={() => setIsHovering(null)}
                    >
                      <div className="venueImages position-relative mb-3">
                        <div className="row" style={{ padding: "0 12px" }}>
                          <div className="col-12 px-0">
                            <CardImg
                              className="w-100 latestlandingpage-popularplace-card-img"
                              src={venue?.venueImages[0]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer">
                        <div className="row mx-0 mt-1">
                          <div className="col-md-6 col-6">
                            <div className="row">
                              <div className="col-12">
                                <h3
                                  className="Montserrat fw-semibold m-0"
                                  style={{
                                    color: "black",
                                    fontSize: "16px",
                                  }}
                                >
                                  {venue?.name}
                                </h3>
                              </div>
                              <div className="col-12">
                                <p
                                  className="Montserrat"
                                  style={{
                                    color: "#000000f0",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {venue?.address}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-6 text-end">
                            <div className="text-end">
                              {venue?.venueSports?.map((data, i) => (
                                <>
                                  {i < 2 && (
                                    <div className="sportimage ms-2">
                                      <img
                                        src={`/images/${venueSportsData(
                                          data?.name
                                        )}`}
                                        className="img-fluid"
                                        alt="image"
                                        title={data?.name}
                                        style={{
                                          borderRadius: "11px",
                                          padding: "3px 3px",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      />
                                    </div>
                                  )}
                                  {i === 2 &&
                                    venue?.venueSports?.length > 2 && (
                                      <div
                                        className="mx-lg-1 mx-1 Montserrat"
                                        style={{ fontSize: "14px" }}
                                      >
                                        + {venue?.venueSports?.length - 2} more
                                      </div>
                                    )}
                                </>
                              ))}

                              {venue?.venueSports?.length === 0 && (
                                <span className="venueText">
                                  No sports available
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center mx-0 mb-3">
                          <div className="col-6">
                            <div className="venuePriceDetails">
                              <span className="text-black fw-bold">
                                ₹{venue?.averagePrice}
                              </span>
                              <span className="">&nbsp;onwards</span>
                            </div>
                          </div>
                          {(venue?.isAcrive === 4 ||
                            venue?.isAcrive === 5 ||
                            venue?.isAcrive === 6) && (
                            <div className="col-md-6 col-6 text-end h-100">
                              <button className="venueListingBooknow">
                                {venue?.isAcrive === 4
                                  ? "Book Now"
                                  : venue?.isAcrive === 5
                                  ? "Book Now"
                                  : venue?.isAcrive === 6
                                  ? "Inquire Now"
                                  : null}
                                <img
                                  src="/images/white-arrow.svg"
                                  alt="arrow"
                                  className="mx-1"
                                />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {!venue?.isAvailable && (
                        <Badge
                          bg="danger"
                          className="position-absolute top-0 end-0 m-2"
                        >
                          Booked
                        </Badge>
                      )}
                      {(venue?.isAcrive === 4 || venue?.isAcrive === 5) && (
                        <Badge
                          bg="success"
                          className="position-absolute left-0 top-0 m-2"
                        >
                          Verified
                        </Badge>
                      )}
                    </Card.Body>
                  </Card>
                </div>
              ))
            ) : (
              <div className="citiesbox text-center border-0 mb-5">
                We couldn't find any venues available in your city right now.
                Please try again later.
              </div>
            )}
          </div>
        )}
        {venuesData?.length != 0 ? (
          <span
            className="latestlandingpage-popularplace-span mt-0 col-12 text-start cursor-pointer d-md-none d-block ms-md-0 ms-4"
            onClick={() => {
              window.location.href = `/venue-search/${currentCity.toLocaleLowerCase()}/sports/${"all"}`;
            }}
          >
            All sports venues in {currentCity} &gt;
          </span>
        ) : null}
      </div>
    </Fragment>
  );
}
