import React from "react";
import FAQCOMP from "./FAQCOMP";
import HelmetMetaTag from "../Utils/HelmetMetaTag/HelmetMetaTag";
import { useNavigate } from "react-router-dom";
export default function Faqpage() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="CricBuddy | Frequently Asked Questions"
        canonical={window.location.href}
        ogtitle="CricBuddy | Frequently Asked Questions"
        ogtype="website"
        ogurl={window.location.href}
      />
      <div className="row px-2">
        <div className="col-12">
          <img
            className="cursorClass"
            src="/images/backArrow.svg"
            onClick={(e) => navigate(-1)}
          />
        </div>
      </div>
      <FAQCOMP />
    </React.Fragment>
  );
}
