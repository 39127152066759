import * as React from "react";
import "../../css/common.css";
import { Card } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "../Utils/Loader";
import { MatchDetails } from "../MatchDetails";
import {
  ConvertDateTime,
  DBConvertDatetime,
  datetimeValues,
} from "../constants";
import LiveScorepage from "../LiveScorepage";
import { GetAPI } from "../Utils/APIConnector";
import { useNavigate } from "react-router-dom";
export function CreatedMatches() {
  const [CreatedMatches, setCreatedMatches] = useState([]);
  const navigate = useNavigate();

  var userid = window.localStorage.getItem("userid");
  if (userid === null || userid == undefined) {
    userid = 0;
  }
  const [isLoading, SetIsLoading] = useState(true);
  const [showMatchDetails, SetShowMatchDetails] = useState(false);
  const [detailMatch, SetDetailMatch] = useState([]);

  var matches = [];
  useEffect(async () => {
    var UserID = window.localStorage.getItem("userid");
    const userType = window.localStorage.getItem("UserType");
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    if (isNaN(UserID) || UserID === null) {
      window.location = "/login";
    }
    await GetAPI("/Matches/MyCreatedMatches/" + userid).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.details;
        for (var i = 0; i < matchList?.length; i++) {
          matches.push(matchList[i]);
        }
        setCreatedMatches(matches.reverse());
        SetIsLoading(false);
      }
    });
  }, []);

  const matchDetails = (matchID) => {
    window.localStorage.setItem("MatchDetailID", matchID);
    window.localStorage.setItem("RedirectURL", window.location.href);
    window.location = "/match-details";
  };
  // const LiveMatchDetails = (matchID) => {
  //   window.localStorage.setItem("LiveMatchDetailID", matchID);
  //   window.location = "/LiveScorepage";
  // };
  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          // showMatchDetails ?
          //     <MatchDetails matchData={detailMatch} returnURL={window.location.href} />
          //     :
          <>
            <div className="row px-2">
              <div className="col-12">
                <img
                  className="cursorClass"
                  src="/images/backArrow.svg"
                  onClick={(e) => navigate("/home")}
                />
              </div>
            </div>
            <div className="Container mb-3">
              <Row className="mx-2">
                <div className="p-2 text-center">
                  <label>
                    <h4>
                      <b>My MATCHES</b>
                    </h4>
                  </label>
                </div>
                {CreatedMatches.length === 0 ? (
                  <Card className="mt-3 matchListItem p-0 text-center">
                    <Card.Body className="p-0">
                      <div className="">
                        <div className="profileformControl small">
                          <b>No records found.</b>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ) : (
                  CreatedMatches.map((str, index) => {
                    var returData;
                    returData = (
                      <Card
                        key={index}
                        className="mt-3 matchListItem p-0"
                        data-item={str.matchesPackage.id}
                        data-index={index}
                      >
                        <Card.Header style={{ backgroundColor: "#E8E8E8" }}>
                          <div
                            className="text-center "
                            onClick={() => matchDetails(str.matchesPackage.id)}
                          >
                            {str.matchesPackage.sportTypeName == "Tennis" ? (
                              <>
                                <div className="row">
                                  <div className="col-10">
                                    <b className="font1 underLine">{`${str.matchesPackage.overs} Over Tennis match `}</b>
                                  </div>
                                  <div className="col-2 px-3">
                                    <img
                                      className="w-100"
                                      src="./images/TennisBall.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row">
                                  <div className="col-10">
                                    <b className="font1 underLine">{`${str.matchesPackage.overs} Over Season match `}</b>
                                  </div>
                                  <div className="col-2 px-3">
                                    <img
                                      className="w-100 "
                                      src="./images/SeasonBall.svg"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Body className="profileformControl">
                          <div className="row text-left my-2">
                            <div className="col-12">
                              <img src="./images/groundIcon.svg" alt="" />{" "}
                              <label className="groundName">
                                {str.matchesPackage?.groundName != null
                                  ? `${str.matchesPackage.groundName} Cricket Ground`
                                  : "Cricket Ground"}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-8 text-left mt-2">
                              <img src="./images/DateandTime.svg" alt="" />{" "}
                              <label className="dataText">
                                {DBConvertDatetime(
                                  str.matchesPackage.dateAndTime
                                )}
                              </label>
                            </div>
                            <div className="col-4 text-left mt-2">
                              <label className="form-label textLabel">
                                Fees :{" "}
                              </label>{" "}
                              <label className="dataText">
                                ₹ {str.matchesPackage.entryFees}
                              </label>
                            </div>
                          </div>
                          {/* <div
                            className={`row my-2 ${datetimeValues(
                              str.matchesPackage.dateAndTime
                            )}`}
                            id="showBtn"
                          >
                            <div className="col-12 centerAlign">
                              <button
                                className="loginbtn"
                                onClick={() => {
                                  window.localStorage.setItem(
                                    "LiveMatchDetailID",
                                    str.matchesPackage.id
                                  );
                                  window.location = "/LiveScorepage";
                                }}
                              >
                                Start Match
                              </button>
                            </div>
                            <div className="col-12 mt-2 centerAlign">
                              <button
                                className="loginbtn"
                                onClick={() => {
                                  window.localStorage.setItem(
                                    "LiveMatchDetailID",
                                    str.matchesPackage.id
                                  );
                                  window.localStorage.setItem(
                                    "ResumeMatch",
                                    "true"
                                  );
                                  window.location = "/LiveScorepage";
                                }}
                              >
                                Resume Match
                              </button>
                            </div>
                          </div> */}
                          <div
                            className={`row my-2 ${datetimeValues(
                              str.matchesPackage.dateAndTime
                            )}`}
                            id="showBtn"
                          >
                            <div className="col-12 centerAlign">
                              {str.matchesPackage.isMatchComplete === 0 ? (
                                <button
                                  className="loginbtn"
                                  onClick={() => {
                                    window.localStorage.setItem(
                                      "MatchDetailID",
                                      str.matchesPackage.id
                                    );
                                    window.localStorage.setItem(
                                      "RedirectURL",
                                      window.location.href
                                    );
                                    window.location = "/start-match";
                                  }}
                                >
                                  Start Match
                                </button>
                              ) : str.matchesPackage.isMatchComplete === 1 ? (
                                <b className="m-0" style={{ color: "#a51e22" }}>
                                  Match Completed
                                </b>
                              ) : (
                                <b className="m-0" style={{ color: "#a51e22" }}>
                                  Match cancelled
                                </b>
                              )}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                    return returData;
                  })
                )}
              </Row>
            </div>
          </>
        )}
      </div>
    </>
  );
}
