const phone = window.localStorage.getItem("UserMobileNo");
const email = window.localStorage.getItem("UserEmail");
export const INITIAL_STATE = {
  VenueName: "",
  category:"sports",
  Address1: "",
  Address2: "",
  Address3: "",
  cityid: "",
  stateid: "",
  ZipCode: "",
  Phone: phone ? phone : "",
  Email: "",
  DescText: "",
  // Phone: [],
  // Email: [],
  StartTime: "00:00",
  EndTime: "00:00",
  MinSlotTime: "60",
  Cancelpolicy: "",
  RescheduleHours: "",
  RescheduleCount: "",
  // Sports: [],
  venueimage: [],
  SportsFacilities: [
    {
      SurfaceType: "",
      CourtCount: "1",
      MaxCapacity: "",
      Sports: "0",
      MonFri: [{ startTime: "", endTime: "", amount: "" }],
      SatSun: [{ startTime: "", endTime: "", amount: "" }],
    },
  ],
  VenueAmenities: [],
  errors: {},
};
export const Default_STATE = {
  VenueName: "",
  category:"sports",
  Address1: "",
  Address2: "",
  Address3: "",
  cityid: "",
  stateid: "",
  ZipCode: "",
  Phone: phone ? phone : "",
  Email: "",
  DescText: "",
  // Phone: [],
  // Email: [],
  StartTime: "00:00",
  EndTime: "00:00",
  MinSlotTime: "60",
  Cancelpolicy: "",
  RescheduleHours: "",
  RescheduleCount: "",
  // Sports: [],
  venueimage: [],
  SportsFacilities: [
    {
      SurfaceType: "",
      CourtCount: "1",
      MaxCapacity: "",
      Sports: "0",
      MonFri: [{ startTime: "", endTime: "", amount: "" }],
      SatSun: [{ startTime: "", endTime: "", amount: "" }],
    },
  ],
  VenueAmenities: [],
  errors: {},
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_INPUT":
    case "CHANGE_ARRAY":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
        errors: {
          ...state.errors,
          [action.payload.name]: "",
        },
      };
    case "SET_ERROR":
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: action.payload.message,
        },
      };
    case "RESET_STATE":
      return Default_STATE;
    default:
      return state;
  }
};
