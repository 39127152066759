import React from 'react'
import { useState, useEffect } from 'react';
import displayRazorpay from './Utils/PaymentGateway'
import { RefundPayment } from './Utils/PaymentGateway';

export function PaymentPage() {

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    });


    // async function displayRazorPay() {

    //     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    //     if (!res) {
    //         alert('RazorPay failed to load');
    //         return;
    //     }

    //     const options = {
    //         "key": "rzp_test_hjXuS58iSCh5AH", // Enter the Key ID generated from the Dashboard
    //         "amount": "10000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //         "currency": "INR",
    //         "name": "CricBuddy Registration",
    //         "description": "Test Transaction",
    //         "image": "https://example.com/your_logo",
    //         "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //         "handler": function (response) {
    //             alert(response.razorpay_payment_id);
    //             alert(response.razorpay_order_id);
    //             alert(response.razorpay_signature)
    //         },
    //         "prefill": {
    //             "name": "Cricket App Test",
    //         },
    //         "theme": {
    //             "color": "#C06D05"
    //         }
    //     };

    //     const rzp1 = new Razorpay(options);
    //     rzp1.on('payment.failed', function (response) {
    //         alert(response.error.code);
    //         alert(response.error.description);
    //         alert(response.error.source);
    //         alert(response.error.step);
    //         alert(response.error.reason);
    //         alert(response.error.metadata.order_id);
    //         alert(response.error.metadata.payment_id);
    //     });
    //     rzp1.open();

    // }

    return (
        <>
            <div className="container mt-5 p-3">
                <div className="row mt-5">
                    <div className="col-12">
                        <input type="text" className="form-control " placeholder="Price" />
                    </div>
                </div>
                <div className="col-12">
                    <button type="submit" id="userRegister" className="mt-2 col-6 loginbtn" onClick={displayRazorpay} >Pay </button>
                </div>
            </div>
            <hr/>
            <div className='container mt-5 p-3'>
                <h3>Successful Payments</h3>

                <div className=''>
                    Payment Id - pay_JCO9G2uJvdH5md | Order Id - order_JCO4LLzpjpvvuv | Amount - 100 Rs
                </div>
                <div className='col-12'>
                    <button type='submit' className='mt-2 col-6 loginbtn' onClick={RefundPayment} >Refund</button>
                </div>
            </div>
        </>
    )
}