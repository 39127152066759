import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Card } from "react-bootstrap";

export default function SavedQueries() {
    const navigate = useNavigate();

    const goToVenueSearch = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="savedVenueContainer position-relative">
                <div className="row px-2">
                    <div className="col-12">
                        <img
                            className=''
                            src="/images/arrow1.svg"
                            style={{ cursor: 'pointer',position:'absolute' }}
                            onClick={goToVenueSearch}
                        />
                        <div className="text-center">
                            <span>
                                <h4 className="text-center">
                                    <b>Saved Queries</b>
                                </h4>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="queriesCard">
                    <Card className='mt-2' style={{ borderRadius: '16px', background: 'white', border: 'none', boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)' }} >
                        <Card.Body>
                            <div className="row">
                                <div className="col-8">
                                    <div className='savedVenueText'>Crico 6-1</div>
                                    <div className='venueText mt-3'>Morning availability within 5km</div>
                                </div>
                                <div className="col-4">
                                    <img className='mt-3' src="/images/delete2.svg" alt="" style={{ float: 'right' }} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}
