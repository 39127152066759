import React from "react";

const Loader = (props) => {
  const marginTopValue = props.mt ? props.mt : "30%";
  return (
    <>
      <div className="loader-view" style={{ marginTop: marginTopValue }}>
        <img
          height="50px"
          width="50px"
          src="/images/loder.svg"
          style={{ marginLeft: "45%" }}
        />
      </div>
    </>
  );
};

export default Loader;
