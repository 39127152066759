import Cookies from "universal-cookie";
import { isValid } from "../constants";
const cookies = new Cookies();
const authToken = cookies.get("AuthToken");
const BaseUrl = process.env.REACT_APP_API_BASE_URL;

export function PostAPI(endpoint, payload) {
  if (isValid(authToken)) {
    cookies.set("AuthToken", authToken, { path: "/home", maxAge: 3600 });

    const token = "Bearer " + authToken;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    var urlencoded = payload;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      ...(payload !== null && { body: urlencoded }),
      redirect: "follow",
    };

    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.includes('"status":401')) {
          window.location.href = "/login";
        }
        return result;
      })
      .catch((error) => {
        return error;
      });
  } else {
    window.location.href = "/login";
  }
}
export function CustomPostAPI(endpoint, payload = null) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var urlencoded = payload;

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    ...(payload !== null && { body: urlencoded }),
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (result.includes('"status":401')) {
        window.location.href = "/login";
      }
      return result;
    })
    .catch((error) => {
      return error;
    });
}
export function GetAPI(endpoint) {
  if (isValid(authToken)) {
    cookies.set("AuthToken", authToken, { path: "/", maxAge: 3600 });

    const token = "Bearer " + authToken;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.includes('"status":401')) {
          window.location.href = "/login";
        }
        return result;
      })
      .catch((error) => {
        return error;
      });
  } else {
    window.location.href = "/login";
  }
}
export function CustomGetAPI(endpoint) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => {
      if (response.status === 404) {
        // window.location.href = "/home";
      }
      return response.text();
    })
    .then((result) => {
      if (result.includes('"status":401')) {
        window.location.href = "/login";
      }
      return result;
    })
    .catch((error) => {
      return error;
    });
}
export function PutAPI(endpoint, payload) {
  if (isValid(cookies.get("AuthToken"))) {
    cookies.set("AuthToken", cookies.get("AuthToken"), {
      path: "/",
      maxAge: 3600,
    });

    const token = "Bearer " + cookies.get("AuthToken");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    var urlencoded = payload;

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.includes('"status":401')) {
          window.location.href = "/login";
        }
        return result;
      })
      .catch((error) => {
        return error;
      });
  } else {
    window.location.href = "/login";
  }
}
export function CustomPutAPI(endpoint, payload = null) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var urlencoded = payload;

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    ...(urlencoded && { body: urlencoded }),
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (result.includes('"status":401')) {
        window.location.href = "/login";
      }
      return result;
    })
    .catch((error) => {
      return error;
    });
}
export function DeleteAPI(endpoint, payload) {
  if (isValid(authToken)) {
    cookies.set("AuthToken", authToken, { path: "/", maxAge: 3600 });

    const token = "Bearer " + authToken;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    var urlencoded = payload;

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    return fetch(`${BaseUrl}${endpoint}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.includes('"status":401')) {
          window.location.href = "/login";
        }
        return result;
      })
      .catch((error) => {
        return error;
      });
  } else {
    window.location.href = "/login";
  }
}
export function CustomDeleteAPI(endpoint, payload = null) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var urlencoded = payload;

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    ...(urlencoded && { body: urlencoded }),
    redirect: "follow",
  };
  return fetch(`${BaseUrl}${endpoint}`, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      if (result.includes('"status":401')) {
        window.location.href = "/login";
      }
      return result;
    })
    .catch((error) => {
      return error;
    });
}
