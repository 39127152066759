import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { GetStrikeRate, isValid } from "../constants";
import Loader from "../Utils/Loader";
import $ from "jquery";
import { GetAPI, PostAPI } from "../Utils/APIConnector";

export default function AddBatsmen(props) {
  const [batsmenStatistic, setbatsmenStatistic] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);
  const [outplayerDetails, SetoutplayerDetails] = useState([]);

  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  var strikeBatsmen = window.localStorage.getItem("strikerBatsmen");
  var nonStrikeBatsmen = window.localStorage.getItem("nonStrikerBatsmen");

  useEffect(() => {
    setTimeout(async () => {
      await GetAPI(
        "/MatchScoreCard/GetBatsmenStatistics?matchid=" +
          parseInt(matchDetailsID) +
          `&InningType=${props.inningsType}`
      ).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var result = JSON.parse(result);
          if (result.status === "Success") {
            setbatsmenStatistic(result.battsmanStatistics);
          }
        }
      });
      SetIsLoading(false);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
    }, 1000);

    var result = props.totalPlayers.filter(
      (u) => !props.outplayersList.includes(u.id)
    );

    SetoutplayerDetails(result);
  }, [props.currentBall]);

  const close = async () => {
    var playerID = $(".newBatsmen  option:selected").val();

    if (!isValid(playerID)) {
      if (props.outplayersList.length == props.totalPlayers.length - 1) {
        var raw = JSON.stringify({
          MatchId: props.currentBall.MatchID,
          BallNumber: props.currentBall.BallNumber,
          BatsmenID: props.currentBall.BatsmenID,
          BowlerID: props.currentBall.BowlerID,
          Run: props.currentBall.Run,
          ExtraType: props.currentBall.ExtraType,
          OutType: props.currentBall.OutType,
          OutBy: props.currentBall.OutBy,
          TeamNumber: props.currentBall.TeamNumber,
          OutPlayerID: props.currentBall.OutPlayerID,
        });
        await PostAPI("/MatchScoreCard/SaveOver/", raw).then((result) => {
          if (result == undefined || result == "") {
            alert("Something went wrong");
          } else {
            result = JSON.parse(result);
            if (result.status === "Success") {
              props.setplayer1Score({
                ...props.player1Score,
                runs: 0,
                balls: 0,
                fours: 0,
                sixs: 0,
              });
              props.setplayer2Score({
                ...props.player2Score,
                runs: 0,
                balls: 0,
                fours: 0,
                sixs: 0,
              });
              props.onHide(false);
              props.setDynamicValues("inningsType", "SecondInning");
              props.prevStep(1);
            } else {
              alert(result.Message);
            }
          }
        });
      } else {
        $(".validationError").removeClass("d-none");
      }
    } else {
      var playerName = $(".newBatsmen  option:selected").text();
      props.lastBatsmenOut === strikeBatsmen
        ? props.setplayer1Score({
            ...props.player1Score,
            name: playerName,
            runs: 0,
            balls: 0,
            fours: 0,
            sixs: 0,
          })
        : props.setplayer2Score({
            ...props.player2Score,
            name: playerName,
            runs: 0,
            balls: 0,
            fours: 0,
            sixs: 0,
          });
      props.lastBatsmenOut === strikeBatsmen
        ? window.localStorage.setItem("strikerBatsmen", playerID)
        : window.localStorage.setItem("nonStrikerBatsmen", playerID);
      $(".validationError").addClass("d-none");
      props.onHide(false);
      // if (props.scoreCount == 6) {
      //   props.cleanupFunction();
      //   props.onHide();
      // } else {
      //   props.onHide();
      // }
      // props.onHide({ ...props.popupshow, popup1: false });
    }
  };
  return (
    <div>
      <div className="px-2">
        {isLoading ? (
          <Loader />
        ) : batsmenStatistic.length === 0 ? (
          <>
            <div>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>Names</th>
                    <th>R</th>
                    <th>B</th>
                    <th>4s</th>
                    <th>6s</th>
                    <th>SR</th>
                  </tr>
                </thead>
                <tbody className="batsmenOut">
                  <tr
                    style={{
                      opacity:
                        props.lastBatsmenOut === strikeBatsmen ||
                        batsmenStatistic.isOut
                          ? "50%"
                          : "",
                    }}
                  >
                    <td
                      style={{
                        color: "#A51E22",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {props.player1Score.name}
                    </td>
                    <td>{props.player1Score.runs}</td>
                    <td>{props.player1Score.balls}</td>
                    <td>{props.player1Score.fours}</td>
                    <td>{props.player1Score.sixs}</td>
                    <td>
                      {GetStrikeRate(
                        props.player1Score.runs,
                        props.player1Score.balls
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      opacity:
                        props.lastBatsmenOut === nonStrikeBatsmen ? "50%" : "",
                    }}
                  >
                    <td
                      style={{
                        color: "#A51E22",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {props.player2Score.name}
                    </td>
                    <td>{props.player2Score.runs}</td>
                    <td>{props.player2Score.balls}</td>
                    <td>{props.player2Score.fours}</td>
                    <td>{props.player2Score.sixs}</td>
                    <td>
                      {GetStrikeRate(
                        props.player2Score.runs,
                        props.player2Score.balls
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <>
            <div>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>Names</th>
                    <th>R</th>
                    <th>B</th>
                    <th>4s</th>
                    <th>6s</th>
                    <th>SR</th>
                  </tr>
                </thead>
                <tbody>
                  {batsmenStatistic.map((data, i) => {
                    var returnData;
                    returnData = (
                      <>
                        <tr
                          style={{
                            opacity:
                              props.lastBatsmenOut === data.playerID ||
                              data.isOut
                                ? "50%"
                                : "",
                          }}
                        >
                          <td
                            style={{
                              color: "#A51E22",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            {data.batsmenName}
                          </td>
                          <td>{data.runs}</td>
                          <td>{data.balls}</td>
                          <td>{data.fours}</td>
                          <td>{data.sixes}</td>
                          <td>{data.sr.toFixed(2)}</td>
                        </tr>
                      </>
                    );
                    return returnData;
                  })}
                </tbody>
              </Table>
            </div>
          </>
        )}

        {/* <div
          className="row mt-3 centerAlign"
          style={{ color: "#A51E22", fontWeight: "700", fontSize: "15px" }}
        >
          W Saha (WK)
        </div>
        <div
          className="row my-1 centerAlign"
          style={{ fontWeight: "700", fontSize: "15px" }}
        >
          c Shaw b Axar
        </div> */}
        <div className="row px-3 my-3">
          {outplayerDetails.length != 1 ? (
            <>
              <select
                className="form-select OutSelect newBatsmen"
                defaultValue="0"
              >
                <option value="0">Select New Batsmen</option>
                {outplayerDetails.map((data, i) =>
                  strikeBatsmen == data.id ? null : nonStrikeBatsmen ==
                    data.id ? null : (
                    <option key={i} value={data.id}>
                      {data.fullName}
                    </option>
                  )
                )}
              </select>
            </>
          ) : (
            <>
              <div className="">Complete this inning</div>
            </>
          )}
        </div>
        <span
          className="validationError centerAlign d-none  mt-2"
          style={{ color: "red" }}
        >
          Please select new batsmen
        </span>
        <div className="row " style={{ justifyContent: "center" }}>
          <div className="col-3 text-end">
            <button
              className="btn"
              style={{ backgroundColor: "#C4C4C4" }}
              onClick={close}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
