import React from "react";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  ConvertDateTime,
  DBConvertDatetime,
  editIconVisible,
} from "./constants";
import { UserDetails } from "./Utils/PopupModal/UserDetails";
import { GetPlayerRole } from "./constants";
import { useSelector } from "react-redux";
import Popup from "./LiveScoreCardpopups/Popup";
import MatchDetailsEditPage from "./MatchDetailsEditPage";
import { GetAPI } from "./Utils/APIConnector";
import Loader from "./Utils/Loader";

export const MatchDetails = () => {
  // const themeColor = useSelector((state) => state.theme.value);
  const MatchID = parseInt(window.localStorage.getItem("MatchDetailID"));
  const [allMatchesData, setAllMatchesData] = useState([]);
  const [existingplayers, setexistingplayers] = useState();
  const [availableplayers, setavailableplayers] = useState();
  const [EditAllMatchesData, setEditAllMatchesData] = useState([]);
  const [isLoading, SetIsLoading] = useState(true);
  const [userPopUp, SetUserPopUp] = useState(false);
  const [MatchEditPopUp, SetMatchEditPopUp] = useState(false);
  const [userDetails, SetUserDetails] = useState({});
  const [bowlingStyle, SetBowlingStyle] = useState("");
  const [battingStyle, SetBattingStyle] = useState("");
  const [PlayerStats, SetPlayerStats] = useState([]);
  const [value, setValue] = useState("");
  const Imageurl = "https://cricbuddyapi.cricbuddy.in";
  const [filterDatas, setfilterDatas] = useState([]);
  const RedirectURL = window.localStorage.getItem("RedirectURL");
  const userid = window.localStorage.getItem("userid");
  const [UserdetailsID, setUserdetailsID] = useState();

  useEffect(async () => {
    const userType = window.localStorage.getItem("UserType");
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }

    await GetAPI("/Matches/DetailsOfCreatedMatch/" + MatchID).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        var matchList = result.matchDetails;
        setavailableplayers(result.RequiredPlayercount);
        setexistingplayers(result.ExistingPlayercount);
        setAllMatchesData(matchList);
        if (matchList && matchList.length > 0) {
          setEditAllMatchesData(matchList[0]?.matchesPackage);
        }
        if (matchList && matchList.length > 0) {
          setfilterDatas(matchList[0]?.joinMatchPackages);
        }
        SetIsLoading(false);
      }
    });
    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };
    // fetch(
    //   "https://playerstation.azurewebsites.net/api/Matches/DetailsOfCreatedMatch/" +
    //     MatchID,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // window.localStorage.removeItem("MatchDetailID");
    //     var matchList = result.matchDetails;
    //     setAllMatchesData(matchList);
    //     setEditAllMatchesData(matchList[0].matchesPackage);
    //     setfilterDatas(matchList[0].joinMatchPackages);
    //   })
    //   .catch((error) => console.log("error", error));
  }, []);

  const backTopage = () => {
    window.localStorage.removeItem("MatchDetailID");
    window.history.back();
  };

  const showUserDetails = async (detailsUserID) => {
    await GetAPI(
      "/MatchScoreCard/GetPlayersStatistics/?userid=" + parseInt(detailsUserID)
    ).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        if (result.status === "success") {
          SetUserDetails(result.userDetails);
          SetBattingStyle(result.battingstyle);
          SetBowlingStyle(result.bowlingstyleName);
          var player = new Array();
          player.push(result.playerStatistics);
          SetPlayerStats(player);
          SetUserPopUp(true);
        }
      }
    });
  };
  const closePopupHandler = () => {
    SetUserPopUp(false);
  };

  const handleChange = (e) => {
    setValue(e);
    e = parseInt(e);
    var role = allMatchesData[0].joinMatchPackages;
    var roleList = [];
    for (let i = 0; i < role.length; i++) {
      if (e === 0) {
        roleList.push(role[i]);
      } else if (e === 1) {
        if (role[i].playerRoleID == 1) {
          roleList.push(role[i]);
        }
      } else if (e === 2) {
        if (role[i].playerRoleID == 2) {
          roleList.push(role[i]);
        }
      } else if (e === 3) {
        if (role[i].playerRoleID == 3) {
          roleList.push(role[i]);
        }
      } else if (e === 4) {
        if (role[i].playerRoleID == 4) {
          roleList.push(role[i]);
        }
      }
      setfilterDatas(roleList);
    }
  };
  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row px-2">
            <div className="col-12">
              <img
                src="/images/backArrow.svg"
                onClick={() => {
                  window.location = RedirectURL;
                }}
              />
            </div>
          </div>
          <div className="Container mb-3">
            {/* <div className="pt-2">
                    <a onClick={backTopage} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <img src="/images/backArrow.svg" />
                    </a>
                    <h5 className="pt-1 text-center"><b>Match Details</b> </h5>
                </div> */}
            <div className="p-2 text-center">
              <label>
                <h4>
                  <b>Match Details</b>
                </h4>
              </label>
            </div>
            {allMatchesData?.length === 0 ? (
              <Card className="mt-3 matchListItem p-0 text-center">
                <Card.Body className="p-0">
                  <div className="">
                    <div className="profileformControl small">
                      <b>No records found.</b>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              allMatchesData?.map((str, index) => {
                var returData;
                returData = (
                  <Card
                    key={index}
                    className="mt-3 matchListItem p-0"
                    data-item={str.matchesPackage.id}
                    data-index={index}
                  >
                    {/* <Card.Header>
                                        <div className='text-center'>
                                            <a><b>{str.matchesPackage.name} - </b>{str.matchesPackage.sportTypeName}</a>
                                        </div>
                                    </Card.Header> */}
                    <Card.Body className="profileformControl">
                      {!editIconVisible(str.matchesPackage.dateAndTime) &&
                      str.matchesPackage.userId == userid ? (
                        <>
                          {/* <div className="row justify-content-end">
                        <div className="col-1 me-2">
                          <img
                            src="./images/editSvg.svg"
                            alt=""
                            onClick={() => SetMatchEditPopUp(true)}
                          />
                        </div>
                      </div> */}
                        </>
                      ) : (
                        ""
                      )}

                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Match Name :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            {str.matchesPackage.name}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Match Type :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            {str.matchesPackage.sportTypeName}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Ground Name :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            {str.matchesPackage?.groundName != null
                              ? `${str.matchesPackage.groundName} Cricket Ground`
                              : "Cricket Ground"}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Date & Time :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            {DBConvertDatetime(str.matchesPackage.dateAndTime)}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Overs :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            {str.matchesPackage.overs}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Fees :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">
                            ₹ {str.matchesPackage.entryFees}
                          </label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Existing Players :
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">{existingplayers}</label>
                        </div>
                      </div>
                      <div className="row text-left">
                        <div className="col-6">
                          <label className="form-label textLabel">
                            Required Players :{" "}
                          </label>
                        </div>
                        <div className="col-6">
                          <label className="dataText">{availableplayers}</label>
                        </div>
                      </div>
                      {str.matchesPackage.numberOfBatsmen == null &&
                      str.matchesPackage.numberOfBowlers == null &&
                      str.matchesPackage.numberOfAllRounders == null &&
                      str.matchesPackage.numberOfWicketKeepers ==
                        null ? null : (
                        <div className="p-2 text-center">
                          <label>
                            <h4>
                              <b>Players Requirements</b>
                            </h4>
                          </label>
                        </div>
                      )}
                      <div className="centerAlign">
                        {str.matchesPackage.numberOfBatsmen != null ? (
                          <div
                            className="col-3 centerAlign "
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="">
                              <img
                                src="/images/PlayerType/Batsmen.svg"
                                className="img-fluid"
                              />{" "}
                              :{" "}
                              <b className="dataText">
                                {str.matchesPackage.numberOfBatsmen}
                              </b>
                            </div>
                          </div>
                        ) : null}
                        {str.matchesPackage.numberOfBowlers != null ? (
                          <div
                            className="col-3 centerAlign "
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="">
                              <img
                                src="/images/PlayerType/Bowler.svg"
                                className="img-fluid"
                              />{" "}
                              :{" "}
                              <b className="dataText">
                                {str.matchesPackage.numberOfBowlers}
                              </b>
                            </div>
                          </div>
                        ) : null}
                        {str.matchesPackage.numberOfAllRounders != null ? (
                          <div
                            className="col-3 centerAlign "
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="">
                              <img
                                src="/images/PlayerType/AllRounder.svg"
                                className="img-fluid"
                              />{" "}
                              :{" "}
                              <b className="dataText">
                                {str.matchesPackage.numberOfAllRounders}
                              </b>
                            </div>
                          </div>
                        ) : null}
                        {str.matchesPackage.numberOfWicketKeepers != null ? (
                          <div
                            className="col-3 centerAlign "
                            style={{ backgroundColor: "white" }}
                          >
                            <div className="">
                              <img
                                src="/images/PlayerType/WicketKeeper.svg"
                                className="img-fluid"
                              />{" "}
                              :{" "}
                              <b className="dataText">
                                {str.matchesPackage.numberOfWicketKeepers}
                              </b>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="p-2 mt-3 text-center">
                        <label>
                          <h4>
                            <b>
                              Joined Players ({str.joinMatchPackages.length})
                            </b>
                          </h4>
                        </label>
                      </div>
                      {str.matchesPackage.numberOfBatsmen == null &&
                      str.matchesPackage.numberOfBowlers == null &&
                      str.matchesPackage.numberOfAllRounders == null &&
                      str.matchesPackage.numberOfWicketKeepers == null ? (
                        ""
                      ) : (
                        <>
                          <hr />
                          <div className="centerAlign px-5" key={index}>
                            <div className="col-3 centerAlign">
                              <input
                                type="radio"
                                value="0"
                                className="form-check-input me-1"
                                name="roleType"
                                onChange={(e) => handleChange(e.target.value)}
                              />
                              <label>
                                <div className="">
                                  <img
                                    src="./images/allIcon.png"
                                    className="img-fluid"
                                  />
                                </div>
                              </label>
                            </div>
                            {str.matchesPackage.numberOfBatsmen != null ? (
                              <div className="col-3 centerAlign">
                                <input
                                  type="radio"
                                  value="1"
                                  className="form-check-input me-1"
                                  name="roleType"
                                  onChange={(e) => handleChange(e.target.value)}
                                />
                                <label>
                                  <div className="">
                                    <img
                                      src="/images/PlayerType/Batsmen.svg"
                                      className="img-fluid"
                                    />
                                  </div>
                                </label>
                              </div>
                            ) : null}
                            {str.matchesPackage.numberOfBowlers != null ? (
                              <div className="col-3 centerAlign">
                                <input
                                  type="radio"
                                  value="2"
                                  className="form-check-input me-1"
                                  name="roleType"
                                  onChange={(e) => handleChange(e.target.value)}
                                />
                                <label>
                                  <div className="">
                                    <img
                                      src="/images/PlayerType/Bowler.svg"
                                      className="img-fluid"
                                    />
                                  </div>
                                </label>
                              </div>
                            ) : null}
                            {str.matchesPackage.numberOfAllRounders != null ? (
                              <div className="col-3 centerAlign">
                                <input
                                  type="radio"
                                  value="3"
                                  className="form-check-input me-1"
                                  name="roleType"
                                  onChange={(e) => handleChange(e.target.value)}
                                />
                                <label>
                                  <div className="">
                                    <img
                                      src="/images/PlayerType/AllRounder.svg"
                                      className="img-fluid"
                                    />
                                  </div>
                                </label>
                              </div>
                            ) : null}
                            {str.matchesPackage.numberOfWicketKeepers !=
                            null ? (
                              <div className="col-3 centerAlign">
                                <input
                                  type="radio"
                                  value="4"
                                  className="form-check-input me-1"
                                  name="roleType"
                                  onChange={(e) => handleChange(e.target.value)}
                                />
                                <label>
                                  <div className="">
                                    <img
                                      src="/images/PlayerType/WicketKeeper.svg"
                                      className="img-fluid"
                                    />
                                  </div>
                                </label>
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      {filterDatas.length === 0 ? (
                        <div className="row p-2">
                          <div
                            className=" small text-center mt-2 px-4 py-3"
                            style={{ background: "white" }}
                          >
                            <b>No player has joined your match</b>
                          </div>
                        </div>
                      ) : (
                        <>
                          {filterDatas.map((data, i) => {
                            var playerDiv;
                            playerDiv = (
                              <>
                                <div
                                  key={i}
                                  className=" mt-2 px-4 py-3"
                                  style={{ background: "white" }}
                                >
                                  <div
                                    className=" small row "
                                    data-id={data.id}
                                    // id={i}
                                  >
                                    <div className="col-3 p-0 centerAlign ">
                                      <label
                                        htmlFor="upload-button"
                                        className="userProfileImageDiv"
                                      >
                                        <img
                                          src={
                                            data.playerProfileImage == null ||
                                            data.playerProfileImage == ""
                                              ? "/images/dummyavtar.png"
                                              : `${Imageurl}/UserImage/${data.playerProfileImage}`
                                          }
                                          className="userProfileImage"
                                        />
                                      </label>
                                    </div>
                                    <div
                                      className="col-9"
                                      style={{
                                        alignSelf: "center",
                                      }}
                                    >
                                      <a
                                        onClick={() =>
                                          showUserDetails(data.joinUserID)
                                        }
                                        style={{ textDecoration: "underline" }}
                                        className="dataText"
                                      >
                                        {" "}
                                        <b
                                          className="row"
                                          data-id={data.joinUserID}
                                        >
                                          {data.fullName}{" "}
                                        </b>
                                      </a>{" "}
                                      <span className="row">
                                        Has joined your match
                                        {data.playerRoleID != null
                                          ? ` as a ${GetPlayerRole(
                                              data.playerRoleID
                                            )}`
                                          : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );

                            return playerDiv;
                          })}
                        </>
                      )}
                    </Card.Body>
                    <Card.Footer
                      style={{ fontSize: "13px", float: "right !important" }}
                      key={index}
                    >
                      <b>
                        Created By :-{" "}
                        <span className="dataText">
                          {str.matchesPackage.userName}{" "}
                        </span>
                      </b>
                      |
                      <b>
                        {" "}
                        Created At :-{" "}
                        <span className="dataText">
                          {DBConvertDatetime(str.matchesPackage.createdAt)}
                        </span>
                      </b>
                    </Card.Footer>
                  </Card>
                );
                return returData;
              })
            )}
          </div>
          {userPopUp ? (
            <UserDetails
              show={userPopUp}
              UserDetails={userDetails}
              BattingStyle={battingStyle}
              BowlingStyle={bowlingStyle}
              PlayerStatistics={PlayerStats}
              onHide={closePopupHandler}
              onClick={closePopupHandler}
            />
          ) : null}
          <Popup
            show={MatchEditPopUp}
            onHide={() => SetMatchEditPopUp(false)}
            class="outClass"
            teamname={"Match Details"}
          >
            <MatchDetailsEditPage
              onHide={() => SetMatchEditPopUp(false)}
              allMatchesData={EditAllMatchesData}
            />
          </Popup>
        </>
      )}
    </div>
  );
};
