import React, { useState, useEffect } from "react";

export const AddPhoneNumber = ({ dispatch }) => {
  var listData = [{ phone: "" }];
  //   if (repInfoData.PhoneNumbersList?.length > 0) {
  //     listData = repInfoData.PhoneNumbersList;
  //   }
  const [formValuesPhone, setFormValues] = useState(listData);

  let handleChange = (i, e) => {
    let newFormValues = [...formValuesPhone];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    setFormValues([...formValuesPhone, { phone: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValuesPhone];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    dispatch({
      type: "CHANGE_ARRAY",
      payload: { name: "Phone", value: formValuesPhone },
    });
  }, [formValuesPhone]);

  //   let handleSubmit = (event) => {
  //     event.preventDefault();
  //     alert(JSON.stringify(formValues));
  //   };

  return (
    <React.Fragment>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="">
        {formValuesPhone.map((element, index) => (
          <>
            <div className="form-inline phoneList" key={index}>
              <div className="row text-start">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10 col-10">
                      <label htmlFor="input-2">
                        Phone Number {index + 1}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        name="phone"
                        inputMode="numeric"
                        className="form-control"
                        value={element.phone || ""}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </div>
                    <div
                      className="col-md-2 col-2 px-0"
                      style={{ placeSelf: "center" }}
                    >
                      {formValuesPhone.length == index + 1 ? (
                        index < 3 && (
                          <img
                            className="img-fluid pt-3"
                            src="/images/Add.svg"
                            alt="icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => addFormFields(index)}
                          />
                        )
                      ) : (
                        <img
                          className="img-fluid pt-3"
                          src="/images/delete.png"
                          alt="icon"
                          onClick={() => removeFormFields(index)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      {/* </form> */}
    </React.Fragment>
  );
};

export default AddPhoneNumber;
