import React from "react";

export default function CricbuddyAppComp() {
  return (
    <div className="pt-5">
      <div className="pb-3 mx-0 mx-md-5 pb-md-5">
        <div className="row downloadAppDiv position-relative">
          <div className="col-md-6 p-3 p-md-5">
            <h1 className="offerText">
              Download the cricbuddy app & get instant <b className="offerText-b">10% OFF</b> <br/>on your
              first booking
            </h1>

            <div className="mt-4 d-md-flex d-none flex-wrap justify-content-center justify-content-md-start">
              <a
                target="_"
                href="https://play.google.com/store/apps/details?id=com.sprigstack.cric_buddy"
                rel="nofollow"
              >
                <img
                  className="img-fluid mt-2 ps-2"
                  style={{ height: "8vh" }}
                  src="/images/Playstore.svg"
                  alt="googleplay"
                />
              </a>

              <a
                href="https://apps.apple.com/in/app/cricbuddy/id6480105922"
                target="_"
                rel="nofollow"
              >
                <img
                  className="img-fluid mt-2 ps-2"
                  style={{ height: "8vh" }}
                  src="/images/App_store.svg"
                  alt="applestore"
                />
              </a>
            </div>
          </div>

          <div className="col-md-6 d-md-block d-none">
            <img
              src="/images/cricbuddApp-img.png"
              alt="app"
              className="cricbuddyappImg"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
