import React from "react";
import $ from "jquery";
import { PostAPI } from "../Utils/APIConnector";

export default function LiveMatchPalyers_3({
  prevStep,
  nextStep,
  teamNames,
  playerWithTeams,
  setDynamicValues,
  values,
}) {
  const userType = window.localStorage.getItem("UserType");
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  const Previous = () => {
    prevStep(1);
  };
  const Continue = async () => {
    if (userType == null || userType === undefined || userType === "") {
      window.location.href = "/home";
    }
    var count = 0;
    var TossCalled = $("#TeamTossCall").find("input:radio:checked").val();
    var TossWon = $("#TeamTossWon").find("input:radio:checked").val();
    var TeamChoose = $("#TeamChoosen").find("input:radio:checked").val();

    if (
      TossCalled == undefined ||
      TossCalled == null ||
      TossCalled == "" ||
      TossCalled == 0
    ) {
      $("#TeamTossCall").parent().find(".validation").removeClass("d-none");
    } else {
      if (
        !$("#TeamTossCall").parent().find(".validation").hasClass(" d-none")
      ) {
        $("#TeamTossCall").parent().find(".validation").addClass(" d-none");
      }
      setDynamicValues("TossCall", TossCalled);
      ++count;
    }
    if (
      TossWon == undefined ||
      TossWon == null ||
      TossWon == "" ||
      TossWon == 0
    ) {
      $("#TeamTossWon").parent().find(".validation").removeClass("d-none");
    } else {
      if (!$("#TeamTossWon").parent().find(".validation").hasClass(" d-none")) {
        $("#TeamTossWon").parent().find(".validation").addClass(" d-none");
      }
      setDynamicValues("TossWon", TossWon);
      ++count;
    }
    if (
      TeamChoose == undefined ||
      TeamChoose == null ||
      TeamChoose == "" ||
      TeamChoose == 0
    ) {
      $("#TeamChoosen").parent().find(".validation").removeClass("d-none");
    } else {
      if (!$("#TeamChoosen").parent().find(".validation").hasClass(" d-none")) {
        $("#TeamChoosen").parent().find(".validation").addClass(" d-none");
      }
      setDynamicValues("TeamChoose", TeamChoose);
      ++count;
    }
    if (count == 3) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      var raw = JSON.stringify({
        SingleOrDouble: 2,
        Team1Name: teamNames[0].teamA,
        Team2Name: teamNames[0].teamB,
        TossCalled: parseInt(TossCalled),
        TossWonBy: parseInt(TossWon),
        TossWinnerChoose: TeamChoose,
        CricketMatchId: parseInt(matchDetailsID),
        MatchTeamPlayers: playerWithTeams,
      });
      await PostAPI("/Matches/SaveTeamSelection/", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          if (result.status === "Success") {
            setDynamicValues("SingleOrMultipleTeam", "Multiple");
            nextStep(1);
          } else {
            alert(result.Message);
          }
        }
      });
    }
  };
  return (
    <div>
      <div className="p-3">
        <div className="row ContainerPage_3 py-3">
          <div className="col-12 page_3Font1">Teams</div>
          <div className="col-12 my-2">
            <div className="row">
              <div className="col-6">
                <input
                  disabled
                  type="text"
                  className="form-control "
                  placeholder="Host Team"
                  value={teamNames[0].teamA}
                />
              </div>
              <div className="col-6">
                <input
                  disabled
                  type="text"
                  className="form-control "
                  placeholder="Visitor Team"
                  value={teamNames[0].teamB}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row ContainerPage_3 py-3 mt-4">
          <div className="col-12 page_3Font1">Toss called by ?</div>
          <div className="col-12 my-2">
            <div className="row" id="TeamTossCall">
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio3"
                  id="ContainerPage_3Radio3_1"
                  value="1"
                  defaultChecked={values.TossCall == 1 ? "checked" : null}
                />
                <label
                  className="page_3Font2 ms-1"
                  htmlFor="ContainerPage_3Radio3_1"
                >
                  {teamNames[0].teamA}
                </label>
              </div>
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio3"
                  id="ContainerPage_3Radio3_2"
                  value="2"
                  defaultChecked={values.TossCall == 2 ? "checked" : null}
                />
                <label
                  className="page_3Font2 ms-1 "
                  htmlFor="ContainerPage_3Radio3_2"
                >
                  {teamNames[0].teamB}
                </label>
              </div>
            </div>
            <span
              className="validation d-none"
              style={{ color: "red", float: "right" }}
            >
              Required
            </span>
          </div>
        </div>
        <div className="row ContainerPage_3 py-3 mt-4">
          <div className="col-12 page_3Font1">Toss won by?</div>
          <div className="col-12 my-2">
            <div className="row" id="TeamTossWon">
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio1"
                  id="ContainerPage_3Radio1_1"
                  value="1"
                  defaultChecked={values.TossWon == 1 ? "checked" : null}
                />
                <label
                  className="page_3Font2 ms-1"
                  htmlFor="ContainerPage_3Radio1_1"
                >
                  {teamNames[0].teamA}
                </label>
              </div>
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio1"
                  id="ContainerPage_3Radio1_2"
                  value="2"
                  defaultChecked={values.TossWon == 2 ? "checked" : null}
                />
                <label
                  className="page_3Font2 ms-1"
                  htmlFor="ContainerPage_3Radio1_2"
                >
                  {teamNames[0].teamB}
                </label>
              </div>
            </div>
            <span
              className="validation d-none"
              style={{ color: "red", float: "right" }}
            >
              Required
            </span>
          </div>
        </div>
        <div className="row ContainerPage_3 py-3 mt-4">
          <div className="col-12 page_3Font1">Opted to ?</div>
          <div className="col-12 my-2">
            <div className="row" id="TeamChoosen">
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio2"
                  id="ContainerPage_3Radio2_1"
                  value="Bat"
                  defaultChecked={values.TeamChoose == "Bat" ? "checked" : null}
                />
                <label
                  className="page_3Font2 ms-1"
                  htmlFor="ContainerPage_3Radio2_1"
                >
                  Bat{" "}
                </label>
              </div>
              <div className="col-6">
                <input
                  type="radio"
                  className="form-check-input "
                  name="ContainerPage_3Radio2"
                  id="ContainerPage_3Radio2_2"
                  value="Bowl"
                  defaultChecked={
                    values.TeamChoose == "Bowl" ? "checked" : null
                  }
                />
                <label
                  className="page_3Font2 ms-1"
                  htmlFor="ContainerPage_3Radio2_2"
                >
                  Bowl
                </label>
              </div>
            </div>
            <span
              className="validation d-none"
              style={{ color: "red", float: "right" }}
            >
              Required
            </span>
          </div>
        </div>
      </div>
      <div className="mt-3 mb-3">
        <hr />
        <div className="row">
          <div className="col-6 ">
            <img src="/images/Fill_BackArrow.svg" onClick={Previous} />
          </div>
          <div
            className="col-6"
            style={{
              justifyContent: "flex-end",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <img src="/images/Fill_NextArrow.svg" onClick={Continue} />
          </div>
        </div>
      </div>
    </div>
  );
}
