import React, { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { CheckValidation, validateEmail } from "../Utils/common";
import $ from "jquery";
import { CustomPostAPI } from "../Utils/APIConnector";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactUs(props) {
  const [mail, setmail] = useState({
    Name: "",
    Email: "",
    Number: "",
    Message: "",
  });
  const [showLoader, SetShowLoader] = useState(false);
  const UserType = window.localStorage.getItem("UserType");
  const ReCAPTCHAValue = useRef();

  const userId = localStorage.getItem("userid");
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === "Number" && e.target.value.length > 10) {
      return;
    }
    setmail({ ...mail, [e.target.name]: e.target.value });
  };

  const submit = (e) => {
    SetShowLoader(true);

    const valid = CheckValidation({ formID: "AddSurface" });
    const emailIsValid = validateEmail(mail?.Email);
    if (!emailIsValid) {
      $(".emailError").text("*Invalid Email Format");
    } else {
      $(".emailError").text("");
    }
    if (ReCAPTCHAValue.current.getValue() == "") {
      $(".recaptha").addClass("d-block");
    } else {
      $(".recaptha").removeClass("d-block");
    }
    if (valid && emailIsValid && ReCAPTCHAValue.current.getValue() != "") {
      var raw = JSON.stringify({
        userid: userId ? userId : 0,
        name: mail?.Name,
        phone: mail?.Number,
        email: mail?.Email,
        message: mail?.Message,
      });
      CustomPostAPI("/venue/Contactus", raw).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          SetShowLoader(false);

          setmail({ Name: "", Email: "", Number: "", Message: "" });
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Request sent successfully",
          }).then(() => {
            navigate("/home");
          });
        }
      });
    } else {
      SetShowLoader(false);
    }
  };
  return (
    <React.Fragment>
      {props.iscontactus != "false" && (
        <div className="row px-2">
          <div className="col-12">
            <img
              className="cursorClass"
              src="/images/backArrow.svg"
              onClick={(e) => navigate(-1)}
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <Card
            className="mt-3"
            style={{
              borderRadius: "10px",
              background: "#F2F2F2",
              border: "none",
            }}
          >
            <Card.Body>
              <div className="row">
                {props.iscontactus != "false" && (
                  <div className="col-12">
                    <h1 className="text-center">Contact Us</h1>
                  </div>
                )}
                <div className="col-12 contactus">
                  <div className="row" id="AddSurface">
                    <div className="col-12 parent">
                      <label htmlFor="Name" className="mt-2 mb-1">
                        Name*
                      </label>
                      <br />
                      <input
                        className="w-100"
                        value={mail?.name}
                        type="text"
                        name="Name"
                        required
                        placeholder="Name"
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="form-text invalid-feedback">
                        * This field is Required!
                      </span>
                    </div>
                    <div className="col-12 parent">
                      <label htmlFor="Email" className="mt-2 mb-1">
                        Email*
                      </label>
                      <br />
                      <input
                        className="w-100"
                        value={mail?.email}
                        type="text"
                        name="Email"
                        placeholder="Email"
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="form-text invalid-feedback">
                        * This field is Required!
                      </span>
                      <small className="emailError text-danger"></small>
                    </div>
                    <div className="col-12 parent">
                      <label htmlFor="Number" className="mt-2 mb-1">
                        Phone Number*
                      </label>
                      <br />
                      <input
                        className="w-100"
                        value={mail?.Number}
                        type="number"
                        inputMode="numeric"
                        name="Number"
                        required
                        maxLength={10}
                        placeholder="Phone Number"
                        onChange={(e) => handleChange(e)}
                      />
                      <span className="form-text invalid-feedback">
                        * This field is Required!
                      </span>
                    </div>
                    <div className="col-12 parent">
                      <label htmlFor="Message" className="mt-2 mb-1">
                        Message*
                      </label>
                      <br />
                      {/* <input className="w-100" type="text" name="Name" /> */}
                      <textarea
                        name="Message"
                        value={mail?.message}
                        className="w-100"
                        onChange={(e) => handleChange(e)}
                        required
                        placeholder="Description"
                      ></textarea>
                      <span className="form-text invalid-feedback">
                        * This field is Required!
                      </span>
                    </div>
                    <div className="col-12 col-md-12  parent d-flex justify-content-center mt-3">
                      <ReCAPTCHA
                        ref={ReCAPTCHAValue}
                        sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                      />
                    </div>
                    <span className="form-text recaptha text-center  invalid-feedback">
                      *Please complete the captcha
                    </span>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <div className="justify-content-center">
                    {/* <div className="col-md-3 m-auto mt-3 mb-1"> */}
                    <button
                      className="col-md-3 m-auto mt-3 mb-1 Contactusbtn"
                      onClick={(e) => submit(e)}
                    >
                      Send details
                    </button>
                    <span className="form-text invalid-feedback">
                      Details sent successfully
                    </span>
                    {/* </div> */}
                    {showLoader ? (
                      <img
                        id="loaderImg"
                        className="col-1 hidden"
                        height="30px"
                        width="30px"
                        src="/images/loder.svg"
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
}
