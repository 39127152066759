import React from "react";
import "../css/common.css";
class Flashpage extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.localStorage.clear();
    setTimeout(() => {
      window.location = "/home";
      window.localStorage.setItem("UserType", "Guest");
    }, 1000);
  }
  render() {
    return (
      <div className="container-fluid bodyClass">
        <img src="/images/cricketFlash.svg" className="cricketLogoFlash" />
      </div>
    );
  }
}
export default Flashpage;
