import React, { useState } from "react";
import Popup from "../LiveScoreCardpopups/Popup";
import AppLink from "./AppLink";

export default function GetApp(props) {
  const [show, setShow] = useState(false);
  return (
    <>
      <button
        className={`${
          props.header ? "btn-Banner-header" : "btn-Banner px-4 py-1"
        }`}
        onClick={(e) => setShow(true)}
      >
        Get the App
      </button>
      <Popup show={show} onHide={() => setShow(false)} venueflag="1">
        <AppLink />
      </Popup>
    </>
  );
}
