export async function BattingStyle() {
  var myHeaders = new Headers();
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    process.env.REACT_APP_API_BASE_URL + "/User/GetBattingstyle",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      window.localStorage.setItem(
        "Battingstyle",
        JSON.stringify(result.enumvalues)
      );
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export async function BowlingStyle() {
  var myHeaders = new Headers();
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    process.env.REACT_APP_API_BASE_URL + "/User/GetBowlingstyle",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      window.localStorage.setItem(
        "Bowlingstyle",
        JSON.stringify(result.enumValues)
      );
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export async function PlayRole() {
  var myHeaders = new Headers();
  myHeaders.append("authToken", process.env.REACT_APP_CUSTOM_API_KEY);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    process.env.REACT_APP_API_BASE_URL + "/User/playersRoles",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      window.localStorage.setItem(
        "playrole",
        JSON.stringify(result.playersRoles)
      );
      return result;
    })
    .catch((error) => {
      return error;
    });
}

export function ConvertDateTime(datetime) {
  var matchDate = new Date(Date.parse(datetime.replace(/-/g, "/")));
  var date =
    matchDate.getDate() +
    "-" +
    (matchDate.getMonth() + 1) +
    "-" +
    matchDate.getFullYear();
  var time = new Date(matchDate.getTime()).toLocaleTimeString(
    navigator.language,
    { hour: "2-digit", minute: "2-digit" }
  );
  // var time = matchDate.getHours() + ":" + matchDate.getMinutes();
  var convertedDate = date + ", " + time;
  return convertedDate;
}

export function DBConvertDatetime(datetime) {
  var matchDate = new Date(datetime);
  var date =
    matchDate.getDate() +
    "/" +
    (matchDate.getMonth() + 1) +
    "/" +
    matchDate.getFullYear();

  var time = new Date(matchDate.getTime()).toLocaleTimeString(
    navigator.language,
    { hour: "2-digit", minute: "2-digit", hour12: true }
  );
  // var time = matchDate.getHours() + ":" + matchDate.getMinutes();
  var convertedDate = date + ", " + time;
  return convertedDate;
}

export function ConvertDate(datetime) {
  var tempDate = new Date(datetime);
  var convertedDate =
    tempDate.getDate() +
    "-" +
    (tempDate.getMonth() + 1) +
    "-" +
    tempDate.getFullYear();

  return convertedDate;
}

export function GetMyAge(date) {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
export function maxDate() {
  var d = new Date();
  // d.setDate(d.getDate() + ((16 - d.getDay()) % 7 + 1) % 7);
  //var today = new Date();
  d.setDate(d.getDate() + 15);
  var dd = d.getDate();

  var mm = d.getMonth() + 1;
  var yyyy = d.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var today = yyyy + "-" + mm + "-" + dd;
  return today;
}
export function minDate() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export function GetDay(datetime) {
  const d = new Date(datetime);
  let day = d.getDay();
  var days = "";
  switch (day) {
    case 0:
      days = "Sunday";
      break;
    case 1:
      days = "Monday";
      break;
    case 2:
      days = "Tuesday";
      break;
    case 3:
      days = "Wednesday";
      break;
    case 4:
      days = "Thursday";
      break;
    case 5:
      days = "Friday";
      break;
    case 6:
      days = "Saturday";
      break;
  }
  return days;
}

export function GetPlayerRole(playerRoleID) {
  var roleID = parseInt(playerRoleID);
  switch (roleID) {
    case 1:
      return "(Batsman)";
    case 2:
      return "(Bowler)";
    case 3:
      return "(All Rounder)";
    case 4:
      return "(Wicket Keeper)";
    default:
      return "";
  }
}

export function GetStrikeRate(playerScoreRuns, playerScoreballs) {
  if (playerScoreballs == 0) {
    return 0;
  }
  var value = parseFloat(playerScoreRuns / playerScoreballs) * 100;

  if (isNaN(value)) {
    value = 0;
  } else {
    value = value;
  }
  return value.toFixed(2);
}

export function GetRunRate(totalScore, overCount, scoreCount) {
  var totalBalls = overCount * 6 + scoreCount;
  if (totalBalls == 0) {
    return 0;
  }
  var value = (totalScore / totalBalls) * 6;
  if (isNaN(value)) {
    value = 0;
  } else {
    value = value;
  }
  return value.toFixed(2);
}

export function datetimeValues(Value) {
  var date1 = new Date(Value);
  var date2 = new Date(Value);
  var isVisible = "d-none";
  date1.setMinutes(date1.getMinutes() - 30);
  date2.setMinutes(date2.getMinutes() + 1440);
  if (new Date() >= date1 && new Date() <= date2) {
    isVisible = "";
  } else {
    // isVisible = "d-none";
    isVisible = "";
  }
  return isVisible;
}
// export function sameMobileNo(Value) {
//   var valueArr = Value.map(function (item) {
//     return item.Mobilenumber;
//   });
//   var isDuplicate = valueArr.some(function (item, idx) {
//     return valueArr.indexOf(item) != idx;
//   });
// }

export function isValid(value) {
  var valid = false;

  if (
    value === null ||
    value === undefined ||
    value === "" ||
    value === 0 ||
    value === "0" ||
    value === "NULL" ||
    value === "null"
  ) {
    valid = false;
  } else {
    valid = true;
  }

  return valid;
}

export function radioChecked(playerWithTeams, id, value) {
  var includeInList = false;

  if (playerWithTeams != null && playerWithTeams.length > 0) {
    var foundValue = playerWithTeams.filter(
      (obj) => obj.PlayerID == id && obj.TeamTypeID == value
    );
    if (foundValue.length > 0) {
      includeInList = true;
    }
  }
  return includeInList;
}

export function getNextSaturdayDate() {
  var today = new Date();
  today.setDate(today.getDate() + ((6 - 1 - today.getDay() + 7) % 7) + 1);
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export function dateInputValue(date) {
  var date = date.split("T");
  var value = date[0].split("-");
  return value[0] + "-" + value[1] + "-" + value[2];
}

export function editIconVisible(Value) {
  var date1 = new Date(Value);
  var isVisible = false;
  date1.setMinutes(date1.getMinutes() - 1440);
  // if (new Date() >= date1) {
  //   isVisible = false;
  // } else {
  //    isVisible = true;
  // }
  return isVisible;
}
