import React, { useState } from "react";
import EditVenuePage1 from "./EditVenuePage1";
import EditVenuePage3 from "./EditVenuePage3";
import EditVenuePage2 from "./EditVenuePage2";
import EditVenuePage4 from "./EditVenuePage4";

export default function VenueDashboardEditDetails({
  venueID,
  isMainPage,
  setIsMainPage,
}) {
  const [selectedPage, setSelectedPage] = useState();
  return (
    <React.Fragment>
      {isMainPage ? (
        <>
          <div
            className="my-3 cursor-pointer"
            onClick={() => {
              setIsMainPage(false);
              setSelectedPage("details");
            }}
          >
            {" "}
            <div className="d-flex align-items-center justify-content-between p-4 bordereditvenue">
              <span className="Montserrat fs-14">Venue Information</span>
              <img src="/images/EditVenueNextClick.svg" alt="" />
            </div>
          </div>
          <div
            className="my-2 cursor-pointer"
            onClick={() => {
              setIsMainPage(false);
              setSelectedPage("facility");
            }}
          >
            {" "}
            <div className="d-flex align-items-center justify-content-between p-4 bordereditvenue">
              <span className="Montserrat fs-14">Facilities</span>
              <img src="/images/EditVenueNextClick.svg" alt="" />
            </div>
          </div>
          <div
            className="my-3 cursor-pointer"
            onClick={() => {
              setIsMainPage(false);
              setSelectedPage("amenity");
            }}
          >
            {" "}
            <div className="d-flex align-items-center justify-content-between p-4 bordereditvenue">
              <span className="Montserrat fs-14">Amenties</span>
              <img src="/images/EditVenueNextClick.svg" alt="" />
            </div>
          </div>
          <div
            className="my-3 cursor-pointer"
            onClick={() => {
              setIsMainPage(false);
              setSelectedPage("disable");
            }}
          >
            <div className="d-flex align-items-center justify-content-between p-4 bordereditvenue">
              <span className="Montserrat fs-14">Disable Venue</span>
              <img src="/images/EditVenueNextClick.svg" alt="" />
            </div>
          </div>
        </>
      ) : (
        <>
          {selectedPage == "details" && (
            <EditVenuePage1
              venueId={venueID}
              setSelectedPage={setSelectedPage}
              setIsMainPage={setIsMainPage}
            />
          )}
          {selectedPage == "facility" && (
            <EditVenuePage2
              venueId={venueID}
              setSelectedPage={setSelectedPage}
              setIsMainPage={setIsMainPage}
            />
          )}
          {selectedPage == "amenity" && (
            <EditVenuePage3
              venueId={venueID}
              setSelectedPage={setSelectedPage}
              setIsMainPage={setIsMainPage}
            />
          )}
          {selectedPage == "disable" && (
            <EditVenuePage4
              venueId={venueID}
              setSelectedPage={setSelectedPage}
              setIsMainPage={setIsMainPage}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
