import React, { useState, useEffect } from "react";
import StepProgressBar from "../VenueInformation/StepProgressBar";
// import Loader from "./Utils/Loader";
import VerifyFirstStep from "./VerifyFirstStep";
import VerifySecondStep from "./VerifySecondStep";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomGetAPI, CustomPostAPI } from "../Utils/APIConnector";
import Loader from "../Utils/Loader";
import Swal from "sweetalert2";
import {
  convertDecimalToTime,
  convertTo12HourFormat,
  minToHour,
} from "../Utils/common";

export default function VenueVerification() {
  const [currentStep, setcurrentStep] = useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  var BucketS3Link = process.env.REACT_APP_Base_URL;

  useEffect(() => {
    CustomGetAPI("/Venue/Approveorrejectvenue/" + location.state.id).then(
      (result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          result = JSON.parse(result);
          setIsLoading(false);
          setData(result);
        }
      }
    );
  }, [location.state.id]);
  const handleVenueStatus = (id, flag) => {
    setIsLoading(true);
    var raw = JSON.stringify({
      venueid: id,
      flag: flag,
    });
    CustomPostAPI("/Venue/AcceptOrRejectVenue", raw).then((result) => {
      if (result == undefined || result == "") {
        setIsLoading(false);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      } else {
        setIsLoading(false);

        const message =
          flag === true
            ? "Venue approved successfully"
            : "Venue rejected successfully";
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: message,
        }).then(() => {
          navigate("/home");
        });
      }
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          <div className="row px-2">
            <div className="col-12">
              <img
                className="cursorClass"
                src="/images/backArrow.svg"
                onClick={() => navigate(-1)}
              />
            </div>
          </div>

          <div className="Container mb-3 row">
            <div className="p-2 text-center col-12">
              {/* <StepProgressBar currentStep={currentStep} totalSteps={2} /> */}
              <label>
                <h4 style={{ color: "#A51E22" }} className="mt-3">
                  <b>Venue Verification</b>
                </h4>
              </label>
            </div>
            <div className="col-12">
              <div className="dotted-line"></div>
            </div>
            <div className="col-12 text-center my-2">
              <img src="/images/infovector.svg" alt="" className="img-fluid" />
              &nbsp;
              <span>Venue Information</span>
              <div className="row text-start adminlist">
                <div className="col-12 mt-3">
                  <label>Venue Name :&nbsp;</label>
                  {data?.name}
                </div>
                <div className="col-12 mt-3">
                  <label>Photos of venue :</label>
                </div>
                <div className="col-12">
                  <span className="venueText">
                    {data?.venueImages?.map((img, index) => (
                      <img
                        key={index} // Adding a unique key for each image is necessary when rendering lists in React
                        className="img-fluid w-100 venuePhotos1 my-2"
                        src={`${BucketS3Link}${img?.image}`}
                        alt="preview"
                        style={{
                          marginRight: "3px",
                          borderRadius: "5px",
                        }}
                      />
                    ))}
                  </span>
                </div>
                <div className="col-12 mt-3">
                  <label>Description/About the Venue :</label>
                  <br />
                  {data?.description}
                </div>
                <div className="col-12 my-3">
                  <label>Venue Operating Hours : &nbsp;</label>
                  <br />
                  {convertTo12HourFormat(data?.startTime) === "12:00 am" &&
                  convertTo12HourFormat(data?.endTime) === "11:59 pm"
                    ? "24 Hours"
                    : `${convertTo12HourFormat(
                        data?.startTime
                      )} - ${convertTo12HourFormat(data?.endTime)}`}
                </div>
                <div className="col-12 mt-3">
                  <label>Minimun Booking Time:</label>{" "}
                  {convertDecimalToTime(minToHour(data?.minSlotTime))}
                </div>
                <div className="col-12 mt-3">
                  <label>Cancellation Policy:</label> {data?.cancellationTime}
                </div>
                <div className="col-12 mt-3">
                  <label>Reschedule Hours:</label> {data?.rescheduleHours}
                </div>
                <div className="col-12 mt-3">
                  <label>Reschedule Count:</label> {data?.rescheduleCount}
                </div>
                <div className="dotted-line"></div>
                <div className="col-12 text-center mt-2">
                  <img
                    src="/images/contactinfo.svg"
                    alt=""
                    className="img-fluid"
                  />
                  &nbsp;
                  <span>Contact information</span>
                </div>
                <div className="col-12 mt-3">
                  <label>Email :&nbsp;</label>
                  {data?.email}
                </div>
                <div className="col-12 my-3">
                  <label>Phone number :&nbsp;</label>
                  {data?.phone}
                </div>
                <div className="dotted-line"></div>
                <div className="col-12 text-center mt-2">
                  <img
                    src="/images/locationinfo.svg"
                    alt=""
                    className="img-fluid"
                  />
                  &nbsp;
                  <span>Location</span>
                </div>
                <div className="col-12 mt-3">
                  <label>Area :&nbsp;</label>
                  {data?.address?.addressline1}
                </div>
                <div className="col-12 mt-3">
                  <label>Area/Locality :&nbsp;</label>
                  {data?.address?.addressline2}
                </div>
                {data?.address?.addressline3 && (
                  <div className="col-12 mt-3">
                    <label>Landmark:</label>&nbsp;
                    {data?.address?.addressline3}
                  </div>
                )}
                <div className="col-6 mt-3">
                  <label>City :&nbsp;</label>
                  {data?.address?.cityName}
                </div>
                <div className="col-6 mt-3">
                  <label>State :&nbsp;</label>
                  {data?.address?.stateName}
                </div>
                <div className="col-6 mt-3">
                  <label>Country :&nbsp;</label>
                  India
                </div>
                <div className="col-6 my-3">
                  <label>Zip Code :&nbsp;</label>
                  {data?.address?.zipcode}
                </div>
                <div className="dotted-line"></div>
              </div>
              <div className="row px-2 adminlist">
                <div className="col-12 mt-2">
                  <b>Facilities</b>
                </div>
                <div className="col-12  mt-2">
                  <div className="row" id="AddSurface">
                    <div className="accordion mt-2" id="accordionExample">
                      {data?.sportsFacility?.map((element, index) => (
                        <>
                          <div className="accordion-item">
                            <h2
                              className="accordion-header"
                              id={`heading-${index}`}
                            >
                              <div
                                className="row mx-0 p-2"
                                style={{ backgroundColor: "#f5f5f5" }}
                              >
                                <div
                                  className="col-10 ps-0 d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#F2F2F2 !important",
                                    color: "black !important",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {data?.category == "Sports"
                                    ? "Court/Surface"
                                    : "Event"}{" "}
                                  {index + 1} : {element?.surfaceName}
                                </div>

                                <div className="col-2 pe-0">
                                  <button
                                    className={`accordion-button accordion-button-child ${
                                      index != 0 && "collapsed"
                                    }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={
                                      index == 0 ? "true" : "false"
                                    }
                                    aria-controls={`collapse${index}`}
                                    style={{ float: "right" }}
                                  ></button>
                                </div>
                              </div>
                            </h2>

                            <div
                              id={`collapse${index}`}
                              className={`accordion-collapse collapse ${
                                index == 0 && "show"
                              }`}
                              collapse
                              show
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                className="accordion-body"
                                style={{ backgroundColor: "#F9F9F9" }}
                              >
                                <div className="row ">
                                  {/* <div className="col-12 my-1 text-start">
                                <label>Court Count :&nbsp;</label>
                                {element?.courtCount}
                              </div>
                              <div className="dotted-line"></div> */}
                                  <div className="col-12 my-1 text-start">
                                    <label>
                                      Selected{" "}
                                      {data?.category == "Sports"
                                        ? "Sports"
                                        : "Event"}{" "}
                                      {index + 1} :&nbsp;
                                    </label>

                                    {element?.sports?.map((item, i) => (
                                      <React.Fragment key={i}>
                                        {i > 0 && ", "}
                                        {item.label}
                                      </React.Fragment>
                                    ))}
                                  </div>
                                  <div className="dotted-line"></div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-12 p-0">
                                    <label>
                                      Booking day availability & rates
                                    </label>
                                  </div>
                                  {/* <div className="col-12 my-3">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Mon-Fri</th>
                                        </tr>
                                        <tr>
                                          {element?.sports[0]?.label ===
                                          "Go-Karting" ? (
                                            <th className="text-center tableheaderBackground">
                                              Duration
                                            </th>
                                          ) : (
                                            <>
                                              <th className="text-center tableheaderBackground">
                                                StartTime
                                              </th>
                                              <th className="text-center tableheaderBackground">
                                                EndTime
                                              </th>
                                            </>
                                          )}

                                          <th className="text-center tableheaderBackground">
                                            Rate
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {element?.monFri?.map((item, index) => (
                                          <tr key={index}>
                                            {element?.sports[0]?.label ===
                                            "Go-Karting" ? (
                                              <td>{item?.duration}</td>
                                            ) : (
                                              <>
                                                <td>
                                                  {convertTo12HourFormat(
                                                    item?.startTime
                                                  )}
                                                </td>
                                                <td>
                                                  {convertTo12HourFormat(
                                                    item?.endTime
                                                  ) === "11:59 pm"
                                                    ? "12:00 am"
                                                    : convertTo12HourFormat(
                                                        item?.endTime
                                                      )}
                                                </td>
                                              </>
                                            )}

                                            <td>{item.amount}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="col-12 mb-2">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th colSpan={3}>Sat-Sun</th>
                                        </tr>
                                        <tr>
                                          {element?.sports[0]?.label ===
                                          "Go-Karting" ? (
                                            <th className="text-center tableheaderBackground">
                                              Duration
                                            </th>
                                          ) : (
                                            <>
                                              <th className="text-center tableheaderBackground">
                                                StartTime
                                              </th>
                                              <th className="text-center tableheaderBackground">
                                                EndTime
                                              </th>
                                            </>
                                          )}
                                          <th className="text-center tableheaderBackground">
                                            Rate
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {element?.satSun?.map((item, index) => (
                                          <tr key={index}>
                                            {element?.sports[0]?.label ===
                                            "Go-Karting" ? (
                                              <td>{item?.duration}</td>
                                            ) : (
                                              <>
                                                <td>
                                                  {convertTo12HourFormat(
                                                    item?.startTime
                                                  )}
                                                </td>
                                                <td>
                                                  {convertTo12HourFormat(
                                                    item?.endTime
                                                  ) === "11:59 pm"
                                                    ? "12:00 am"
                                                    : convertTo12HourFormat(
                                                        item?.endTime
                                                      )}
                                                </td>
                                              </>
                                            )}

                                            <td>{item.amount}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div> */}

                                  <div className="row mt-3">
                                    {element?.weekDay?.map((day, dayIndex) => {
                                      const isGoKarting =
                                        element?.sports[0]?.label ===
                                        "Go-Karting";

                                      if (isGoKarting) {
                                        return (
                                          day?.weekDays !== "" &&
                                          day?.data?.length > 0 && (
                                            <div
                                              key={dayIndex}
                                              className="col-md-6"
                                            >
                                              <div
                                                className="p-3 mb-3"
                                                style={{
                                                  backgroundColor: "#e8e8e8",
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div
                                                    className="fw-600"
                                                    style={{ fontSize: "16px" }}
                                                  >
                                                    {day.weekDays}
                                                  </div>
                                                </div>
                                                {day?.data?.map((item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="mt-2"
                                                  >
                                                    <div>
                                                      {item.duration1 &&
                                                        "5 Min: Amount: ₹" +
                                                          item.duration1}{" "}
                                                      <br />
                                                      {item.duration2 &&
                                                        "10 Min: Amount: ₹" +
                                                          item.duration2}{" "}
                                                      <br />
                                                      {item.duration3 &&
                                                        "15 Min: Amount: ₹" +
                                                          item.duration3}{" "}
                                                      <br />
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )
                                        );
                                      } else {
                                        return (
                                          day?.weekDays !== "" &&
                                          day?.data?.length > 0 &&
                                          day?.data[0]?.startTime !== "" &&
                                          day?.data[0]?.amount !== "" &&
                                          day?.data[0]?.amount !== false &&
                                          day?.data[0]?.amount !== "0" && (
                                            <div
                                              key={dayIndex}
                                              className="col-md-6"
                                            >
                                              <div
                                                className="p-3 mb-3"
                                                style={{
                                                  backgroundColor: "#e8e8e8",
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div
                                                    className="fw-600"
                                                    style={{ fontSize: "16px" }}
                                                  >
                                                    {day.weekDays}
                                                  </div>
                                                </div>
                                                {day?.data?.map((item, idx) => (
                                                  <div
                                                    key={idx}
                                                    className="mt-2"
                                                  >
                                                    <div>
                                                      Time:{" "}
                                                      {convertTo12HourFormat(
                                                        item.startTime
                                                      )}{" "}
                                                      to{" "}
                                                      {convertTo12HourFormat(
                                                        item.endTime
                                                      )}{" "}
                                                      <br />
                                                      Amount: ₹{item.amount}
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )
                                        );
                                      }
                                    })}
                                  </div>
                                  <div className="text-start">
                                    {element?.rehearsalprice > 0 ||
                                    element?.extrahourprice > 0 ? (
                                      <div className="dotted-line mb-3"></div>
                                    ) : (
                                      <></>
                                    )}
                                    {/* <div className="mt-2">
                                      <label>Minimum Booking Time:</label>{" "}
                                      {element?.minslottime} mins
                                    </div> */}
                                    {element?.rehearsalprice > 0 && (
                                      <>
                                        <div className="mt-2">
                                          <label>Rehersal Price:</label>{" "}
                                          {element?.rehearsalprice}₹
                                        </div>
                                      </>
                                    )}
                                    {element?.extrahourprice > 0 && (
                                      <>
                                        <div className="mt-2">
                                          <label>Extra Per Hour Charge:</label>{" "}
                                          {element?.extrahourprice}₹
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="dotted-line"></div>
                                  <div className="col-12 mt-3">
                                    <label>Selected Amenities :&nbsp;</label>
                                    {data?.venueAmenities?.map((item, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          {i > 0 && ", "}
                                          {item.name}
                                        </React.Fragment>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="dotted-line mt-3"></div>
                <div className="row mt-4 d-flex justify-content-evenly">
                  <div className="col-md-4 col-5">
                    <button
                      className="btn btn-gray"
                      onClick={() =>
                        handleVenueStatus(location.state.id, false)
                      }
                    >
                      Reject
                    </button>
                  </div>
                  <div className="col-md-4 col-5">
                    <button
                      className="btn btn-danger"
                      onClick={() => handleVenueStatus(location.state.id, true)}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="venueverification"></div>
            {/* <div className="venueverification">
          {currentStep == 1 ? (
            <VerifyFirstStep setcurrentStep={setcurrentStep} data={data} isLoading={isLoading} />
          ) : null}

          {currentStep == 2 ? (
            <VerifySecondStep setcurrentStep={setcurrentStep} data={data} isLoading={isLoading} />
          ) : null}
        </div> */}
          </div>
        </>
      )}
    </>
  );
}
