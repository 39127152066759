import React, { useEffect, useState } from "react";
import {
  CustomDeleteAPI,
  CustomGetAPI,
  CustomPostAPI,
} from "../Utils/APIConnector";
import { Card } from "react-bootstrap";
import { VenueDate } from "../Utils/common";
import { useNavigate } from "react-router-dom";
import Loader from "../Utils/Loader";
import Swal from "sweetalert2";

export default function CouponPage() {
  const [couponData, setCouponData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    CustomGetAPI("/venue/GetOffers/0").then((result) => {
      if (result) {
        result = JSON.parse(result);
        setCouponData(result?.data);
        setIsLoading(false);
      }
    });
  }, []);

  const deleteCoupon = (id) => {
    setIsLoading(true);
    CustomDeleteAPI(`/venue/Deleteoffer/${id}`).then((result) => {
      if (result) {
        result = JSON.parse(result);
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Congratulations!",
          text: "Coupon Deleted Successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/coupon-home";
          }
        });
      }
    });
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-1">
              <img
                src="/images/backArrow.svg"
                onClick={() => {
                  window.location.href = "/home-venue-owner";
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-11 text-end">
              <button
                className="btnAddCoupon"
                onClick={() => {
                  window.location.href = "/add-coupon";
                }}
              >
                <b>+ Add Coupon</b>
              </button>
            </div>
          </div>
          <div className="row mt-5">
            {couponData &&
              couponData?.map((coupon) => (
                <div className="col-md-6">
                  <Card>
                    <Card.Header className="couponHeader">
                      <div className="row">
                        <div className="col-10">
                          <b className="signuppolicy">{coupon.code}</b>
                        </div>
                        <div className="col-1 px-1 px-md-0">
                          <img
                            src="/images/deleteicon.svg"
                            alt=""
                            className="img-fluid w-100 cursor-pointer"
                            onClick={() => {
                              deleteCoupon(coupon.id);
                            }}
                          />
                        </div>
                        <div className=" col-1 px-1 px-md-0">
                          <img
                            src="/images/edit.svg"
                            className="editImage img-fluid cursor-pointer"
                            alt="edit"
                            onClick={() =>
                              navigate("/edit-coupon", {
                                state: { couponId: coupon.id },
                              })
                            }
                          />
                        </div>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div className="row couponBody">
                        <div className="col-6">
                          <b>Start</b>
                          <br />
                          <span>
                            {" "}
                            {VenueDate(coupon.createdDate).formattedDate}
                          </span>{" "}
                        </div>
                        <div className="col-6">
                          <b>End</b>
                          <br />
                          <span> {VenueDate(coupon.expiry).formattedDate}</span>
                        </div>
                        <div className="col-6 mt-2">
                          <b>Discount %</b>
                          <br />
                          <span> {coupon.offpercentage}%</span>
                        </div>
                        <div className="col-6 mt-2">
                          <b>Discount ₹</b>
                          <br />
                          <span> {coupon.amountLimit}₹</span>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </>
      )}
    </React.Fragment>
  );
}
