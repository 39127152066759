import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

export default function StepProgressBar(props) {
  var stepPercentage = 0;
  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === props.totalSteps) {
    stepPercentage = 100;
  } else {
    stepPercentage =
      (100 / parseInt(props.totalSteps - 1)) * (props.currentStep - 1);
  }

  const stepDiv = [];
  for (var i = 1; i <= props.totalSteps; i++) {
    stepDiv.push(i);
  }
  return (
    <React.Fragment>
      <ProgressBar percent={stepPercentage}>
        {stepDiv.map((str, index) => {
          var stDiv;
          <>
            {
              (stDiv = (
                <Step transition="scale">
                  {({ accomplished, index }) => (
                    <div
                      className={`indexedStep ${
                        accomplished ? "accomplished" : null
                      }`}
                    >
                      {index + 1}
                    </div>
                  )}
                </Step>
              ))
            }
          </>;
          return stDiv;
        })}
      </ProgressBar>
    </React.Fragment>
  );
}
