import { configureStore } from "@reduxjs/toolkit";
import LiveScoreData from "./reducer";

const store = configureStore({
  reducer: {
    liveScoreData: LiveScoreData,
  },
});

export default store;
