import React, { useEffect, useState } from "react";
import Loader from "../Utils/Loader";
import Popup from "../LiveScoreCardpopups/Popup";
import DatePicker from "react-datepicker";
import StaffCard from "../StaffMembers/StaffCard";
import {
  CustomDeleteAPI,
  CustomGetAPI,
  CustomPostAPI,
  CustomPutAPI,
} from "../Utils/APIConnector";
import { formatDateTime } from "../Utils/common";
import Select from "react-select";

export default function EditVenuePage4({
  venueId,
  setIsMainPage,
  setSelectedPage,
}) {
  const [isLoading, SetIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [selectedid, setId] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCourt, setSelectedCourt] = useState([]);
  const [description, setDescription] = useState("");
  const [disableSlotData, setDisableSlotData] = useState();
  const [courtData, setCourtData] = useState();

  useEffect(() => {
    getDisableSlots();
  }, []);
  const getDisableSlots = () => {
    CustomGetAPI(`/BlockTimeslots/GetBlockedTimeslots/${venueId}`).then(
      (result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status == "success") {
            setDisableSlotData(result?.data?.responseList);
            setCourtData(result?.court);
          }
          SetIsLoading(false);
        }
      }
    );
  };

  const addSlot = () => {
    if (isEdit) {
      const raw = JSON.stringify({
        id: selectedid,
        venueid: venueId,
        Start_Date_Time: startDate.toString(),
        End_Date_Time: endDate.toString(),
        VenuesportId: selectedCourt?.map((item) => item.value).join(","),
        description: description,
      });
      CustomPutAPI(`/BlockTimeslots/UpdateBlockedTimeslot`, raw).then(
        (result) => {
          if (result) {
            result = JSON.parse(result);
            if (result.status == "success") {
              setShow(false);
              SetIsLoading(true);
              getDisableSlots();
            }
          }
        }
      );
    } else {
      const raw = JSON.stringify({
        venueid: venueId,
        Start_Date_Time: startDate.toString(),
        End_Date_Time: endDate.toString(),
        VenuesportId: Array.isArray(selectedCourt)
          ? selectedCourt?.map((item) => item.value).join(",")
          : selectedCourt?.value,
        description: description,
      });
      CustomPostAPI(`/BlockTimeslots/BlockTimeslots`, raw).then((result) => {
        if (result) {
          result = JSON.parse(result);
          if (result.status == "success") {
            setShow(false);
            SetIsLoading(true);
            getDisableSlots();
          }
        }
      });
    }
  };
  const deleteDisableSlot = (id) => {
    CustomDeleteAPI(`/BlockTimeslots/Deletetimeslot/${id}`).then((result) => {
      if (result) {
        result = JSON.parse(result);
        if (result.status == "success") {
          setShow(false);
          SetIsLoading(true);
          getDisableSlots();
        }
      }
    });
  };
  const options =
    courtData?.map((data) => ({
      value: data.id,
      label: data.name,
    })) || [];

  const allIDs = courtData?.map((data) => data.id).join(",") || "";
  const allOption = {
    value: allIDs,
    label: "All",
  };

  const allOptions = [allOption, ...options];
  const body = (
    <>
      <div className="row">
        <div className="col-6">
          <p className="mb-1">Start Date & Time:</p>
          <DatePicker
            showTimeSelect
            className="form-control"
            selected={startDate}
            onChange={(date) => {
              if (date > endDate) {
                setEndDate(date);
              }
              setStartDate(date);
            }}
            dateFormat="Pp"
          />
        </div>
        <div className="col-6">
          <p className="mb-1">End Date & Time:</p>
          <DatePicker
            showTimeSelect
            className="form-control"
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            dateFormat="Pp"
            minDate={new Date(startDate)}
          />
        </div>
        <div className="col-12">
          <p className="mb-1">Description:</p>
          <textarea
            className="form-control"
            placeholder="Add description to show user reason for slot not unavialable"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="col-6 mt-2">
          <p className="mb-1">Select Court:</p>
          <Select
            value={selectedCourt}
            options={allOptions}
            onChange={(selectedOptions) => {
              if (
                selectedOptions.length > 1 &&
                selectedOptions[0]?.value == allIDs
              ) {
                selectedOptions = selectedOptions.filter(
                  (option) => option.value !== allIDs
                );
              }
              if (selectedOptions.length == 0) {
                selectedOptions = allOption;
              }
              const filteredOptions = selectedOptions.filter(
                (option) => option.value === allIDs
              );

              setSelectedCourt(
                filteredOptions.length > 0 ? filteredOptions : selectedOptions
              );
            }}
            name=""
            isDisabled={options.length == 1}
            isMulti
            id=""
          />
        </div>
        <div className="col-6 mt-2 d-flex align-items-end justify-content-end">
          <button className="btn btn-success w-50" onClick={() => addSlot()}>
            {isEdit ? "Edit" : "Add"}
          </button>
        </div>
      </div>
    </>
  );
  return (
    <React.Fragment>
      <div className="row mx-0 Montserrat fw-600 mt-3 d-flex align-items-center">
        <div className="col">
          {" "}
          <span>Disable Venue</span>
        </div>
        <div className="col">
          {" "}
          <button
            className="btnAddSlot  py-2 px-2 float-end Montserrat fw-500"
            onClick={() => {
              setShow(true);
              setEdit(false);
              setStartDate(new Date());
              setEndDate(new Date());
              setDescription("");
              setSelectedCourt(allOption);
            }}
          >
            Add Slot
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader mt="20%" />
      ) : (
        <>
          <div className="row mx-0">
            {disableSlotData?.length > 0 &&
              disableSlotData.map((data) => (
                <div className="col-md-6 col-12 px-3 mt-3" key={1}>
                  <div className="row staff-box h-100 position-relative Montserrat border rounded shadow-sm p-3 bg-light">
                    <section className="col-9  p-2">
                      <div className="pl-3">
                        <h2 className="fs-6 fw-600 text-primary">
                          {data?.courtId?.map((item) => item.label).join(",")}
                        </h2>
                        <div className="my-2">
                          <label className="fs-6 fw-600">
                            Start Date & Time
                          </label>
                          <span className="fs-6 fw-500 text-muted">
                            {formatDateTime(
                              data?.startDateTime,
                              "DD/MM/YY hh:mm A"
                            )}
                          </span>
                        </div>
                        <div className="my-2">
                          <label className="fs-6 fw-600">End Date & Time</label>
                          <span className="fs-6 fw-500 text-muted">
                            {formatDateTime(
                              data?.endDateTime,
                              "DD/MM/YY hh:mm A"
                            )}
                          </span>
                        </div>
                        {data?.description && (
                          <div className="my-2">
                            <label className="fs-6 fw-600">Description</label>
                            <br />
                            <span className="fs-6 fw-500 text-muted">
                              {data?.description}
                            </span>
                          </div>
                        )}
                      </div>
                    </section>
                    <div className="position-absolute top-0 end-0 d-flex flex-column">
                      <img
                        className="staff-action-icons-edit mb-2"
                        src="/images/EditNewIcon.svg"
                        alt="Edit"
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => {
                          setShow(true);
                          setEdit(true);
                          setId(data?.id);
                          setStartDate(new Date(data?.startDateTime));
                          setEndDate(new Date(data?.endDateTime));
                          if (options.length == 1) {
                            setSelectedCourt(allOption);
                          } else {
                            setSelectedCourt(data?.courtId);
                          }

                          setDescription(data?.description);
                        }}
                      />
                      <img
                        className="staff-action-icons-delete"
                        src="/images/delete2.svg"
                        alt="Delete"
                        style={{
                          cursor: "pointer",
                          width: "24px",
                          height: "24px",
                        }}
                        onClick={() => {
                          deleteDisableSlot(data?.id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
      <Popup show={show} venueflag="1" onHide={() => setShow(false)}>
        {body}
      </Popup>
    </React.Fragment>
  );
}
