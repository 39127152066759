import React, { Fragment, useState } from "react";
import { CustomPostAPI, CustomPutAPI } from "../Utils/APIConnector";
import { CheckValidation, compressImage, validateEmail } from "../Utils/common";
import $ from "jquery";
export default function AddUpdateStaff({
  onHide,
  GetStaffMembers,
  show,
  venueID,
}) {
  const userid = window.localStorage.getItem("userid");
  const [staffData, setStaffData] = useState({
    Name: show?.updateData != null ? show?.updateData?.firstName : "",
    Email: show?.updateData != null ? show?.updateData?.email : "",
    Phone: show?.updateData != null ? show?.updateData?.phoneNumber : "",
    Image: show?.updateData != null ? show?.updateData?.profileImage : "",
    // Position: show?.updateData != null ? show?.updateData?.Position : "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const imageUpload = async (e) => {
    const file = e.target.files[0];
    let compressedFile = await compressImage(file);
    getBase64(compressedFile).then((base64) => {
      setStaffData((prevState) => ({ ...prevState, Image: base64 }));
    });
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "Phone" && value.length > 10) {
      return;
    }
    if (name === "Email" && !validateEmail(value)) {
      $(".invalid-email").removeClass("d-none");
    } else {
      $(".invalid-email").addClass("d-none");
    }
    let updatedData = { ...staffData };
    updatedData[name] = value;
    setStaffData(updatedData);
  };
  const saveUpdateData = async () => {
    setErrorMessage("");
    if (
      CheckValidation({ formID: "staffPopup" }) &&
      $(".invalid-email").hasClass("d-none")
    ) {
      const raw = JSON.stringify({
        Name: staffData?.Name,
        Email: staffData?.Email,
        Phone: staffData?.Phone,
        Image: staffData?.Image,
        // Position: staffData?.Position,
        venueId: venueID,
        Id: show?.updateData ? show.updateData.id : userid,
      });

      const apiCall = show?.updateData ? CustomPutAPI : CustomPostAPI;
      const endpoint = show?.updateData
        ? "/Venue/UpdateStaff"
        : "/Venue/AddStaffMembers";

      await apiCall(endpoint, raw).then((result) => {
        if (!result) {
          alert("Something went wrong");
        } else {
          let res = JSON.parse(result);
          if (res?.status == "success") {
            onHide();
            GetStaffMembers();
          } else {
            setErrorMessage(res?.message);
          }
        }
      });
    }
  };
  return (
    <Fragment>
      <div className="px-md-3">
        <h5 className="colorClass text-center">
          {show?.updateData ? "Edit Staff Member" : "Add Staff Member"}
        </h5>
        <div className="parent" id="staffPopup">
          <div className="col-12 pt-2 text-center">
            <label htmlFor="upload-button" className="profileImageDiv">
              {staffData?.Image == null || staffData?.Image == "" ? (
                <img src="./images/dummyavtar.png" className="profileImage" />
              ) : (
                <img
                  src={staffData?.Image}
                  alt="dummy"
                  className="profileImage"
                />
              )}
            </label>
            <input
              type="file"
              accept="image/x-png,image/jpeg"
              id="upload-button"
              className="d-none"
              onChange={imageUpload}
            />
          </div>
          <div className="row mt-3 parent">
            <div className="col-4 pe-0 align-self-center">
              <span className="venueText">Full Name</span>
            </div>
            <div className="col-8">
              <input
                name="Name"
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder="Name"
                value={staffData?.Name}
                onChange={(e) => {
                  handleChange(e);
                }}
                required
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
            </div>
          </div>
          <div className="row mt-3 parent">
            <div className="col-4 pe-0 align-self-center">
              <span className="venueText">Phone No.</span>
            </div>
            <div className="col-8">
              <input
                name="Phone"
                type="number"
                className="form-control"
                autoComplete="off"
                placeholder="Phone"
                value={staffData?.Phone}
                onChange={(e) => {
                  handleChange(e);
                }}
                required
                disabled={show?.updateData ? true : false}
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
            </div>
          </div>
          <div className="row mt-3 parent">
            <div className="col-4 pe-0 align-self-center">
              <span className="venueText">Email</span>
            </div>
            <div className="col-8">
              <input
                name="Email"
                type="text"
                className="form-control"
                autoComplete="off"
                placeholder="Email"
                value={staffData?.Email}
                onChange={(e) => {
                  handleChange(e);
                }}
                required
              />
              <span className="form-text invalid-feedback">
                * This field is Required!
              </span>
              <span className="form-text invalid-email d-none text-danger">
                Please enter valid email Id
              </span>
            </div>
          </div>
          {/* <div className="row mt-3 ">
                        <div className="col-4 pe-0 align-self-center">
                            <span className="venueText">
                                Position
                            </span>
                        </div>
                        <div className="col-8 parent">
                            <input
                                name="Position"
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                placeholder="Position"
                                value={staffData?.Position}
                                onChange={(e) => { handleChange(e) }}
                                required
                            />
                            <span className="form-text invalid-feedback">
                                * This field is Required!
                            </span>
                        </div>
                    </div> */}
        </div>
        <div className="row mt-2 justify-content-center">
          {errorMessage != "" && (
            <span className="form-text text-danger mb-2 text-center fw-600">
              {errorMessage}
            </span>
          )}
          <div
            className="col-3 text-end align-content-center staff-cancel-button"
            onClick={onHide}
          >
            Cancel
          </div>
          <div className="col-3">
            <button
              className="staff-button px-3 w-100"
              onClick={saveUpdateData}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
