import React from "react";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import { ConvertDate, GetMyAge } from "./constants";
import { GetAPI } from "./Utils/APIConnector";

export default function ScoreCard() {
  const matchDetailsID = window.localStorage.getItem("LiveMatchDetailID");
  const [mainPage, setmainPage] = useState({
    info: "",
    career: "d-none",
  });
  const [firstinnings, setfirstinnings] = useState({
    firstInning: [],
    firstInningbatsmenStatistics: [],
    firstInningbolwerStatistics: [],
  });
  const [secondinnings, setsecondinnings] = useState({
    secondInning: [],
    secondInningbatsmenStatistics: [],
    secondInningbolwerStatistics: [],
  });
  const [scorePagePlayerDetails, setscorePagePlayerDetails] = useState(false);
  const [playerDetail, setplayerDetail] = useState([]);
  useEffect(() => {
    (async function getStatus() {
      await GetAPI(
        "/MatchScoreCard/MatchStatistics/?matchid=" + parseInt(matchDetailsID)
      ).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          var result = JSON.parse(result);
          if (result.details[0].inningName == "FirstInnigs") {
            setfirstinnings({
              ...firstinnings,
              firstInning: result.details[0],
              firstInningbatsmenStatistics: result.details[0].batsmenStatistics,
              firstInningbolwerStatistics: result.details[0].bolwerStatistics,
            });
          } else {
            setfirstinnings({
              ...firstinnings,
              firstInning: result.details[1],
              firstInningbatsmenStatistics: result.details[1].batsmenStatistics,
              firstInningbolwerStatistics: result.details[1].bolwerStatistics,
            });
          }
          if (result.details[0].inningName == "SecondInnigs") {
            setsecondinnings({
              ...secondinnings,
              secondInning: result.details[0],
              secondInningbatsmenStatistics:
                result.details[0].batsmenStatistics,
              secondInningbolwerStatistics: result.details[0].bolwerStatistics,
            });
          } else {
            setsecondinnings({
              ...secondinnings,
              secondInning: result.details[1],
              secondInningbatsmenStatistics:
                result.details[1].batsmenStatistics,
              secondInningbolwerStatistics: result.details[1].bolwerStatistics,
            });
          }
        }
      });
      setTimeout(getStatus, 100);
    })();
  }, []);

  const playerDetails = async (Playerid) => {
    await GetAPI(
      "/MatchScoreCard/GetPlayersStatistics/?userid=" + parseInt(Playerid)
    ).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        var result = JSON.parse(result);
        if (result.status == "success") {
          setplayerDetail(result);
          setscorePagePlayerDetails(true);
        } else {
          alert("error");
        }
      }
    });
  };

  return (
    <div>
      <div className="mx-3">
        {!scorePagePlayerDetails ? (
          <>
            <Accordion alwaysOpen flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordionHeader">
                  <div className="col-5">
                    <div className="my-4 h6">
                      {firstinnings.firstInning.teamName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="my-4 h6" style={{ float: "right" }}>
                      {firstinnings.firstInning.totalRun} -{" "}
                      {firstinnings.firstInning.wicket} (
                      {firstinnings.firstInning.over})
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Table responsive>
                    <thead style={{ backgroundColor: "#C4C4C4" }}>
                      <tr>
                        <th>Batsmen</th>
                        <th>R</th>
                        <th>B</th>
                        <th>4s</th>
                        <th>6s</th>
                        <th>SR</th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {firstinnings.firstInningbatsmenStatistics.map(
                        (data, i) => {
                          var returndata;
                          returndata = (
                            <>
                              <tr key={i} data-id={data.playerID}>
                                <td
                                  className="cursorPointer"
                                  onClick={() => playerDetails(data.playerID)}
                                >
                                  <span
                                    style={{
                                      color: data.isOut ? "red" : "black",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {data.batsmenName}
                                  </span>
                                  <br />
                                  {data.isOut ? data.outType : "Not Out"}
                                </td>
                                <td>{data.runs}</td>
                                <td>{data.balls}</td>
                                <td>{data.fours}</td>
                                <td>{data.sixes}</td>
                                <td>{data.sr}</td>
                              </tr>
                            </>
                          );
                          return returndata;
                        }
                      )}
                    </tbody>
                  </Table>
                  <div className="row ps-2">
                    <div className="col-4">Extras</div>
                    <div className="col-8 ps-0">
                      {firstinnings.firstInning.extras}
                    </div>
                  </div>
                  <div
                    className="my-3 py-2 ps-2"
                    style={{ backgroundColor: "#C4C4C4" }}
                  >
                    Fall of Wickets
                  </div>
                  <div className="my-3 ps-2">
                    {firstinnings.firstInning.fallofWickets}
                  </div>
                  <Table responsive>
                    <thead style={{ backgroundColor: "#C4C4C4" }}>
                      <tr>
                        <th>Bowler</th>
                        <th>O</th>
                        <th>M</th>
                        <th>R</th>
                        <th>W</th>
                        <th>ECO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstinnings.firstInningbolwerStatistics.map(
                        (data, i) => {
                          var returndata;
                          returndata = (
                            <>
                              <tr key={i}>
                                <td
                                  className="cursorPointer"
                                  onClick={() => playerDetails(data.playerID)}
                                  style={{ textDecoration: "underline" }}
                                >
                                  {data.bolwerName}
                                </td>
                                <td>{data.overs}</td>
                                <td>{data.maiden}</td>
                                <td>{data.runs}</td>
                                <td>{data.wickets}</td>
                                <td>{data.eRate}</td>
                              </tr>
                            </>
                          );
                          return returndata;
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="col-5">
                    <div className="my-4 h6">
                      {secondinnings.secondInning.teamName}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="my-4 h6" style={{ float: "right" }}>
                      {secondinnings.secondInning.totalRun} -{" "}
                      {secondinnings.secondInning.wicket} (
                      {secondinnings.secondInning.over})
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <Table responsive>
                    <thead style={{ backgroundColor: "#C4C4C4" }}>
                      <tr>
                        <th>Batsmen</th>
                        <th>R</th>
                        <th>B</th>
                        <th>4s</th>
                        <th>6s</th>
                        <th>SR</th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {secondinnings.secondInningbatsmenStatistics.map(
                        (data, i) => {
                          var returndata;
                          returndata = (
                            <>
                              <tr key={i}>
                                <td
                                  className="cursorPointer"
                                  onClick={() => playerDetails(data.playerID)}
                                  style={{ textDecoration: "underline" }}
                                >
                                  {data.batsmenName}
                                  <br />
                                  {data.isOut ? data.outType : "Not Out"}
                                </td>
                                <td>{data.runs}</td>
                                <td>{data.balls}</td>
                                <td>{data.fours}</td>
                                <td>{data.sixes}</td>
                                <td>{data.sr}</td>
                              </tr>
                            </>
                          );
                          return returndata;
                        }
                      )}
                    </tbody>
                  </Table>
                  <div className="row ps-2">
                    <div className="col-4">Extras</div>
                    <div className="col-8 ps-0">
                      {secondinnings.secondInning.extras}
                    </div>
                  </div>
                  <div
                    className="my-3 py-2 ps-2"
                    style={{ backgroundColor: "#C4C4C4" }}
                  >
                    Fall of Wickets
                  </div>
                  <div className="my-3 ps-2">
                    {secondinnings.secondInning.fallofWickets}
                  </div>
                  <Table responsive>
                    <thead style={{ backgroundColor: "#C4C4C4" }}>
                      <tr>
                        <th>Bowler</th>
                        <th>O</th>
                        <th>M</th>
                        <th>R</th>
                        <th>W</th>
                        <th>ECO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {secondinnings.secondInningbolwerStatistics.map(
                        (data, i) => {
                          var returndata;
                          returndata = (
                            <>
                              <tr key={i}>
                                <td
                                  className="cursorPointer"
                                  onClick={() => playerDetails(data.playerID)}
                                  style={{ textDecoration: "underline" }}
                                >
                                  {data.bolwerName}
                                </td>
                                <td>{data.overs}</td>
                                <td>{data.maiden}</td>
                                <td>{data.runs}</td>
                                <td>{data.wickets}</td>
                                <td>{data.eRate}</td>
                              </tr>
                            </>
                          );
                          return returndata;
                        }
                      )}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </>
        ) : (
          <>
            <div className="row">
              <div
                className="col-1"
                onClick={() => setscorePagePlayerDetails(false)}
              >
                <img
                  className="cursorPointer"
                  src="./images/BackBtn.svg"
                  alt=""
                />
              </div>
              <div className="col-11 h6 pt-1">
                {playerDetail.userDetails.firstName}{" "}
                {playerDetail.userDetails.lastName}
              </div>
            </div>
            <div className="row mt-3">
              <div
                className="col-6 centerAlign cursorPointer h6"
                onClick={() =>
                  setmainPage({ ...mainPage, info: "", career: "d-none" })
                }
              >
                <span className={mainPage.info == "" ? "bottomColor" : ""}>
                  Info.
                </span>
              </div>
              <div
                className="col-6 centerAlign cursorPointer h6"
                onClick={() =>
                  setmainPage({ ...mainPage, info: "d-none", career: "" })
                }
              >
                <span className={mainPage.career == "" ? "bottomColor" : ""}>
                  Career Summary
                </span>
              </div>
            </div>
            <div className="my-4">
              <div className={mainPage.info}>
                <div className="playerProfilePhotoScoreCard">
                  <img
                    src={
                      playerDetail.userDetails.profileImage != null &&
                      playerDetail.userDetails.profileImage != ""
                        ? playerDetail.userDetails.profileImage
                        : "./images/dummyavtar.png"
                    }
                    className=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>

                <div className="p-2 text-center">
                  <label
                    style={{ color: "#A51E22", textTransform: "uppercase" }}
                  >
                    <h4>
                      <b>
                        {playerDetail.userDetails.firstName}{" "}
                        {playerDetail.userDetails.lastName}{" "}
                      </b>
                    </h4>
                  </label>
                  <br />
                  <span className="textLabel"></span>
                </div>
                <div className="row text-center">
                  <div className="col-5">
                    <label className="form-label textLabel">
                      Batting Style :{" "}
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">
                      {playerDetail.battingstyle === null ||
                      playerDetail.battingstyle === ""
                        ? "N/A"
                        : playerDetail.battingstyle
                            .replace(/([A-Z]+)/g, " $1")
                            .replace(/([A-Z][a-z])/g, " $1")}
                    </label>
                  </div>
                </div>
                <div className="row text-center">
                  <div className="col-5">
                    <label className="form-label textLabel">
                      Bowling Style :{" "}
                    </label>
                  </div>
                  <div className="col-7">
                    <label className="dataText">
                      {playerDetail.bowlingstyleName === null ||
                      playerDetail.bowlingstyleName === ""
                        ? "N/A"
                        : playerDetail.bowlingstyleName
                            .replace(/([A-Z]+)/g, " $1")
                            .replace(/([A-Z][a-z])/g, " $1")}
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="p-2 mt-3 text-center">
                    <label>
                      <h4>
                        <b>PERSONAL INFORMATION</b>
                      </h4>
                    </label>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-4 text-start">
                    <label className="form-label textLabel ">Phone No. :</label>
                  </div>
                  <div className="col-8 text-start">
                    <label className="dataText">
                      {playerDetail.userDetails.phone_Number}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-start">
                    <label className="form-label textLabel">DOB : </label>
                  </div>
                  <div className="col-8 text-start">
                    <label className="dataText">
                      {playerDetail.userDetails.dob == "" ||
                      playerDetail.userDetails.dob == null
                        ? "N/A"
                        : `${ConvertDate(playerDetail.userDetails.dob)}(
                        ${GetMyAge(playerDetail.userDetails.dob)} Years)`}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 text-start">
                    <label className="form-label textLabel">Email :</label>
                  </div>
                  <div className="col-8 text-start">
                    <label className="dataText">
                      {playerDetail.userDetails.email == "" ||
                      playerDetail.userDetails.email == null
                        ? "N/A"
                        : playerDetail.userDetails.email}
                    </label>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-4 text-start">
                    <label className="form-label textLabel">Location : </label>
                  </div>
                  <div className="col-8  text-center">
                    <label className="dataText">
                      {playerDetail.userDetails.area == "" ||
                      playerDetail.userDetails.area == null ||
                      playerDetail.userDetails.city == "" ||
                      playerDetail.userDetails.city == null
                        ? "N/A"
                        : `${playerDetail.userDetails.area} ${playerDetail.userDetails.city}`}
                    </label>
                  </div>
                </div>
              </div>

              <div className={mainPage.career}>
                <div
                  className="my-3 py-2 ps-2 csfont1"
                  style={{ backgroundColor: "#C4C4C4" }}
                >
                  Batting Career Summary
                </div>
                <div className="row csfont2">
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Avgerage : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.avgerage}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Ballfaced : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.ballfaced}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Fifteen : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.fifteen}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Fours : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.fours}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start pe-0">
                        HighestScore :{" "}
                      </div>
                      <div className="col-4 text-start">
                        {
                          playerDetail.playerStatistics.battingCareer
                            .highestScore
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Hundered : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.hundered}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Innings : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.innings}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Matches : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.matches}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Runs : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.runs}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Sixes : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.battingCareer.sixes}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">StrikeRate : </div>
                      <div className="col-4 text-start">
                        {parseInt(
                          playerDetail.playerStatistics.battingCareer.strikeRate
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start px-0">
                        TwoHundered :{" "}
                      </div>
                      <div className="col-4 text-start">
                        {
                          playerDetail.playerStatistics.battingCareer
                            .twoHundered
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="my-3 py-2 ps-2 csfont1"
                  style={{ backgroundColor: "#C4C4C4" }}
                >
                  Bowling Career Summary
                </div>
                <div className="row csfont2">
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Avgerage : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.avgerage}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Balls : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.balls}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Bbm : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.bbm}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Economay : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.economay}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">FiveWicket : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.fiveWicket}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Innings : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.innings}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Matches : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.matches}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Runs : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.runs}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">StrikeRate : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.strikeRate}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">TenWicket : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.tenWicket}
                      </div>
                    </div>
                  </div>
                  <div className="col-6 my-2">
                    <div className="row ">
                      <div className="col-8 text-start">Wickets : </div>
                      <div className="col-4 text-start">
                        {playerDetail.playerStatistics.bowlingCareer.wickets}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
