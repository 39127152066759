import React, { useEffect, useState } from "react";
import $ from "jquery";
import AddBatsmen from "./AddBatsmen";
import Table from "react-bootstrap/Table";
import { GetStrikeRate, isValid } from "../constants";

export default function OutScorePopup({
  popupshow,
  onHide,
  totalPlayers,
  player1Score,
  player2Score,
  battingTeam,
  SetOutType,
  SetOutBy,
  outType,
  outBy,
  setplayer1Score,
  setplayer2Score,
  runoutPLayerId,
  setrunoutPLayerId,
  scoreCount,
  outplayersList,
  setoutplayersList,
  currentBall,
  prevStep,
  setDynamicValues,
  inningsType,
}) {
  const [isTrue, setisTrue] = useState(false);

  const [lastBatsmenOut, setLastBatsmenOut] = useState(90);

  var strikeBatsmen = window.localStorage.getItem("strikerBatsmen");

  var nonStrikeBatsmen = window.localStorage.getItem("nonStrikerBatsmen");

  const close = () => {
    var radioType = "";
    var batsmenID = $(".batsmenOut").find("input:radio:checked").val();
    if (isValid(batsmenID)) {
      $(".radioTypeCheck").each(function () {
        radioType = $(this).find("input:radio:checked").val();
      });
      if (!isValid(radioType)) {
        $(".radioError").removeClass("d-none");
        return;
      } else {
        var outByID = "";
        if (parseInt(radioType) === 2) {
          outByID = $(".caughtBy option:selected").val();
          if (!isValid(outByID)) {
            $(".radioError").removeClass("d-none");
            return;
          } else {
            $(".radioError").addClass("d-none");
            //setisTrue(true);
          }
        }
        if (parseInt(radioType) === 4) {
          outByID = $(".runOutBy option:selected").val();
          if (!isValid(outByID)) {
            $(".radioError").removeClass("d-none");
            return;
          } else {
            $(".radioError").addClass("d-none");
            //setisTrue(true);
          }
        }
        SetOutType([...outType, radioType]);
        if (outByID > 0) {
          SetOutBy([...outBy, outByID]);
        } else {
          SetOutBy([...outBy, ""]);
        }
        $(".radioError").addClass("d-none");

        setLastBatsmenOut(batsmenID);

        setisTrue(true);
      }
    } else {
      $(".radioError").removeClass("d-none");
    }
    var RunoutPLayerId = "";
    // if ($("#radio1").is(":checked")) {
    if ($("#notrunOut").is(":checked")) {
      RunoutPLayerId = $("#notrunOut").val();
    }
    if ($("#runOut").is(":checked")) {
      RunoutPLayerId = $("#runOut").val();
    }
    // }
    setoutplayersList([...outplayersList, parseInt(RunoutPLayerId)]);
    setrunoutPLayerId([...runoutPLayerId, RunoutPLayerId]);
  };

  if (!$(".star1").hasClass("d-none")) {
    $("#runOut").attr("disabled", true);
  } else {
    $("#notrunOut").attr("disabled", true);
  }
  const radio1Checked = () => {
    if ($("#radio1").is(":checked")) {
      if (!$(".star1").hasClass("d-none")) {
        $("#runOut").removeAttr("disabled");
      } else {
        $("#notrunOut").removeAttr("disabled");
      }
      $("#runOut").removeAttr("disabled");
      if ($(".OutselectBox1").hasClass("d-none")) {
        $(".OutselectBox1").removeClass("d-none");
      }
    } else if (!$("#radio1").is(":checked")) {
      $(".OutselectBox1").addClass("d-none");
    }
    if (!$("#radio1").is(":checked")) {
      if (!$(".star1").hasClass("d-none")) {
        $("#runOut").prop("disabled", true);
        $("#notrunOut").prop("checked", true);
      } else {
        $("#runOut").prop("checked", true);
        $("#notrunOut").prop("disabled", true);
      }
    }
    if ($("#radio2").is(":checked")) {
      if ($(".OutselectBox2").hasClass("d-none")) {
        $(".OutselectBox2").removeClass("d-none");
      }
    } else if (!$("#radio2").is(":checked")) {
      $(".OutselectBox2").addClass("d-none");
    }
  };
  return (
    <>
      {!isTrue ? (
        <>
          <div>
            <div>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>Names</th>
                    <th>R</th>
                    <th>B</th>
                    <th>4s</th>
                    <th>6s</th>
                    <th>SR</th>
                  </tr>
                </thead>
                <tbody className="batsmenOut">
                  <tr>
                    <td>
                      <input
                        type="radio"
                        id="notrunOut"
                        className="form-check-input centerAlign"
                        name="BatsmenOutScore"
                        value={parseInt(strikeBatsmen)}
                        // onClick={radio1Checked}
                      />
                    </td>
                    <td
                      style={{
                        color: "#A51E22",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {player1Score.name}
                    </td>
                    <td>{player1Score.runs}</td>
                    <td>{player1Score.balls}</td>
                    <td>{player1Score.fours}</td>
                    <td>{player1Score.sixs}</td>
                    <td>
                      {GetStrikeRate(player1Score.runs, player1Score.balls)}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="radio"
                        id="runOut"
                        className="form-check-input "
                        name="BatsmenOutScore"
                        value={parseInt(nonStrikeBatsmen)}
                        // onClick={radio1Checked}
                      />
                    </td>
                    <td
                      style={{
                        color: "#A51E22",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {player2Score.name}
                    </td>
                    <td>{player2Score.runs}</td>
                    <td>{player2Score.balls}</td>
                    <td>{player2Score.fours}</td>
                    <td>{player2Score.sixs}</td>
                    <td>
                      {GetStrikeRate(player2Score.runs, player2Score.balls)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            {/* <div
          className="row my-3 centerAlign"
          style={{ color: "#A51E22", fontWeight: "700", fontSize: "15px" }}
        >
          c Shaw b Axar
        </div> */}
            <div className="radioTypeCheck">
              <div className="row my-3">
                <div className="col-6">
                  <input
                    type="radio"
                    className="form-check-input "
                    name="OutScore"
                    value="1"
                    onClick={radio1Checked}
                  />
                  <span className=" ms-1">Bowled</span>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="OutScore"
                        id="radio1"
                        onClick={radio1Checked}
                        value="4"
                      />
                      <span className=" ms-1">Run Out</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2 OutselectBox1 d-none">
                      <select
                        className="form-select OutSelect runOutBy"
                        defaultValue="0"
                      >
                        <option value="0">Runout By</option>
                        {totalPlayers.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.fullName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-6">
                  <div className="row">
                    <div className="col-6 pe-0">
                      <input
                        type="radio"
                        className="form-check-input "
                        name="OutScore"
                        id="radio2"
                        value="2"
                        onClick={radio1Checked}
                      />
                      <span className=" ms-1">Caught</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-2 OutselectBox2 d-none">
                      <select
                        className="form-select OutSelect caughtBy"
                        defaultValue="0"
                      >
                        <option value="0">Caught By</option>
                        {totalPlayers.map((data, i) => (
                          <option key={i} value={data.id}>
                            {data.fullName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <input
                    type="radio"
                    className="form-check-input "
                    name="OutScore"
                    onClick={radio1Checked}
                    value="5"
                  />
                  <span className=" ms-1">Stumped</span>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-6">
                  <input
                    type="radio"
                    className="form-check-input "
                    name="OutScore"
                    onClick={radio1Checked}
                    value="3"
                  />
                  <span className=" ms-1">LBW</span>
                </div>
                <div className="col-6">
                  <input
                    type="radio"
                    className="form-check-input "
                    name="OutScore"
                    onClick={radio1Checked}
                    value="6"
                  />
                  <span className=" ms-1">Hit wicket</span>
                </div>
              </div>
            </div>
            <span
              className="radioError centerAlign d-none  mt-2"
              style={{ color: "red" }}
            >
              Please select
            </span>
            <div className="row " style={{ justifyContent: "right" }}>
              <div className="col-3 text-end">
                <button
                  className="btn"
                  style={{ backgroundColor: "#C4C4C4" }}
                  onClick={close}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <AddBatsmen
            currentBall={currentBall}
            totalPlayers={battingTeam}
            popupshow={popupshow}
            onHide={onHide}
            lastBatsmenOut={lastBatsmenOut}
            player1Score={player1Score}
            player2Score={player2Score}
            setplayer1Score={setplayer1Score}
            setplayer2Score={setplayer2Score}
            scoreCount={scoreCount}
            outplayersList={outplayersList}
            prevStep={prevStep}
            setDynamicValues={setDynamicValues}
            inningsType={inningsType}
          />
        </>
      )}
    </>
  );
}
