import React, { Component, Fragment } from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Button, Container, Card, Col } from "react-bootstrap";
import { Route } from "react-router-dom";
import { ConvertDateTime, DBConvertDatetime } from "../constants";
import { Authentication } from "../Utils/PopupModal/Authentication";
import { Welcomepage } from "../welcomepage";
import { GetDay } from "../constants";
import $ from "jquery";
import { PostAPI } from "../Utils/APIConnector";

export const ModalPopup = ({ data, show, onHide, matchJoin }) => {
  const [isShow, SetIsShow] = useState(false);
  const [obj, setobj] = useState({});

  const handleRazorpayPayment = async (matchID, entryFee) => {
    const UserID = parseInt(window.localStorage.getItem("userid"));
    const UserType = window.localStorage.getItem("UserType");
    if (UserType == "" || UserType == "Guest") {
      window.localStorage.setItem("RedirectURL", window.location.href);
      window.href = "/welcomepage";
    }
    var roleTypeID = 0;
    if ($("#roleTypeDiv").length > 0) {
      $(".roleTypeCheck").each(function () {
        roleTypeID = $("input:radio:checked", $(this)).val();
      });
      if (
        roleTypeID === undefined ||
        roleTypeID === "" ||
        roleTypeID === null ||
        roleTypeID === 0
      ) {
        $(".radioError").removeClass("d-none");
        return;
      } else {
        $(".radioError").addClass("d-none");
      }
    }
    const MatchFees = parseInt(entryFee);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", "*");

    const Matchdata = JSON.stringify({
      UserId: UserID,
      MatchId: parseInt(matchID),
      Amount: MatchFees,
    });
    var options;
    await PostAPI("/payment/Authorize", Matchdata).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        result = JSON.parse(result);
        const order_id = result.orderResponse.orderId;
        options = {
          key: result.orderResponse.razorPayAPIKey,
          amount: result.orderResponse.amount,
          name: "Join Match Fees",
          description: "Match Fees",
          image: "/images/cricketFlash.svg",
          order_id: order_id,
          handler: async (paymentdata) => {
            if (data != "") {
              var obs = new Object();
              obs.data = data;
              obs.MatchId = matchID;
              obs.UserId = UserID;
              obs.MatchStatus = 1;
              obs.PaymentId = paymentdata.razorpay_payment_id;
              obs.OrderId = paymentdata.razorpay_order_id;
              obs.PlayerRoleID = parseInt(roleTypeID);
              window.localStorage.setItem("MatchDetails", JSON.stringify(obs));
            }

            var paymentData = JSON.stringify({
              MatchId: matchID,
              UserId: UserID,
              MatchStatus: 1,
              PaymentId: paymentdata.razorpay_payment_id,
              OrderId: paymentdata.razorpay_order_id,
              PlayerRoleID: parseInt(roleTypeID),
            });
            await PostAPI("/CreateMatches/JoinMatchPlayers", paymentData).then(
              (result) => {
                if (result == undefined || result == "") {
                  alert("Something went wrong");
                } else {
                  result = JSON.parse(result);
                  if (result.status == "Payment Success") {
                    window.location = "/PaymentConfirmed";
                  } else {
                    alert(result.status);
                  }
                }
              }
            );
          },
          prefill: {
            name: "CricBuddy Test",
            email: "team.sprigstack@gmail.com",
            contact: "9999999999",
          },
          theme: {
            color: "#F3F3F3",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    });
  };
  useEffect(() => {
    setobj(data);
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  var buttonCode;
  {
    // console.log(data);
  }
  if (data?.playerStatus === null) {
    buttonCode = (
      <Button
        type="submit"
        className="loginbtn"
        style={{ backgroundColor: "#3C3C40" }}
        onClick={() => handleRazorpayPayment(data.id, data.entryFees)}
      >
        Join & Pay
      </Button>
    );
  } else if (data.playerStatus == 1) {
    buttonCode = (
      <Button
        type="submit"
        className="primary"
        style={{ backgroundColor: "grey" }}
      >
        Requested
      </Button>
    );
  } else if (data.playerStatus == 2) {
    buttonCode = (
      <Button type="submit" className="" style={{ backgroundColor: "green" }}>
        Approved
      </Button>
    );
  } else if (data.playerStatus == 3) {
    buttonCode = (
      <Button type="submit" className="" style={{ backgroundColor: "red" }}>
        Rejected
      </Button>
    );
  } else if (data.playerStatus == 4) {
    buttonCode = (
      <Button type="submit" className="">
        Refunded
      </Button>
    );
  }

  return (
    <div>
      <Modal
        className="popupBtn"
        show={show}
        onHide={() => onHide({ msg: "Cross Icon Clicked!" })}
        style={{ paddingTop: "20px" }}
      >
        <Modal.Header closeButton style={{ backgroundColor: "#E8E8E8" }}>
          <Modal.Title>
            <div className="text-center">
              {data.sportTypeName == "TennisBall" ? (
                <>
                  <div className="d-flex">
                    <div className="col-10 centerAlign">
                      <b className="font2">{`${data.overs} Over Tennis match `}</b>
                    </div>
                    <div className="col-2 px-1">
                      <img
                        src="./images/TennisBall.svg"
                        className="w-100"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-10 px-0 centerAlign">
                      <b className="font1">{`${data.overs} Over Season match `}</b>
                    </div>
                    <div className="col-2 px-1">
                      <img
                        src="./images/SeasonBall.svg"
                        className="w-100"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-6 pe-0">
              <div className="text-left">
                <label className="form-label textLabel">
                  Players in team :
                </label>{" "}
                <label className="dataText">
                  {data.numberOfExistingPlayers}
                </label>
              </div>
            </div>
            <div className="col-6 pe-0">
              <div className=" text-left">
                <label className="form-label textLabel">
                  Required Players :
                </label>{" "}
                <label className="dataText">
                  {data.numberOfRequiredPlayers}
                </label>
              </div>
            </div>
          </div>
          {/* <div className="row"> */}
          <div className="col-12 text-left pe-0">
            <div className="row">
              <div className="col-6">
                <label className="form-label textLabel">
                  Match Fees :{" "}
                  <label className="dataText">₹ {data.entryFees}</label>
                </label>{" "}
              </div>
              {/* <div className="col-6"></div> */}
            </div>
          </div>
          <div className="col-12 text-left pe-0">
            <div className="row">
              <div className="col-1">
                <img src="./images/DateandTime.svg" alt="" />{" "}
              </div>
              <div className="col-11">
                <label className="dataText">
                  {DBConvertDatetime(data.dateAndTime)}
                  {`(${GetDay(data.dateAndTime)})`}
                </label>
              </div>
            </div>
            {/* <div className="row text-center"> */}
            {/* <div className="col-12 getdays ">
                  
                </div> */}
            {/* </div> */}
          </div>
          {/* </div> */}
          <div className="row text-left my-2">
            <div className="col-12">
              <img src="./images/groundIcon.svg" alt="" />{" "}
              <label className="groundName">{`${data.groundName} Cricket Ground`}</label>
            </div>
          </div>
          {/* {data.numberOfBatsmen == null &&
          data.numberOfBowlers == null &&
          data.numberOfAllRounders == null &&
          data.numberOfWicketKeepers == null ? null : (
            <div className="p-2 text-center">
              <label>
                <h4>
                  <b>Players Requirements</b>
                </h4>
              </label>
            </div>
          )}
          <div className="row justify-content-center">
            {data.numberOfBatsmen != null ? (
              <div
                className="col-3 centerAlign "
                style={{ backgroundColor: "white" }}
              >
                <div className="">
                  <img
                    src="/images/PlayerType/Batsmen.svg"
                    className="img-fluid"
                  />{" "}
                  : <b className="dataText">{data.numberOfBatsmen}</b>
                </div>
              </div>
            ) : null}
            {data.numberOfBowlers != null ? (
              <div
                className="col-3 centerAlign "
                style={{ backgroundColor: "white" }}
              >
                <div className="">
                  <img
                    src="/images/PlayerType/Bowler.svg"
                    className="img-fluid"
                  />{" "}
                  : <b className="dataText">{data.numberOfBowlers}</b>
                </div>
              </div>
            ) : null}
            {data.numberOfAllRounders != null ? (
              <div
                className="col-3 centerAlign "
                style={{ backgroundColor: "white" }}
              >
                <div className="">
                  <img
                    src="/images/PlayerType/AllRounder.svg"
                    className="img-fluid"
                  />{" "}
                  : <b className="dataText">{data.numberOfAllRounders}</b>
                </div>
              </div>
            ) : null}
            {data.numberOfWicketKeepers != null ? (
              <div
                className="col-3 centerAlign "
                style={{ backgroundColor: "white" }}
              >
                <div className="">
                  <img
                    src="/images/PlayerType/WicketKeeper.svg"
                    className="img-fluid"
                  />{" "}
                  : <b className="dataText">{data.numberOfWicketKeepers}</b>
                </div>
              </div>
            ) : null}
          </div> */}
          {data.numberOfBatsmen == null &&
          data.numberOfBowlers == null &&
          data.numberOfAllRounders == null &&
          data.numberOfWicketKeepers == null ? null : (
            <>
              <hr />
              <div className="p-2 text-center" id="roleTypeDiv">
                <label>
                  <h4>
                    <b>Select your role</b>
                  </h4>
                </label>
              </div>
            </>
          )}
          <div className="centerAlign roleTypeCheck">
            {data.numberOfBatsmen != null ? (
              <div className="col-3 centerAlign">
                <input
                  type="radio"
                  id="roleType"
                  value="1"
                  className="form-check-input me-1"
                  name="roleType"
                />
                <label>
                  <div className="">
                    <img
                      src="/images/PlayerType/Batsmen.svg"
                      className="img-fluid"
                    />
                  </div>
                </label>
              </div>
            ) : null}
            {data.numberOfBowlers != null ? (
              <div className="col-3 centerAlign">
                <input
                  type="radio"
                  id="roleType"
                  value="2"
                  className="form-check-input me-1"
                  name="roleType"
                />
                <label>
                  <div className="">
                    <img
                      src="/images/PlayerType/Bowler.svg"
                      className="img-fluid"
                    />
                  </div>
                </label>
              </div>
            ) : null}
            {data.numberOfAllRounders != null ? (
              <div className="col-3 centerAlign">
                <input
                  type="radio"
                  id="roleType"
                  value="3"
                  className="form-check-input me-1"
                  name="roleType"
                />
                <label>
                  <div className="">
                    <img
                      src="/images/PlayerType/AllRounder.svg"
                      className="img-fluid"
                    />
                  </div>
                </label>
              </div>
            ) : null}
            {data.numberOfWicketKeepers != null ? (
              <div className="col-3 centerAlign">
                <input
                  type="radio"
                  id="roleType"
                  value="4"
                  className="form-check-input me-1"
                  name="roleType"
                />
                <label>
                  <div className="">
                    <img
                      src="/images/PlayerType/WicketKeeper.svg"
                      className="img-fluid"
                    />
                  </div>
                </label>
              </div>
            ) : null}
          </div>
          <span
            className="radioError centerAlign d-none  mt-2"
            style={{ color: "red" }}
          >
            Please select your role
          </span>
          <div className="row">
            <div className="mt-3 col-12 text-center">
              {data.playerStatus == null ? (
                <button
                  type="submit"
                  className="loginbtn col-6"
                  style={{ backgroundColor: "#3C3C40" }}
                  onClick={() => handleRazorpayPayment(data.id, data.entryFees)}
                >
                  JOIN & PAY
                </button>
              ) : data.playerStatus == 1 ? (
                <Button
                  type="submit"
                  className="primary"
                  style={{ backgroundColor: "grey" }}
                >
                  Requested
                </Button>
              ) : data.playerStatus == 2 ? (
                <Button
                  type="submit"
                  className=""
                  style={{ backgroundColor: "green" }}
                >
                  Approved
                </Button>
              ) : data.playerStatus == 3 ? (
                <Button
                  type="submit"
                  className=""
                  style={{ backgroundColor: "red" }}
                >
                  Rejected
                </Button>
              ) : data.playerStatus == 4 ? (
                <Button type="submit" className="">
                  Refunded
                </Button>
              ) : null}
            </div>
            <div
              className="col-12 mt-3 px-3  "
              style={{ textAlign: "justify" }}
            >
              <span
                className="requiredValidation"
                style={{
                  color: "#A51E22",
                  fontSize: "15px",
                }}
              >
                <p>
                  Match fee is collected to confirm your presence at match
                  venue, it will only be charged if your request to join this
                  match is approved by creator.{" "}
                  <span className="fw-bold">
                    {process.env.REACT_APP_CUSTOM_REFUND_FEE}
                  </span>{" "}
                  of match fee will be refunded after match completes and{" "}
                  <span className="fw-bold">
                    {process.env.REACT_APP_CUSTOM_PLATFORM_FEE}
                  </span>{" "}
                  of match fee will be charged as platform fee.
                </p>
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ fontSize: "13px" }} className="text-center">
          <b>Match Created By :- {data.userName}</b>
        </Modal.Footer>
      </Modal>
      {isShow ? <Welcomepage redirectURL={window.location.href} /> : null}
    </div>
  );
};
